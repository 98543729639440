import {
  Experience,
  EXPERIENCE_TYPE,
  GetExperienceByIdRequest,
  GetUserExperiencesRequest,
  GetCategoryExperiencesRequest,
  GetTimeSlotResponse,
} from "redux/Experience/types";
import { ListQueryParams, Response } from "../../redux/Common/types";
import ExperienceService from "./ExperienceService";

export default class ExclusiveContentService extends ExperienceService {
  getExperiences = (params: ListQueryParams): Promise<Response<Experience[]>> => {
    return this.api.getExperiences(EXPERIENCE_TYPE.ON_DEMAND_CONTENT, params);
  };

  getAllExperiences = (params: ListQueryParams): Promise<Response<Experience[]>> => {
    return this.api.getExperiences(EXPERIENCE_TYPE.ALL, params);
  };

  getUserExperiences = (params: GetUserExperiencesRequest): Promise<Response<Experience[]>> => {
    return this.api.getUserExperiences(EXPERIENCE_TYPE.ON_DEMAND_CONTENT, params);
  };

  getAllUserExperiences = (params: GetUserExperiencesRequest): Promise<Response<Experience[]>> => {
    return this.api.getUserExperiences(EXPERIENCE_TYPE.ALL, params);
  };

  getExperienceById = (payload: GetExperienceByIdRequest): Promise<Response<Experience>> => {
    return this.api.getExperienceById(payload);
  };

  getCategoryExperiences = (params: GetCategoryExperiencesRequest): Promise<Response<Experience[]>> => {
    return this.api.getCategoryExperiences(EXPERIENCE_TYPE.ON_DEMAND_CONTENT, params);
  };

  getTimeSlots = (payload: string): Promise<Response<GetTimeSlotResponse>> => {
    return this.api.getTimeSlots(payload);
  };
}
