import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getExperiencesTypes = createGenericTypes("GET_EXPERIENCES");

export const getExperiencesActions = createGenericActions(getExperiencesTypes);

export const getListExperiencesTypes = createGenericTypes("GET_LIST_EXPERIENCES");

export const getListExperiencesActions = createGenericActions(getListExperiencesTypes);

export const createExperience1To1Types = createGenericTypes("CREATE_EXPERIENCE_ONE_TO_ONE");

export const createExperience1To1Actions = createGenericActions(createExperience1To1Types);

export const createExperienceLiveClassTypes = createGenericTypes("CREATE_EXPERIENCE_LIVE_CLASS");

export const createExperienceLiveClassActions = createGenericActions(createExperienceLiveClassTypes);

export const createExclusiveContentTypes = createGenericTypes("CREATE_EXCLUSIVE_CONTENT");

export const createExclusiveContentActions = createGenericActions(createExclusiveContentTypes);

export const getExperienceByIdTypes = createGenericTypes("GET_EXPERIENCE_BY_ID");

export const getExperienceByIdActions = createGenericActions(getExperienceByIdTypes);

export const createExperienceTypes = createGenericTypes("CREATE_EXPERIENCE");

export const createExperienceActions = createGenericActions(createExperienceTypes);

export const updateExperience1To1Types = createGenericTypes("UPDATE_EXPERIENCE_ONE_TO_ONE");

export const updateExperience1To1Actions = createGenericActions(updateExperience1To1Types);

export const updateExperienceLiveClassTypes = createGenericTypes("UPDATE_EXPERIENCE_LIVE_CLASS");

export const updateExperienceLiveClassActions = createGenericActions(updateExperienceLiveClassTypes);

export const updateExclusiveContentTypes = createGenericTypes("UPDATE_EXCLUSIVE_CONTENT");

export const updateExclusiveContentActions = createGenericActions(updateExclusiveContentTypes);

export const updateStagingCoversTypes = createGenericTypes("UPDATE_STAGING_COVERS");

export const updateStagingCoversActions = createGenericActions(updateStagingCoversTypes);

export const updateProductImagesTypes = createGenericTypes("UPDATE_PRODUCT_IMAGES");

export const updateProductImagesActions = createGenericActions(updateProductImagesTypes);

export const updateExperienceProcessStatusTypes = createGenericTypes("UPDATE_EXPERIENCE_PROCESS_STATUS");

export const updateExperienceProcessStatusActions = createGenericActions(updateExperienceProcessStatusTypes);

export const publishExperienceTypes = createGenericTypes("PUBLISH_EXPERIENCE");

export const publishExperienceActions = createGenericActions(publishExperienceTypes);

export const getLiveClassExperiencesTypes = createGenericTypes("GET_EXPERIENCES_LIVE_CLASS");
export const getLiveClassExperiencesActions = createGenericActions(getLiveClassExperiencesTypes);

export const getOneToOneExperiencesTypes = createGenericTypes("GET_EXPERIENCES_ONE_TO_ONE");
export const getOneToOneExperiencesActions = createGenericActions(getOneToOneExperiencesTypes);

export const getExclusiveContentExperiencesTypes = createGenericTypes("GET_EXPERIENCES_EXCLUSIVE_CONTENT");
export const getExclusiveContentExperiencesActions = createGenericActions(getExclusiveContentExperiencesTypes);

export const getCourseExperiencesTypes = createGenericTypes("GET_EXPERIENCES_COURSE");
export const getCourseExperiencesActions = createGenericActions(getCourseExperiencesTypes);

export const getExperienceTimeSlotsTypes = createGenericTypes("GET_EXPERIENCE_TIME_SLOTS");
export const getExperienceTimeSlotsActions = createGenericActions(getExperienceTimeSlotsTypes);

export const getAllExperiencesTypes = createGenericTypes("GET_ALL_EXPERIENCES");
export const getAllExperiencesActions = createGenericActions(getAllExperiencesTypes);

export const checkExperienceIsBookedTypes = createGenericTypes("CHECK_EXPERIENCE_IS_BOOKED");
export const checkExperienceIsBookedActions = createGenericActions(checkExperienceIsBookedTypes);
