import { createGenericTypes, createGenericActions } from "utils/createAction";

export const sendGiftCodeTypes = createGenericTypes("SEND_GIFT_CODE");
export const sendGiftCodeActions = createGenericActions(sendGiftCodeTypes);

export const buyGiftCodeTypes = createGenericTypes("BUY_GIFT_CODE");
export const buyGiftCodeActions = createGenericActions(buyGiftCodeTypes);

export const getGiftDetailTypes = createGenericTypes("GET_GIFT_DETAIL");
export const getGiftDetailActions = createGenericActions(getGiftDetailTypes);

export const getPurchaseGiftDetailTypes = createGenericTypes("GET_PURCHASE_GIFT_DETAIL");
export const getPurchaseGiftDetailActions = createGenericActions(getPurchaseGiftDetailTypes);

export const getPurchaseGiftsTypes = createGenericTypes("GET_LIST_PURCHASE_GIFT");
export const getPurchaseGiftsActions = createGenericActions(getPurchaseGiftsTypes);

export const getListGiftCodeTypes = createGenericTypes("GET_LIST_GIFT_CODE");
export const getListGiftCodeActions = createGenericActions(getListGiftCodeTypes);
