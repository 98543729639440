export enum RESPONSE_TYPE {
  SUCCESS,
  ERROR,
}

enum API_ERROR_CODE {
  authorization = 401,
  notFound = 404,
}

export type ResponseData = {
  message: any;
  code?: API_ERROR_CODE | null;
  statusCode?: API_ERROR_CODE | null;
  type: string | null;
};

export type LogRequestState = {
  responseType: RESPONSE_TYPE | null;
  responseData: ResponseData;
};
