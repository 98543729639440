import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { MODAL_CREATE_ACCOUNT, toggleModalActions } from "redux/Modal/actions";
import { authService, spotifyService, userService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import notification from "utils/notification";
import { TALENT_JWT_KEY } from "services/AuthService";
import { omit } from "lodash";

import {
  acceptCollaboratorInviteActions,
  checkUserExistedActions,
  createPasswordActions,
  forgotPasswordActions,
  getListUserPreSaveAppleActions,
  getListUserPreSaveSpotifyActions,
  getTalentCredentialActions,
  getUserByIdActions,
  joinFanClubActions,
  logoutActions,
  resetPasswordActions,
  sendOTPActions,
  signInActions,
  signInWithAppleActions,
  signInWithFacebookActions,
  signInWithGoogleActions,
  signUpActions,
  updatePasswordActions,
  updateUserProfileActions,
  updateUserTimezoneActions,
  verifyOTPActions,
  talentLoginActions,
  talentLoginWithGoogleActions,
  requestOtpActions,
} from "./actions";
import {
  AcceptCollaboratorInvitationRequest,
  CheckUserExistedRequest,
  CreatePasswordRequest,
  GetListUserPreSaveRequest,
  JoinFanClubRequest,
  RequestOtpRequest,
  ResetPasswordRequest,
  SendEmailOTPRequest,
  SignInAppleRequest,
  SignInGoogleRequest,
  SignInRequestWithSegment,
  SignInSocialRequest,
  UpdatePasswordRequest,
  User,
  VerifyEmailOTPRequest,
} from "./types";

function* acceptCollaboratorInvite({ payload }: Action<AcceptCollaboratorInvitationRequest>) {
  const result: any = yield* call(userService.acceptCollaboratorInvitation, payload);
  if (result.ok) {
    const { response } = result;
    notification.success({ message: "The invitation has been accepted successfully", duration: 5 });
    yield* put(acceptCollaboratorInviteActions.SUCCESS(response));

    if (payload.refreshUser) {
      yield* call(getUserById);
    }

    if (payload?.onSuccess) {
      yield* call(payload?.onSuccess);
    }

    if (payload.openLogin) {
      yield* put(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: true,
        }),
      );
    }
  } else {
    notification.error({ message: result.message });
    if (result.message?.includes("You have already joined")) {
      if (payload?.onAlreadyJoined) {
        yield* call(payload?.onAlreadyJoined);
      }
    }
    yield* put(acceptCollaboratorInviteActions.FAILURE(result.message));
  }
}

function* signin({ payload }: Action<SignInRequestWithSegment>) {
  const result: any = yield* call(authService.signin, payload.form);
  if (result.ok) {
    const { response } = result;
    yield* put(signInActions.SUCCESS(response.user));
    localStorage.setItem("user", JSON.stringify(response.user));
    try {
      yield* call(payload?.sendSegmentEvent, {
        "Is successfull?": "TRUE",
        "Talent User Full Name": `${response.user?.firstName} ${response.user?.lastName}`,
      });
    } catch (err) {
      console.log(err);
    }

    // run callback after login
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    try {
      yield* call(payload?.sendSegmentEvent, {
        "Is successfull?": "FALSE",
      });
    } catch (err) {
      console.log(err);
    }
    yield* put(signInActions.FAILURE(result.message));
  }
}

function* talentLogin({ payload }: Action<SignInRequestWithSegment>) {
  const deviceId = authService.deviceId || "";
  const result: any = yield* call(authService.signin, Object.assign(payload.form, { deviceId }));
  if (result.ok) {
    const { response } = result;
    if (response.user) {
      authService.deviceId = response.deviceId;
    }
    yield* put(talentLoginActions.SUCCESS(response.user));
    localStorage.setItem("user", JSON.stringify(response.user));
    try {
      yield* call(payload?.sendSegmentEvent, {
        "Is successfull?": "TRUE",
        "Talent User Full Name": `${response.user?.firstName} ${response.user?.lastName}`,
      });
    } catch (err) {
      console.log(err);
    }

    // run callback after login
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    try {
      yield* call(payload?.sendSegmentEvent, {
        "Is successfull?": "FALSE",
      });
    } catch (err) {
      console.log(err);
    }
    if (payload.onError && typeof payload.onError === "function") {
      payload.onError(result.message);
    }
    yield* put(talentLoginActions.FAILURE(result.message));
  }
}

function* signUp({ payload }: Action<any>) {
  const result: any = yield* call(authService.signUp, payload.form);
  if (result.ok) {
    const { response } = result;
    const user = response?.user;
    if (user) {
      try {
        yield* put(signUpActions.SUCCESS(user));
        yield* call(payload?.sendSegmentEvent, {
          "User id": user.id,
          Name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          // Age: moment().diff(user.dateOfBirth, "years"),
          Gender: user?.gender?.toUpperCase(),
          "Registration Date": user?.createdAt,
        });
        
        //Sign In
        const deviceId = authService.deviceId || "";
        const result: any = yield* call(authService.signin, Object.assign(payload.form, { deviceId }));
        if (result.ok) {
          const { response } = result;
          yield* put(signInActions.SUCCESS(response.user));
          localStorage.setItem("user", JSON.stringify(response.user));
        }
      } catch (err) {
        console.log(err);
      }

      // run callback after login
      if (payload.callback && typeof payload.callback === "function") {
        payload.callback();
      }
    } else {
      yield* put(signUpActions.SUCCESS(response));
    }
    // is invite collaborator
    //KC: When I am checking the bug for KOMI-584, it seems this if statement here is always false
    //But the accept invite function is called as a callback
    //Not sure if it is intended or not...
    if (payload.form.isInvitation) {
      yield* call(acceptCollaboratorInvite, {
        payload: {
          form: {
            email: payload.form.email,
            token: payload.form.token,
          },
          refreshUser: true,
        },
      } as Action<AcceptCollaboratorInvitationRequest>);
    }
  } else {
    yield* put(signUpActions.FAILURE(result.message));
  }
}

function* forgotPassword({ payload }: Action<any>) {
  const result: any = yield* call(authService.forgotPassword, payload);
  if (result.ok) {
    yield* put(forgotPasswordActions.SUCCESS());
  } else {
    yield* put(forgotPasswordActions.FAILURE(result.message));
  }
}

function* resetPassword({ payload }: Action<ResetPasswordRequest>) {
  const requestPayload = omit(payload, ["onSuccess"]);
  const result: any = yield* call(authService.resetPassword, requestPayload);
  if (result.ok) {
    const { response } = result;
    yield* put(resetPasswordActions.SUCCESS(response));
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    yield* put(resetPasswordActions.FAILURE(result.message));
  }
}

function* logout() {
  try {
    yield* call(authService.logout);
    yield* call(spotifyService.logout);
    yield* put(logoutActions.SUCCESS());
    localStorage.removeItem("user");
  } catch (error) {
    yield* put(logoutActions.FAILURE("Cannot logout"));
  }
}

function* getUserById() {
  const result: any = yield* call(userService.getUserById);
  if (result.ok) {
    yield* put(getUserByIdActions.SUCCESS(result.response));
    localStorage.setItem("user", JSON.stringify(result.response));
  } else {
    yield* put(getUserByIdActions.FAILURE(result?.message || null));
  }
}

function* updateUserProfile({ payload }: Action<User>) {
  const result: any = yield* call(userService.updateUserProfile, payload);
  if (result.ok) {
    yield* put(updateUserProfileActions.SUCCESS(result.response));
  } else {
    yield* put(updateUserProfileActions.FAILURE(result.message));
  }
}

function* updateUserTimezone({ payload }: Action<User>) {
  const result: any = yield* call(userService.updateUserProfile, payload);
  if (result.ok) {
    yield* put(updateUserTimezoneActions.SUCCESS(result.response));
  } else {
    yield* put(updateUserTimezoneActions.FAILURE(result.message));
  }
}

function* updatePassword({ payload }: Action<UpdatePasswordRequest>) {
  const result: any = yield* call(userService.updatePassword, payload);
  if (result.ok) {
    yield* put(updatePasswordActions.SUCCESS(result.response));
  } else {
    yield* put(updatePasswordActions.FAILURE(result.message));
  }
}

function* createPassword({ payload }: Action<CreatePasswordRequest>) {
  const result: any = yield* call(userService.createPassword, payload);
  if (result.ok) {
    yield* put(createPasswordActions.SUCCESS(result.response));
  } else {
    yield* put(createPasswordActions.FAILURE(result.message));
  }
}

function* sendOTP({ payload }: Action<SendEmailOTPRequest>) {
  const result: any = yield* call(userService.sendEmailOTP, payload);
  if (result.ok) {
    yield* put(sendOTPActions.SUCCESS(result.response));
  } else {
    yield* put(sendOTPActions.FAILURE(result.message));
  }
}
function* verifyOTP({ payload }: Action<VerifyEmailOTPRequest>) {
  const result: any = yield* call(userService.verifyEmailOTP, payload);
  if (result.ok) {
    yield* put(verifyOTPActions.SUCCESS(result.response.user));
  } else {
    yield* put(verifyOTPActions.FAILURE(result.message));
  }
}

function* signInWithFacebook({ payload }: Action<SignInSocialRequest>) {
  const result: any = yield* call(authService.signInWithFacebook, payload.token);
  const { response } = result;

  if (result.ok) {
    if (response.user?.id) {
      yield* put(signInActions.SUCCESS(response.user));

      try {
        yield* call(payload?.sendSegmentEvent, {
          "Is successfull?": "TRUE",
          "Talent User Full Name": `${response.user?.firstName} ${response.user?.lastName}`,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      yield* put(
        signInWithFacebookActions.SUCCESS({
          ...response.user,
          provider: "Facebook",
        }),
      );
    }
  } else {
    yield* put(signInWithFacebookActions.FAILURE(result.message));
  }
}

function* signInWithGoogle({ payload }: Action<SignInGoogleRequest>) {
  const result: any = yield* call(authService.signInWithGoogle, payload.token, payload.operation);
  const { response } = result;

  if (result.ok) {
    if (response.user?.id) {
      yield* put(signInActions.SUCCESS(response.user));
      localStorage.setItem("user", JSON.stringify(response.user));
      try {
        yield* call(payload?.sendSegmentEventSignIn, {
          "Is successfull?": "TRUE",
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      yield* put(
        signInWithGoogleActions.SUCCESS({
          ...response.user,
          provider: "Google",
        }),
      );
    }
  } else {
    yield* put(signInWithGoogleActions.FAILURE(result.message));
  }
}

/**
 * Function that will either login a google account or sign them up
 * The second step in the oAuth processes after passing through the google redirect - requires the token from the 1st step
 * if the response from the server has an existing id then the user already exists otherwise, it's signing them up
 * @param payload: requires token and operation type in the payload 
 */
function* talentSignInWithGoogle({ payload }: Action<SignInGoogleRequest>) {
  const operation: string = payload.operation || "";
  const result: any = yield* call(authService.talentSignInWithGoogle, payload.token, operation);
  const { response } = result;

  if (result.ok) {
    //if the user has an existing id, update the sign in action
    if (response.user?.id) {
      //login
      yield* put(signInActions.SUCCESS(response.user));
      localStorage.setItem("user", JSON.stringify(response.user));

      //segment event
      try {
        yield* call(payload?.sendSegmentEventSignIn, {
          "Is successfull?": "TRUE",
          "Talent User Full Name": `${response.user?.firstName} ${response.user?.lastName}`,
        });
      } catch (err) {
        console.log(err);
      }

      //call login callback if it exists
      if (payload.onSuccessLogin) {
        console.log('running onSuccessLogin for talentSignInWithGoogle');
        payload.onSuccessLogin();
      }

    } else {

      //if the user does not have an existing id, save the user instead, with the google action
      yield* put(
        //TODO: change the name of this action to reflect signup with google
        signInWithGoogleActions.SUCCESS({
          ...response.user,
          provider: "Google",
          accessToken: payload.token, //temp save token, to sign in user after all signup steps are complete
        }),
      );
    }
  } else {
    yield* put(signInWithGoogleActions.FAILURE(result.message));
  }
}

function* signInWithApple({ payload }: Action<SignInAppleRequest>) {
  const result: any = yield* call(authService.signInWithApple, payload.token);
  const { response } = result;

  if (result.ok) {
    if (response.user?.id) {
      yield* put(signInActions.SUCCESS(response.user));

      try {
        yield* call(payload?.sendSegmentEventSignIn, {
          "Is successfull?": "TRUE",
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      yield* put(
        signInWithAppleActions.SUCCESS({
          ...response,
          provider: "Apple",
        }),
      );
    }
  } else {
    yield* put(signInWithAppleActions.FAILURE(result.message));
  }
}

function* checkUserExisted({ payload }: Action<CheckUserExistedRequest>) {
  const requestPayload = omit(payload, ["onSuccess", "onError"]);
  const deviceId = authService.deviceId || "";

  const result: any = yield* call(
    userService.checkUserExisted,
    Object.assign(requestPayload, { deviceId }),
  );

  if (result.ok) {
    yield* put(checkUserExistedActions.SUCCESS(result.response));
    if (result.response && payload?.onSuccess) {
      const { userId, isExisted, phone2faEnabled, email2faEnabled, phoneOtp, emailVerified } = result.response;
      yield* call(
        payload?.onSuccess,
        isExisted,
        userId,
        phone2faEnabled,
        email2faEnabled,
        phoneOtp,
        emailVerified,
      );
    }
  } else {
    yield* put(checkUserExistedActions.FAILURE(result.message));
    if (payload?.onError) {
      yield* call(payload?.onError, result.message);
    }
  }
}

function* requestOtp({ payload }: Action<RequestOtpRequest>) {
  const requestPayload = omit(payload, ["onSuccess", "onError"]);
  const result: any = yield* call(authService.requestOtp, requestPayload);

  if (result.ok) {
    yield* put(requestOtpActions.SUCCESS(result.response.id));
    if (result.response && payload?.onSuccess) {
      const { id } = result.response;
      yield* call(payload?.onSuccess, id);
    }
  } else {
    yield* put(requestOtpActions.FAILURE(result.message));
    if (payload?.onError) {
      yield* call(payload?.onError, result.message);
    }
  }
}

function* getTalentCredential() {
  const result: any = yield* call(authService.getTalentCredentials);
  if (result.ok) {
    const { response } = result;
    yield* put(getTalentCredentialActions.SUCCESS(response.accessToken));
  } else {
    yield* put(getTalentCredentialActions.FAILURE(result.message));
  }
}

function* joinFanClub({ payload }: Action<JoinFanClubRequest>) {
  const result: any = yield* call(userService.joinFanClub, {
    talentProfileId: payload.talentProfileId,
  });
  if (result.ok) {
    const { response } = result;
    yield* put(getUserByIdActions.REQUEST());
    if (payload?.onJoinFanSuccess) {
      yield* call(payload?.onJoinFanSuccess);
    }
    yield* put(joinFanClubActions.SUCCESS(response));
  } else {
    yield* put(joinFanClubActions.FAILURE(result.message));
  }
}

function* getListUserPreSaveSpotify({ payload }: Action<GetListUserPreSaveRequest>) {
  const result: any = yield* call(userService.getListUserPreSave, payload);
  if (result.ok) {
    const { response } = result;
    yield* put(getListUserPreSaveSpotifyActions.SUCCESS(response));
  } else {
    yield* put(getListUserPreSaveSpotifyActions.FAILURE(result.message));
  }
}

function* getListUserPreSaveApple({ payload }: Action<GetListUserPreSaveRequest>) {
  const result: any = yield* call(userService.getListUserPreSave, payload);
  if (result.ok) {
    const { response } = result;
    yield* put(getListUserPreSaveAppleActions.SUCCESS(response));
  } else {
    yield* put(getListUserPreSaveAppleActions.FAILURE(result.message));
  }
}

export default function* userSagas(): SagaIterator {
  yield* takeLatest(signInActions.REQUEST, signin);
  yield* takeLatest(talentLoginActions.REQUEST, talentLogin);
  yield* takeLatest(signUpActions.REQUEST, signUp);
  yield* takeLatest(forgotPasswordActions.REQUEST, forgotPassword);
  yield* takeLatest(resetPasswordActions.REQUEST, resetPassword);
  yield* takeLatest(logoutActions.REQUEST, logout);
  yield* takeLatest(getUserByIdActions.REQUEST, getUserById);
  yield* takeLatest(updateUserProfileActions.REQUEST, updateUserProfile);
  yield* takeLatest(updateUserTimezoneActions.REQUEST, updateUserTimezone);
  yield* takeLatest(createPasswordActions.REQUEST, createPassword);
  yield* takeLatest(updatePasswordActions.REQUEST, updatePassword);
  yield* takeLatest(sendOTPActions.REQUEST, sendOTP);
  yield* takeLatest(verifyOTPActions.REQUEST, verifyOTP);
  yield* takeLatest(signInWithFacebookActions.REQUEST, signInWithFacebook);
  yield* takeLatest(signInWithGoogleActions.REQUEST, signInWithGoogle);
  yield* takeLatest(talentLoginWithGoogleActions.REQUEST, talentSignInWithGoogle);
  yield* takeLatest(signInWithAppleActions.REQUEST, signInWithApple);
  yield* takeLatest(checkUserExistedActions.REQUEST, checkUserExisted);
  yield* takeLatest(requestOtpActions.REQUEST, requestOtp);
  yield* takeLatest(getTalentCredentialActions.REQUEST, getTalentCredential);
  yield* takeLatest(acceptCollaboratorInviteActions.REQUEST, acceptCollaboratorInvite);
  yield* takeLatest(getListUserPreSaveSpotifyActions.REQUEST, getListUserPreSaveSpotify);
  yield* takeLatest(getListUserPreSaveAppleActions.REQUEST, getListUserPreSaveApple);
  yield* takeLatest(joinFanClubActions.REQUEST, joinFanClub);
}
