import { Action, handleActions } from "redux-actions";
import {
  getGiftDetailActions,
  sendGiftCodeActions,
  buyGiftCodeActions,
  getPurchaseGiftsActions,
  getPurchaseGiftDetailActions,
  getListGiftCodeActions,
} from "./actions";
import { GiftCodesState } from "./types";

const handlers = {
  // Send Gift Code
  [sendGiftCodeActions.REQUEST]: (state: GiftCodesState): GiftCodesState => ({
    ...state,
    loading: true,
    error: undefined,
    errorGiftCodeApply: undefined,
    errorData: undefined,
    data: null,
  }),
  [sendGiftCodeActions.SUCCESS]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    data: payload,
    loading: false,
  }),
  [sendGiftCodeActions.FAILURE]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    error: payload?.message,
    errorData: payload?.data,
    loading: false,
  }),

  // buy gift code
  [buyGiftCodeActions.REQUEST]: (state: GiftCodesState): GiftCodesState => ({
    ...state,
    loading: true,
    error: undefined,
    buyGiftCode: null,
  }),
  [buyGiftCodeActions.SUCCESS]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    buyGiftCode: payload,
    loading: false,
  }),
  [buyGiftCodeActions.FAILURE]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // Get Gift Code Detail
  [getGiftDetailActions.REQUEST]: (state: GiftCodesState): GiftCodesState => ({
    ...state,
    loading: true,
    errorGiftCodeApply: null,
    discountType: null,
    giftCodeType: null,
    error: null,
    errorData: null,
    discountAmount: null,
  }),
  [getGiftDetailActions.SUCCESS]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    discountAmount: payload?.isValid ? payload?.amount : 0,
    discountType: payload?.isValid ? payload?.discountType : null,
    giftCodeType: payload?.type,
    errorGiftCodeApply: payload?.isValid
      ? null
      : payload?.validationErrors?.length
      ? payload?.validationErrors?.[0]
      : "Invalid or used gift code",
    loading: false,
  }),
  [getGiftDetailActions.FAILURE]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    errorGiftCodeApply: payload,
    loading: false,
  }),
  // Get list purchase gift
  [getPurchaseGiftsActions.REQUEST]: (state: GiftCodesState): GiftCodesState => ({
    ...state,
    loading: true,
    error: null,
    listPurchaseGifts: null,
  }),
  [getPurchaseGiftsActions.SUCCESS]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    listPurchaseGifts: payload,
    error: null,
    loading: false,
  }),
  [getPurchaseGiftsActions.FAILURE]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // Get list gift code
  [getListGiftCodeActions.REQUEST]: (state: GiftCodesState): GiftCodesState => ({
    ...state,
    loading: true,
    error: null,
    listGiftCode: null,
  }),
  [getListGiftCodeActions.SUCCESS]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    listGiftCode: payload,
    error: null,
    loading: false,
  }),
  [getListGiftCodeActions.FAILURE]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // Get purchase gift detail
  [getPurchaseGiftDetailActions.REQUEST]: (state: GiftCodesState): GiftCodesState => ({
    ...state,
    loading: true,
    error: null,
    giftCodeData: null,
  }),
  [getPurchaseGiftDetailActions.SUCCESS]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    giftCodeData: payload,
    error: null,
    loading: false,
  }),
  [getPurchaseGiftDetailActions.FAILURE]: (state: GiftCodesState, { payload }: Action<any>): GiftCodesState => ({
    ...state,
    error: payload,
    loading: false,
  }),
};

const initialState: GiftCodesState = {
  error: null,
  data: null,
  errorData: null,
  loading: false,
  discountAmount: null,
  errorGiftCodeApply: null,
  buyGiftCode: null,
  listPurchaseGifts: null,
  listGiftCode: null,
  giftCodeData: null,
  discountType: null,
  giftCodeType: null,
};

export const giftCodesReducer = handleActions<GiftCodesState>(handlers, initialState);
