import { Api } from "./api/api";
import AuthService from "./AuthService";
import UserService from "./UserService";
import TalentService from "./TalentService";
import CategoryService from "./CategoryService";
import BookingService from "./BookingService";
import { EXPERIENCE_TYPE } from "redux/Experience/types";
import ExperienceFactoryService from "./experience/ExperienceFactoryService";
import TagService from "./TagService";
import WishlistService from "./WishlistService";
import PhotoService from "./PhotoService";
import AWSService from "./externals/AWSService";
import FirebaseService from "./FirebaseService";
import PaymentService from "./PaymentService";
import GiftCodesService from "./GiftCodesService";
import SpecialRequestService from "./SpecialRequestService";
import BundleService from "./BundleService";
import SpotifyService from "./SpotifyService";
import ShopifyService from "./ShopifyService";
import SalesService from "./SalesService";

export const Apisauce = new Api();
Apisauce.setup();

export const authService = new AuthService(Apisauce);
export const userService = new UserService(Apisauce);
export const salesService = new SalesService(Apisauce);
export const talentService = new TalentService(Apisauce);
export const categoryService = new CategoryService(Apisauce);
export const bookingService = new BookingService(Apisauce);
export const giftCodesService = new GiftCodesService(Apisauce);
export const tagService = new TagService(Apisauce);
export const experience1To1Service = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.ONE_TO_ONE,
);
export const experienceLiveClassService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.LIVE_CLASS,
);
export const experienceInteractiveLiveClassService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS,
);
export const exclusiveContentService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.ON_DEMAND_CONTENT,
);
export const courseService = ExperienceFactoryService.createService(
  Apisauce,
  EXPERIENCE_TYPE.COURSE,
);
export const wishlistService = new WishlistService(Apisauce);
export const photoService = new PhotoService(Apisauce);
export const awsService = AWSService.getInstance();
export const firebaseService = new FirebaseService(Apisauce);
export const paymentService = new PaymentService(Apisauce);
export const specialRequestService = new SpecialRequestService(Apisauce);
export const bundleService = new BundleService(Apisauce);
export const spotifyService = new SpotifyService(Apisauce);
export const shopifyService = new ShopifyService();
