import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { bookingService } from "services";
import {
  getBookingByIdActions,
  getBookingsActions,
  createBookingActions,
  rateBookingActions,
  tipBookingActions,
  getUpcomingBookingsActions,
  completeBookingActions,
  updateBookingAttachmentsActions,
  getMyVideosActions,
} from "./actions";
import {
  GetBookingRequest,
  CreateBookingRequest,
  RateBookingRequest,
  TipBookingRequest,
  CompleteBookingRequest,
  UpdateBookingAttachmentsRequest,
} from "./types";

function* getBookings({ payload }: Action<GetBookingRequest>) {
  const result: any = yield* call(bookingService.getBookings, payload);
  if (result.ok) {
    yield* put(getBookingsActions.SUCCESS(result.response));
  } else {
    yield* put(getBookingsActions.FAILURE(result.message));
  }
}

function* getUpcomingBookings({ payload }: Action<GetBookingRequest>) {
  const result: any = yield* call(bookingService.getBookings, payload);
  if (result.ok) {
    yield* put(getUpcomingBookingsActions.SUCCESS(result.response));
  } else {
    yield* put(getUpcomingBookingsActions.FAILURE(result.message));
  }
}

function* getMyVideos({ payload }: Action<GetBookingRequest>) {
  const result: any = yield* call(bookingService.getBookings, payload);
  if (result.ok) {
    yield* put(getMyVideosActions.SUCCESS(result.response));
  } else {
    yield* put(getMyVideosActions.FAILURE(result.message));
  }
}

function* getBookingById({ payload }: Action<number>) {
  const result: any = yield* call(bookingService.getBookingById, payload);
  if (result.ok) {
    yield* put(getBookingByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getBookingByIdActions.FAILURE(result.message));
  }
}

function* createBooking({ payload }: Action<CreateBookingRequest>) {
  const result: any = yield* call(bookingService.createBooking, payload);
  if (result.ok) {
    yield* put(createBookingActions.SUCCESS(result.response));
  } else {
    yield* put(createBookingActions.FAILURE(result?.message?.data ? result.message : result));
  }
}

function* rateBooking({ payload }: Action<RateBookingRequest>) {
  const result: any = yield* call(bookingService.rateBooking, payload);
  if (result.ok) {
    yield* put(rateBookingActions.SUCCESS(result.response));
  } else {
    yield* put(rateBookingActions.FAILURE(result.message));
  }
}

function* tipBooking({ payload }: Action<TipBookingRequest>) {
  const result: any = yield* call(bookingService.tipBooking, payload);
  if (result.ok) {
    yield* put(tipBookingActions.SUCCESS(result.response.clientSecret));
  } else {
    yield* put(tipBookingActions.FAILURE(result.message));
  }
}

function* completeBooking({ payload }: Action<CompleteBookingRequest>) {
  const result: any = yield* call(bookingService.completeBooking, payload);
  if (result.ok) {
    yield* put(completeBookingActions.SUCCESS(result.response));
  } else {
    yield* put(completeBookingActions.FAILURE(result.message));
  }
}

function* updateBookingAttachments({ payload }: Action<UpdateBookingAttachmentsRequest>) {
  const result: any = yield* call(bookingService.updateBookingAttachments, payload);
  if (result.ok) {
    yield* put(updateBookingAttachmentsActions.SUCCESS(result.response));
  } else {
    yield* put(updateBookingAttachmentsActions.FAILURE(result.message));
  }
}

export default function* bookingSagas(): SagaIterator {
  yield* takeLatest(getBookingsActions.REQUEST, getBookings);
  yield* takeLatest(getUpcomingBookingsActions.REQUEST, getUpcomingBookings);
  yield* takeLatest(getMyVideosActions.REQUEST, getMyVideos);
  yield* takeLatest(getBookingByIdActions.REQUEST, getBookingById);
  yield* takeLatest(createBookingActions.REQUEST, createBooking);
  yield* takeLatest(rateBookingActions.REQUEST, rateBooking);
  yield* takeLatest(tipBookingActions.REQUEST, tipBooking);
  yield* takeLatest(completeBookingActions.REQUEST, completeBooking);
  yield* takeLatest(updateBookingAttachmentsActions.REQUEST, updateBookingAttachments);
}
