import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  getOneToOneExperiencesActions,
  getExperienceTimeSlotsActions,
  getAllExperiencesActions,
  getListExperiencesActions,
  checkExperienceIsBookedActions,
} from "redux/Experience/actions";
import {
  exclusiveContentService,
  experience1To1Service,
  experienceLiveClassService,
  courseService,
  experienceInteractiveLiveClassService,
  bundleService,
} from "services";
import {
  getExclusiveContentExperiencesActions,
  getExperienceByIdActions,
  getExperiencesActions,
  getLiveClassExperiencesActions,
  getCourseExperiencesActions,
} from "./actions";
import {
  EXPERIENCE_TYPE,
  GetCourseExperiences,
  GetExclusiveContentExperiences,
  GetExperienceByIdRequest,
  GetExperiencePayload,
  GetLiveClassExperiences,
  GetOneToOneExperiences,
  GetAllExperiences,
} from "./types";

function* getExperiences({ payload }: Action<GetExperiencePayload>) {
  let result: any;

  switch (payload.type) {
    case EXPERIENCE_TYPE.ONE_TO_ONE:
      result = yield* call(experience1To1Service.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.LIVE_CLASS:
      result = yield* call(experienceLiveClassService.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.ON_DEMAND_CONTENT:
      result = yield* call(exclusiveContentService.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.COURSE:
      result = yield* call(courseService.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.ALL:
      result = yield* call(courseService.getAllExperiences, payload.params);
      break;
  }

  if (result?.ok) {
    yield* put(getExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getExperiencesActions.FAILURE(result));
  }
}
function* getListExperiences({ payload }: Action<GetExperiencePayload>) {
  let result: any;

  switch (payload.type) {
    case EXPERIENCE_TYPE.ONE_TO_ONE:
      result = yield* call(experience1To1Service.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.LIVE_CLASS:
      result = yield* call(experienceLiveClassService.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS:
      result = yield* call(experienceInteractiveLiveClassService.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.ON_DEMAND_CONTENT:
      result = yield* call(exclusiveContentService.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.COURSE:
      result = yield* call(courseService.getExperiences, payload.params);
      break;
    case EXPERIENCE_TYPE.BUNDLE:
      result = yield* call(bundleService.getAll, payload.params);
      break;
    case EXPERIENCE_TYPE.ALL:
      result = yield* call(courseService.getAllExperiences, payload.params);
      break;
  }

  if (result?.ok) {
    yield* put(getListExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getListExperiencesActions.FAILURE(result));
  }
}

function* getExperienceById({ payload }: Action<string>) {
  const requestData: GetExperienceByIdRequest = {
    expId: payload,
  };

  const result: any = yield* call(experience1To1Service.getExperienceById, requestData);
  if (result.ok) {
    yield* put(getExperienceByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getExperienceByIdActions.FAILURE(result));
  }
}

function* checkExperienceIsBooked({ payload }: Action<string>) {
  const requestData: GetExperienceByIdRequest = {
    expId: payload,
  };

  const result: any = yield* call(experience1To1Service.getExperienceById, requestData);
  if (result.ok) {
    yield* put(checkExperienceIsBookedActions.SUCCESS(result.response.hasBooking));
  } else {
    yield* put(checkExperienceIsBookedActions.FAILURE(result));
  }
}

function* getLiveClassExperiences({ payload }: Action<GetLiveClassExperiences>) {
  const result: any = yield* call(experienceLiveClassService.getExperiences, payload.params);
  if (result?.ok) {
    yield* put(getLiveClassExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getLiveClassExperiencesActions.FAILURE(result));
  }
}

function* getOneToOneExperiences({ payload }: Action<GetOneToOneExperiences>) {
  const result: any = yield* call(experience1To1Service.getExperiences, payload.params);
  if (result?.ok) {
    yield* put(getOneToOneExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getOneToOneExperiencesActions.FAILURE(result));
  }
}

function* getExclusiveContentExperiences({ payload }: Action<GetExclusiveContentExperiences>) {
  const result: any = yield* call(exclusiveContentService.getExperiences, payload.params);
  if (result?.ok) {
    yield* put(getExclusiveContentExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getExclusiveContentExperiencesActions.FAILURE(result));
  }
}

function* getCourseExperiences({ payload }: Action<GetCourseExperiences>) {
  const result: any = yield* call(courseService.getExperiences, payload.params);
  if (result?.ok) {
    yield* put(getCourseExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getCourseExperiencesActions.FAILURE(result));
  }
}

function* getAllExperiences({ payload }: Action<GetAllExperiences>) {
  const result: any = yield* call(courseService.getAllExperiences, payload.params);
  if (result?.ok) {
    yield* put(getAllExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getAllExperiencesActions.FAILURE(result));
  }
}

function* getExperienceTimeSlots({ payload }: Action<string>) {
  const result: any = yield* call(experience1To1Service.getTimeSlots, payload);
  if (result?.ok) {
    yield* put(getExperienceTimeSlotsActions.SUCCESS(result.response));
  } else {
    yield* put(getExperienceTimeSlotsActions.FAILURE(result));
  }
}

export default function* experienceSagas(): SagaIterator {
  yield* takeLatest(getExperiencesActions.REQUEST, getExperiences);
  yield* takeLatest(getListExperiencesActions.REQUEST, getListExperiences);
  yield* takeLatest(getExperienceByIdActions.REQUEST, getExperienceById);
  yield* takeLatest(checkExperienceIsBookedActions.REQUEST, checkExperienceIsBooked);
  yield* takeLatest(getLiveClassExperiencesActions.REQUEST, getLiveClassExperiences);
  yield* takeLatest(getOneToOneExperiencesActions.REQUEST, getOneToOneExperiences);
  yield* takeLatest(getExclusiveContentExperiencesActions.REQUEST, getExclusiveContentExperiences);
  yield* takeLatest(getCourseExperiencesActions.REQUEST, getCourseExperiences);
  yield* takeLatest(getAllExperiencesActions.REQUEST, getAllExperiences);
  yield* takeLatest(getExperienceTimeSlotsActions.REQUEST, getExperienceTimeSlots);
}
