import { getWishlistActions, addToWishlistActions, removeFromWishlistActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { WishlistState } from "./types";

const handlers = {
  // get wishlist
  [getWishlistActions.REQUEST]: (state: WishlistState): WishlistState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      wishlist: null,
    };
  },
  [getWishlistActions.SUCCESS]: (state: WishlistState, { payload }: Action<any>): WishlistState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      wishlist: payload,
    };
  },
  [getWishlistActions.FAILURE]: (state: WishlistState, { payload }: Action<any>): WishlistState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // add to wishlist
  [addToWishlistActions.REQUEST]: (state: WishlistState): WishlistState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      wishlistSubmitted: null,
    };
  },
  [addToWishlistActions.SUCCESS]: (state: WishlistState, { payload }: Action<any>): WishlistState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      wishlistSubmitted: payload,
    };
  },
  [addToWishlistActions.FAILURE]: (state: WishlistState, { payload }: Action<any>): WishlistState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // remove from wishlist
  [removeFromWishlistActions.REQUEST]: (state: WishlistState, { payload }: Action<any>): WishlistState => {
    return {
      ...state,
      error: null,
      wishlist: {
        items: state.wishlist?.items?.filter((item) => item.id !== payload) ?? [],
        meta: state.wishlist?.meta as any,
        links: state.wishlist?.links as any,
      },
    };
  },
  [removeFromWishlistActions.SUCCESS]: (state: WishlistState, { payload }: Action<any>): WishlistState => {
    return {
      ...state,
      error: null,
      isLoading: false,
    };
  },
  [removeFromWishlistActions.FAILURE]: (state: WishlistState, { payload }: Action<any>): WishlistState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
};

const defaultState: WishlistState = {
  wishlist: null,
  wishlistSubmitted: null,
  isLoading: false,
  error: null,
};

export const wishlistReducer = handleActions<WishlistState>(handlers, defaultState);
