import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { talentService } from "services";
import { getPurchasingOptionsActions, getPurchasingOptionsByIdActions } from "./actions";
import { GetPurchasingOptionsByIdRequest, GetPurchasingOptionsRequest } from "./types";

function* getPurchasingOptions({ payload }: Action<GetPurchasingOptionsRequest>) {
  const result: any = yield* call(talentService.getPurchasingOptions, payload);
  if (result.ok) {
    yield* put(getPurchasingOptionsActions.SUCCESS(result.response));
  } else {
    yield* put(getPurchasingOptionsActions.FAILURE(result.message));
  }
}

function* getPurchasingOptionsById({ payload }: Action<GetPurchasingOptionsByIdRequest>) {
  const result: any = yield* call(talentService.getPurchasingOptionsById, payload);
  if (result.ok) {
    yield* put(getPurchasingOptionsByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getPurchasingOptionsByIdActions.FAILURE(result.message));
  }
}

export default function* purchasingOptionsSagas(): SagaIterator {
  yield* takeLatest(getPurchasingOptionsActions.REQUEST, getPurchasingOptions);
  yield* takeLatest(getPurchasingOptionsByIdActions.REQUEST, getPurchasingOptionsById);
}
