import { SegmentEventProperties, SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { KOMI_USER_LOCATION } from "services/UserService";
import UAParser from "ua-parser-js";

export class AnalyticServices {
  static track<T extends SEGMENT_EVENT>(
    event: T,
    properties?: SegmentEventProperties[T],
    options?: { source?: "client" | "talent" },
  ): void {
    const source = options?.source ?? "client";

    if (source === "client") {
      (window as any)?.analytics?.track(event, properties);
    }
    if (source === "talent") {
      const talentPixel = document?.getElementById("talent-pixel") as HTMLIFrameElement | null;
      const talentPixelWindow = talentPixel?.contentWindow;
      (talentPixelWindow as any)?.analytics?.track(event, properties);
    }
  }

  static identify(event: string, properties?: Record<string, any>): void {
    (window as any)?.analytics?.identify(event, properties);
  }

  static ready(callback: () => void): void {
    (window as any)?.analytics?.ready(callback);
  }

  static reset(): void {
    (window as any)?.analytics?.reset();
  }

  static getLocation(): string | undefined {
    return Cookies.get(KOMI_USER_LOCATION);
  }

  static getPlatform(
    props: { side: "client-side" } | { side: "server-side"; userAgentString: string },
  ): "Responsive" | "Web" {
    const uaParserResult =
      props.side === "client-side" ? UAParser() : UAParser(props.userAgentString);
    return uaParserResult.device.type === "mobile" ? "Web" : "Responsive";
  }
}
