import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { specialRequestService } from "services";
import { getListSpecialRequestsActions, getSpecialRequestByIdActions, createSpecialRequestActions } from "./actions";
import { GetSpecialRequests, CreateSpecialRequestPayload } from "./types";

function* getListSpecialRequests({ payload }: Action<GetSpecialRequests>) {
  const result: any = yield* call(specialRequestService.getList, payload);
  if (result.ok) {
    yield* put(getListSpecialRequestsActions.SUCCESS(result.response));
  } else {
    yield* put(getListSpecialRequestsActions.FAILURE(result.message));
  }
}

function* getSpecialRequestById({ payload }: Action<string>) {
  const result: any = yield* call(specialRequestService.getById, payload);
  if (result.ok) {
    yield* put(getSpecialRequestByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getSpecialRequestByIdActions.FAILURE(result.message));
  }
}

function* createSpecialRequest({ payload }: Action<CreateSpecialRequestPayload>) {
  const result: any = yield* call(specialRequestService.create, payload);
  if (result.ok) {
    yield* put(createSpecialRequestActions.SUCCESS(result.response));
  } else {
    yield* put(createSpecialRequestActions.FAILURE(result.message));
  }
}

export default function* specialRequestSagas(): SagaIterator {
  yield* takeLatest(getListSpecialRequestsActions.REQUEST, getListSpecialRequests);
  yield* takeLatest(getSpecialRequestByIdActions.REQUEST, getSpecialRequestById);
  yield* takeLatest(createSpecialRequestActions.REQUEST, createSpecialRequest);
}
