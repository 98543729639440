import { Text } from "components/Typography";
import React from "react";
import { NoticeType } from "utils/notification";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-outline.svg";

interface Props {
  message: string;
  type?: NoticeType;
  onClose?: () => void;
}

const NotificationMessage: React.FC<Props> = ({ message, onClose }) => {
  return (
    <React.Fragment>
      <div className="notification-content">
        <Text className="d--block">{message}</Text>
        <CloseIcon className="notification-content__close" onClick={onClose} />
      </div>
    </React.Fragment>
  );
};

export default NotificationMessage;
