import { setUserValueAction } from "redux/User/actions";
import { KOMI_CURRENCY } from "services/UserService";
import { getCookie } from "./cookie";

export const getProfileFromCookie = ({ req, store }: any) => {
  const cookie = req ? req.headers["cookie"] : (navigator as any).cookie;
  store.dispatch(
    setUserValueAction({
      currency: getCookie(KOMI_CURRENCY, cookie) ?? "GBP",
    }),
  );
};
