export const formatterUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const abbreviateNumber = (number: any) => {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  const tier = (Math.log10(number) / 3) | 0;

  if (tier == 0) return number;

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = number / scale;

  return scaled.toFixed(2) + suffix;
};

export const getCurrentCurrency = (defaultCurrency: string, exchangeRates: any) => {
  return defaultCurrency || exchangeRates?.GBPExchangeRate || "GBP";
};

export const formatCurrency = (defaultCurrency: string, exchangeRates: any) => {
  const localeData = "en-US";

  return new Intl.NumberFormat(localeData, {
    style: "currency",
    currency: getCurrentCurrency(defaultCurrency, exchangeRates),
    minimumFractionDigits: 0,
    maximumFractionDigits: exchangeRates?.decimalDigits,
  });
};

export const formatCurrencyNonExchange = (currency: string, minDigits = 0) => {
  const localeData = "en-US";

  return new Intl.NumberFormat(localeData, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: 2,
  });
};

export const getPrice = (price: number, currency: string, exchangeRates: any, withRounding?: boolean) => {
  let exchangedPrice = parseFloat(exchangeRates?.GBPExchangeRate || "1") * price;
  if (withRounding && exchangedPrice) {
    exchangedPrice = Math.floor(exchangedPrice) + 0.99;
  }
  const formatPrice = formatCurrency(currency, exchangeRates).format(exchangedPrice);
  return formatPrice;
};

export const parseCurrencyToInt = (value: string | number) => {
  if (typeof value !== "string") return value;
  return parseInt(value.replace(/,/g, ""));
};

export const getGiftCurrency = (currency: string) => {
  return !["USD", "EUR", "GBP"].includes(currency) ? "USD" : currency;
};

export const getGiftPrice = (price: number, currency: string) => {
  const localeData = "en-US";

  return new Intl.NumberFormat(localeData, {
    style: "currency",
    currency: getGiftCurrency(currency),
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(price);
};

export const LIMIT_PRICE_USD = 0.5;

export const getLimitPrice = (exchangeRates: any, exchangeRatesUSD: any) => {
  if (!exchangeRatesUSD || !exchangeRates) return -1;
  if (exchangeRates?.localCurrency === "USD") return LIMIT_PRICE_USD;
  // convert limit price USD to GBP
  const priceGBP = LIMIT_PRICE_USD / exchangeRatesUSD?.GBPExchangeRate;
  // convert price from GBP to local currency
  return priceGBP * exchangeRates?.GBPExchangeRate;
};
