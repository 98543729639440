import { Api } from "./api";

export interface ICategoryService {
  getAllCategories(): Promise<any>;
  getCategoryById(id: number): Promise<any>;
}
export default class CategoryService implements ICategoryService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getAllCategories = (): Promise<any> => {
    return this.api.getAllCategories({ page: 1, limit: 9999 });
  };
  getTrendingCategories = (): Promise<any> => {
    return this.api.getAllCategories({ page: 1, limit: 9999, trending: true });
  };

  getCategoryById = (id: number): Promise<any> => {
    return this.api.getCategoryById(id);
  };
}
