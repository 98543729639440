import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getListSpecialRequestsTypes = createGenericTypes("GET_LIST_SPECIAL_REQUESTS");
export const getListSpecialRequestsActions = createGenericActions(getListSpecialRequestsTypes);

export const getSpecialRequestByIdTypes = createGenericTypes("GET_SPECIAL_REQUEST_DETAIL_BY_ID");
export const getSpecialRequestByIdActions = createGenericActions(getSpecialRequestByIdTypes);

export const createSpecialRequestTypes = createGenericTypes("CREATE_SPECIAL_REQUEST");
export const createSpecialRequestActions = createGenericActions(createSpecialRequestTypes);
