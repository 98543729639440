import { GetGiftCodeDetail, SendGiftCodeRequest, BuyGiftCodeRequest, GiftCode } from "redux/GiftsCode/types";
import { Response, Pagination, ListQueryParams } from "redux/Common/types";
import { Api } from "./api";

interface IGiftCodesService {
  sendGiftCode(payload: SendGiftCodeRequest): Promise<Response<any>>;
  getGiftDetail(payload: { giftCodeId: string; experienceId: string }): Promise<Response<any>>;
}

export default class GiftCodesService implements IGiftCodesService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  sendGiftCode = (payload: SendGiftCodeRequest): Promise<Response<any>> => {
    return this.api.sendGiftCode(payload);
  };

  buyGiftCode = (payload: BuyGiftCodeRequest): Promise<Response<any>> => {
    return this.api.buyGiftCode(payload);
  };

  getGiftDetail = (payload: GetGiftCodeDetail): Promise<Response<any>> => {
    return this.api.getGiftDetail(payload);
  };

  getPurchaseGifts = (payload: ListQueryParams): Promise<Response<Pagination<GiftCode[]>>> => {
    return this.api.getPurchaseGifts(payload);
  };

  getListGiftCode = (payload: ListQueryParams): Promise<Response<Pagination<GiftCode[]>>> => {
    return this.api.getListGiftCode(payload);
  };

  getPurchaseGiftDetail = (id: string): Promise<Response<GiftCode>> => {
    return this.api.getPurchaseGiftDetail(id);
  };
}
