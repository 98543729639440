import { handleActions, Action } from "redux-actions";
import { REQUEST_API_ERROR, REQUEST_API_SUCCESS } from "./actions";
import { LogRequestState, RESPONSE_TYPE } from "./types";

const handlers = {
  [REQUEST_API_ERROR]: (state: LogRequestState, { payload }: Action<any>): LogRequestState => ({
    ...state,
    responseType: RESPONSE_TYPE.ERROR,
    responseData: payload,
  }),
  [REQUEST_API_SUCCESS]: (state: LogRequestState, { payload }: Action<any>): LogRequestState => ({
    ...state,
    responseType: RESPONSE_TYPE.SUCCESS,
    responseData: payload,
  }),
};

const initialState: LogRequestState = {
  responseType: null,
  responseData: {
    statusCode: null,
    message: null,
    code: null,
    type: null,
  },
};

export const logRequestReducer = handleActions<LogRequestState>(handlers, initialState);
