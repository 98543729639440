import {
  Experience,
  EXPERIENCE_TYPE,
  GetExperienceByIdRequest,
  GetUserExperiencesRequest,
  GetCategoryExperiencesRequest,
  GetTimeSlotResponse,
} from "redux/Experience/types";
import { ListQueryParams, Response } from "../../redux/Common/types";
import ExperienceService from "./ExperienceService";

export default class Experience1To1Service extends ExperienceService {
  getExperiences = (params: ListQueryParams): Promise<Response<Experience[]>> => {
    return this.api.getExperiences(EXPERIENCE_TYPE.ONE_TO_ONE, params);
  };

  getAllExperiences = (params: ListQueryParams): Promise<Response<Experience[]>> => {
    return this.api.getExperiences(EXPERIENCE_TYPE.ALL, params);
  };

  getUserExperiences = (params: GetUserExperiencesRequest): Promise<Response<Experience[]>> => {
    return this.api.getUserExperiences(EXPERIENCE_TYPE.ONE_TO_ONE, params);
  };

  getAllUserExperiences = (params: GetUserExperiencesRequest): Promise<Response<Experience[]>> => {
    return this.api.getUserExperiences(EXPERIENCE_TYPE.ALL, params);
  };

  getExperienceById = (payload: GetExperienceByIdRequest): Promise<Response<Experience>> => {
    return this.api.getExperienceById(payload);
  };

  getCategoryExperiences = (
    params: GetCategoryExperiencesRequest,
    type = EXPERIENCE_TYPE.ONE_TO_ONE,
  ): Promise<Response<Experience[]>> => {
    return this.api.getCategoryExperiences(type, params);
  };

  getTimeSlots = (periodId: string): Promise<Response<GetTimeSlotResponse>> => {
    return this.api.getTimeSlots(periodId);
  };
}
