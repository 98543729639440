import { ListQueryParams } from "./../Common/types";
import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { giftCodesService } from "services";
import {
  getGiftDetailActions,
  sendGiftCodeActions,
  sendGiftCodeTypes,
  buyGiftCodeActions,
  getPurchaseGiftsActions,
  getPurchaseGiftDetailActions,
  getListGiftCodeActions,
} from "./actions";
import { GetGiftCodeDetail, SendGiftCodeRequest, BuyGiftCodeRequest } from "./types";

function* sendGiftCode({ payload }: Action<SendGiftCodeRequest>) {
  const result: any = yield* call(giftCodesService.sendGiftCode, payload);
  if (result.ok) {
    yield* put(sendGiftCodeActions.SUCCESS(result.response));
  } else {
    yield* put(sendGiftCodeActions.FAILURE(result.message));
  }
}

function* buyGiftCode({ payload }: Action<BuyGiftCodeRequest>) {
  const result: any = yield* call(giftCodesService.buyGiftCode, payload);
  if (result.ok) {
    yield* put(buyGiftCodeActions.SUCCESS(result.response));
  } else {
    yield* put(buyGiftCodeActions.FAILURE(result.message));
  }
}

function* getGiftDetail({ payload }: Action<GetGiftCodeDetail>) {
  const result: any = yield* call(giftCodesService.getGiftDetail, payload);
  if (result.ok) {
    yield* put(getGiftDetailActions.SUCCESS(result.response));
  } else {
    yield* put(getGiftDetailActions.FAILURE(result.message));
  }
}

function* getListPurchaseGift({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(giftCodesService.getPurchaseGifts, payload);
  if (result.ok) {
    yield* put(getPurchaseGiftsActions.SUCCESS(result.response));
  } else {
    yield* put(getPurchaseGiftsActions.FAILURE(result.message));
  }
}

function* getListGiftCode({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(giftCodesService.getListGiftCode, payload);
  if (result.ok) {
    yield* put(getListGiftCodeActions.SUCCESS(result.response));
  } else {
    yield* put(getListGiftCodeActions.FAILURE(result.message));
  }
}

function* getPurchaseGiftDetail({ payload }: Action<string>) {
  const result: any = yield* call(giftCodesService.getPurchaseGiftDetail, payload);
  if (result.ok) {
    yield* put(getPurchaseGiftDetailActions.SUCCESS(result.response));
  } else {
    yield* put(getPurchaseGiftDetailActions.FAILURE(result.message));
  }
}

export default function* giftCodesSagas(): SagaIterator {
  yield* takeLatest(sendGiftCodeActions.REQUEST, sendGiftCode);
  yield* takeLatest(getGiftDetailActions.REQUEST, getGiftDetail);
  yield* takeLatest(buyGiftCodeActions.REQUEST, buyGiftCode);
  yield* takeLatest(getPurchaseGiftsActions.REQUEST, getListPurchaseGift);
  yield* takeLatest(getPurchaseGiftDetailActions.REQUEST, getPurchaseGiftDetail);
  yield* takeLatest(getListGiftCodeActions.REQUEST, getListGiftCode);
}
