import React, { useCallback } from "react";
import { NoticeType } from "utils/notification";
import { ReactComponent as NotiError } from "public/static/assets/icons/notification/error.svg";
import { ReactComponent as NotiSuccess } from "public/static/assets/icons/notification/success.svg";
import { ReactComponent as NotiWarning } from "public/static/assets/icons/notification/warning.svg";

interface Props {
  type?: NoticeType;
  style?: any;
}

const NotificationIcon: React.FC<Props> = ({ type, style }) => {
  const renderIcon = useCallback(() => {
    switch (type) {
      case "success":
        return <NotiSuccess />;
      case "error":
        return <NotiError />;
      case "warning":
        return <NotiWarning />;
      default:
        return <NotiWarning />;
    }
  }, [type]);
  return <React.Fragment>{renderIcon()}</React.Fragment>;
};

export default NotificationIcon;
