import { Pagination } from "redux/Common/types";
import { RoomToken } from "redux/Room/types";
import {
  Booking,
  GetBookingRequest,
  CreateBookingRequest,
  RateBookingRequest,
  RateBookingResponse,
  TipBookingResponse,
  TipBookingRequest,
  CompleteBookingRequest,
  UpdateBookingAttachmentsRequest,
} from "./../redux/Booking/types";
import { Response } from "./../redux/Common/types";
import { Api } from "./api";

interface IBookingService {
  getBookings(payload: GetBookingRequest): Promise<Response<Pagination<Booking[]>>>;
  getBookingById(id: number): Promise<Response<Booking>>;
  createBooking(payload: CreateBookingRequest): Promise<Response<Booking>>;
  // checkoutBooking(payload: CheckoutBookingRequest): Promise<Response<Booking>>;
}

export default class BookingService implements IBookingService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getBookings = (payload: GetBookingRequest): Promise<Response<Pagination<Booking[]>>> => {
    return this.api.getBookings(payload);
  };

  getBookingById = (id: number): Promise<Response<Booking>> => {
    return this.api.getBookingById(id);
  };

  createBooking = (payload: CreateBookingRequest): Promise<Response<Booking>> => {
    return this.api.createBooking(payload);
  };

  rateBooking = (payload: RateBookingRequest): Promise<Response<RateBookingResponse>> => {
    return this.api.rateBooking(payload);
  };

  tipBooking = (payload: TipBookingRequest): Promise<Response<TipBookingResponse>> => {
    return this.api.tipBooking(payload);
  };

  // checkoutBooking = (payload: CheckoutBookingRequest): Promise<Response<Booking>> => {
  //   return this.api.checkoutBooking(payload);
  // };

  getRoomToken1To1 = (bookingId: number): Promise<Response<RoomToken>> => {
    return this.api.getRoomToken1To1(bookingId);
  };

  getRoomTokenLiveClass = (bookingId: number): Promise<Response<RoomToken>> => {
    return this.api.getRoomTokenLiveClass(bookingId);
  };

  completeBooking = (payload: CompleteBookingRequest): Promise<Response<Booking>> => {
    return this.api.completeBooking(payload);
  };

  getTimeSlots = (periodId: string): Promise<Response<any>> => {
    return this.api.getTimeSlots(periodId);
  };

  updateBookingAttachments = (payload: UpdateBookingAttachmentsRequest): Promise<Response<string[]>> => {
    return this.api.updateBookingAttachments(payload);
  };
}
