import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { paymentService } from "services";
import {
  checkoutBookingActions,
  checkoutExpertSupportActions,
  checkoutRequestActions,
  checkoutSupportActions,
  getBraintreeClientTokenActions,
  getExchangeRateActions,
  getTransactionActions,
  getTransactionByIdActions,
  getPaymentMethodsActions,
  getExchangeRateUSDActions,
  getExchangeRateTipActions,
} from "./actions";
import { CheckoutBookingRequest, CheckoutRequest, CheckoutExpertSupportRequest } from "./types";
import { GetTransactionRequest } from "redux/Booking/types";
import { getGiftCurrency } from "utils/currency";

function* checkoutBooking({ payload }: Action<CheckoutBookingRequest>) {
  const result: any = yield* call(paymentService.checkoutBooking, payload);
  if (result.ok) {
    yield* put(checkoutBookingActions.SUCCESS(result.response.clientSecret));
  } else {
    yield* put(checkoutBookingActions.FAILURE(result?.message || null));
  }
}

function* checkoutExpertSupport({ payload }: Action<CheckoutExpertSupportRequest>) {
  const result: any = yield* call(paymentService.checkoutExpertSupport, payload);
  if (result.ok) {
    yield* put(
      checkoutExpertSupportActions.SUCCESS({
        clientSecret: result.response.clientSecret,
        amount: payload.amountLocal,
        notes: payload.notes,
        id: result.response.transactionId,
      }),
    );
  } else {
    yield* put(checkoutExpertSupportActions.FAILURE(result?.message || null));
  }
}

function* checkoutSupport({ payload }: Action<CheckoutExpertSupportRequest>) {
  const result: any = yield* call(paymentService.checkoutSupport, payload);
  if (result.ok) {
    yield* put(
      checkoutSupportActions.SUCCESS({
        clientSecret: result.response.clientSecret,
        amount: payload.amountLocal,
        notes: payload.notes,
        id: result.response.transactionId,
      }),
    );
  } else {
    yield* put(checkoutSupportActions.FAILURE(result?.message || null));
  }
}

function* checkoutRequest({ payload }: Action<CheckoutRequest>) {
  // const result: any = yield* call(requestService.checkoutRequest, payload);
  // if (result.ok) {
  //   yield* put(checkoutRequestActions.SUCCESS(result.response.clientSecret));
  // } else {
  //   yield* put(checkoutRequestActions.FAILURE(result?.message || null));
  // }
}

function* getBraintreeClientToken() {
  const result: any = yield* call(paymentService.getBraintreeClientToken);
  if (result.ok) {
    yield* put(getBraintreeClientTokenActions.SUCCESS(result.response));
  } else {
    yield* put(getBraintreeClientTokenActions.FAILURE(result?.message || null));
  }
}

function* getExchangeRates({ payload }: Action<string>) {
  const result: any = yield* call(paymentService.getExchangeRates, payload);
  if (result.ok) {
    yield* put(getExchangeRateActions.SUCCESS(result.response));
  } else {
    yield* put(
      getExchangeRateActions.SUCCESS({
        GBPExchangeRate: "1",
        decimalDigits: 2,
        localCurrency: "GBP",
      }),
    );
  }
}

function* getExchangeRatesUSD() {
  const result: any = yield* call(paymentService.getExchangeRates, "USD");
  if (result.ok) {
    yield* put(getExchangeRateUSDActions.SUCCESS(result.response));
  } else {
    yield* put(getExchangeRateUSDActions.FAILURE(result.message));
  }
}

function* getExchangeRatesTip({ payload }: Action<string>) {
  const result: any = yield* call(paymentService.getExchangeRates, getGiftCurrency(payload));
  if (result.ok) {
    yield* put(getExchangeRateTipActions.SUCCESS(result.response));
  } else {
    yield* put(
      getExchangeRateTipActions.SUCCESS({
        GBPExchangeRate: "1",
        decimalDigits: 2,
        localCurrency: "GBP",
      }),
    );
  }
}

function* getTransactions({ payload }: Action<GetTransactionRequest>) {
  const result: any = yield* call(paymentService.getTransactions, payload);
  if (result.ok) {
    yield* put(getTransactionActions.SUCCESS(result.response));
  } else {
    yield* put(getTransactionActions.FAILURE(result.message));
  }
}

function* getTransactionById({ payload }: Action<number>) {
  const result: any = yield* call(paymentService.getTransactionById, payload);
  if (result.ok) {
    yield* put(getTransactionByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getTransactionByIdActions.FAILURE(result.message));
  }
}

function* getPaymentMethods() {
  const result: any = yield* call(paymentService.getPaymentMethods);
  if (result.ok) {
    yield* put(getPaymentMethodsActions.SUCCESS(result.response));
  } else {
    yield* put(getPaymentMethodsActions.FAILURE(result.message));
  }
}

export default function* paymentSagas(): SagaIterator {
  yield* takeLatest(checkoutBookingActions.REQUEST, checkoutBooking);
  yield* takeLatest(checkoutRequestActions.REQUEST, checkoutRequest);
  yield* takeLatest(getBraintreeClientTokenActions.REQUEST, getBraintreeClientToken);
  yield* takeLatest(getExchangeRateActions.REQUEST, getExchangeRates);
  yield* takeLatest(getExchangeRateActions.REQUEST, getExchangeRatesUSD);
  yield* takeLatest(getExchangeRateActions.REQUEST, getExchangeRatesTip);
  yield* takeLatest(checkoutExpertSupportActions.REQUEST, checkoutExpertSupport);
  yield* takeLatest(getTransactionActions.REQUEST, getTransactions);
  yield* takeLatest(getTransactionByIdActions.REQUEST, getTransactionById);
  yield* takeLatest(checkoutSupportActions.REQUEST, checkoutSupport);
  yield* takeLatest(getPaymentMethodsActions.REQUEST, getPaymentMethods);
}
