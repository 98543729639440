import {
  sendOTPTypes,
  checkUserExistedTypes,
  forgotPasswordTypes,
  resetPasswordTypes,
  createPasswordTypes,
  verifyOTPTypes,
  acceptCollaboratorInviteTypes,
  joinFanClubTypes,
  talentLoginTypes,
} from "redux/User/actions";
import { RootState } from "./../rootReducer";
import { pick } from "lodash";
import { getUserByIdTypes, signInTypes, signUpTypes, updateUserProfileTypes, updatePasswordTypes } from "./actions";

export const selectUserData = (rootState: RootState) => rootState.userState?.user;

export const selectUserLoading = (rootState: RootState) => rootState.userState?.loading;

export const selectPhoneOtpLoading = (rootState: RootState) => rootState.userState?.phoneOtpLoading;

export const selectEmailOtpLoading = (rootState: RootState) => rootState.userState?.emailOtpLoading;

export const selectUserError = (rootState: RootState) => rootState.userState?.error;

export const selectSignInError = (rootState: RootState) =>
  (rootState.userState?.type === signInTypes.FAILURE || rootState.userState?.type === talentLoginTypes.FAILURE) &&
  rootState.userState?.error;

export const selectSignUpError = (rootState: RootState) =>
  rootState.userState?.type === signUpTypes.FAILURE && rootState.userState?.error;

export const selectForgotPasswordError = (rootState: RootState) =>
  rootState.userState?.type === forgotPasswordTypes.FAILURE && rootState.userState?.error;

export const selectForgotPasswordSuccess = (rootState: RootState) =>
  rootState.userState?.type === forgotPasswordTypes.SUCCESS;

export const selectResetPasswordError = (rootState: RootState) =>
  rootState.userState?.type === resetPasswordTypes.FAILURE && rootState.userState?.error;

export const selectResetPasswordSuccess = (rootState: RootState) =>
  rootState.userState?.type === resetPasswordTypes.SUCCESS;

export const selectGetUserByIdError = (rootState: RootState) =>
  rootState.userState?.type === getUserByIdTypes.FAILURE && rootState.userState?.error;

export const selectUserId = (rootState: RootState) => rootState.userState?.user?.id;

export const selectUpdatePassword = (rootState: RootState) =>
  [updatePasswordTypes.SUCCESS, createPasswordTypes.SUCCESS].includes(rootState.userState?.type)
    ? rootState?.userState.password
    : undefined;

export const selectUpdatePasswordError = (rootState: RootState) =>
  rootState.userState?.type === updatePasswordTypes.FAILURE ? rootState?.userState.error : undefined;

export const selectUpdateUserProfileSuccess = (rootState: RootState) =>
  rootState.userState?.type === updateUserProfileTypes.SUCCESS ? rootState.userState?.user : undefined;

export const selectUpdateUserProfileError = (rootState: RootState) =>
  rootState.userState?.type === updateUserProfileTypes.FAILURE && rootState.userState?.error;

export const selectSendOTPError = (rootState: RootState) =>
  rootState.userState?.type === sendOTPTypes.FAILURE && rootState.userState?.error;

export const selectLoadingSendOTP = (rootState: RootState) =>
  [sendOTPTypes.REQUEST, sendOTPTypes.SUCCESS].includes(rootState.userState?.type) && rootState.userState?.loading;

export const selectLoadingCheckUserExisted = (rootState: RootState) =>
  [checkUserExistedTypes.REQUEST, checkUserExistedTypes.SUCCESS].includes(rootState.userState?.type) &&
  rootState.userState?.loading;

export const selectPhoneOTP = (rootState: RootState) => rootState.userState?.phoneOTP;

export const selectVerifyOtpResult = (rootState: RootState) => rootState.userState?.verifyOtpResult;

export const selectVerifyOtpError = (rootState: RootState) =>
  rootState.userState?.type === verifyOTPTypes.FAILURE ? rootState?.userState.error : undefined;

export const selectSendOtpError = (rootState: RootState) =>
  rootState.userState?.type === sendOTPTypes.FAILURE ? rootState?.userState.error : undefined;

export const selectUserSocial = (rootState: RootState) => rootState.userState?.userSocial;

export const selectUserExisted = (rootState: RootState) =>
  rootState.userState?.type === checkUserExistedTypes.SUCCESS && rootState.userState?.isUserExisted?.isExisted;

export const selectTwoFactorInfo = (rootState: RootState) =>
  pick(rootState.userState?.isUserExisted, ["userId", "phone2faEnabled", "email2faEnabled", "email", "phoneOtp"]);

export const selectOtpId = (rootState: RootState) => rootState.userState?.otpId;

export const selectUserInWaitlist = (rootState: RootState) =>
  rootState.userState?.type === checkUserExistedTypes.SUCCESS && rootState.userState?.isUserExisted?.isInWaitlist;

export const selectUserAuthProviders = (rootState: RootState) =>
  rootState.userState?.type === checkUserExistedTypes.SUCCESS && rootState.userState?.isUserExisted?.authProviders;

export const selectUserCurrency = (rootState: RootState) => rootState.userState?.currency;

export const selectTalentCredential = (rootState: RootState) => rootState.userState.talentCredential;

export const selectIsRequestJoinTalent = (rootState: RootState) => rootState.userState.isRequestJoinTalent;

export const selectBookingSetup = (rootState: RootState) => rootState.userState.bookingSetup;

export const selectSignUpResult = (rootState: RootState) => rootState.userState.signUpResult;

export const selectOAuth = (rootState: RootState) => rootState.userState?.oAuth;

export const selectAcceptCollaboratorInviteSuccess = (rootState: RootState) =>
  rootState.userState?.type === acceptCollaboratorInviteTypes.SUCCESS;

export const selectAcceptCollaboratorInviteError = (rootState: RootState) =>
  rootState.userState?.type === acceptCollaboratorInviteTypes.FAILURE && rootState.userState?.error;

export const selectListUserPreSaveSpotify = (rootState: RootState) => rootState.userState.spotifyPreSaveList;

export const selectListUserPreSaveApple = (rootState: RootState) => rootState.userState.applePreSaveList;

export const selectJoinFanClubSuccess = (rootState: RootState) => rootState.userState.joinFanClub;

export const selectJoinFanClubError = (rootState: RootState) =>
  rootState.userState?.type === joinFanClubTypes.FAILURE ? rootState?.userState.error : undefined;
