import { Action, handleActions } from "redux-actions";
import { toggleModalType } from "./actions";
import { ModalState } from "./types";

const handlers = {
  [toggleModalType]: (state: ModalState, { payload }: Action<any>): ModalState => {
    return {
      ...state,
      data: payload?.data,
      modal: payload?.modal,
      status: payload?.status,
    };
  },
};

const defaultState: ModalState = {
  modal: null,
  status: false,
  data: null,
};

export const modalReducer = handleActions<ModalState>(handlers, defaultState);
