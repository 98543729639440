import {
  UpdatePasswordRequest,
  PhoneOTP,
  SendPhoneOTPRequest,
  CheckUserExistedResult,
  CreatePasswordRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  CategoriesRequest,
  AcceptCollaboratorInvitationRequest,
  SendEmailOTPRequest,
  VerifyEmailOTPRequest,
  SpotifyConnectRequest,
  SpotifyConnectResponse,
  SpotifyUser,
  GetListUserPreSaveRequest,
  GetListUserPreSaveResult,
  JoinFanClubRequest,
  TalentProfileModule,
  WaitlistData,
  CheckTalentExistedRequest,
  SubmitDataCaptureFormRequest,
  BandsintownItem,
  RequestOtpRequest,
  SelfSignUpRequest,
  ResendEmailConfirmationRequest,
  RequestTalentProfileRequest,
  ResendEmailConfirmationResponse,
  EmailVerificationResponse,
} from "./../../redux/User/types";
import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { Category } from "redux/Category/types";
import {
  ListQueryParams,
  Pagination,
  Response,
  CreateShortLinkRequest,
  ShortLink,
  SearchResult,
} from "redux/Common/types";
import {
  GetPurchasingOptionsRequest,
  PurchasingOptions,
  GetPurchasingOptionsByIdRequest,
} from "redux/PurchasingOptions/types";
import {
  CreatePreSaveMusicRequest,
  GetTalentsByCategoryRequest,
  GetTalentsRequest,
  Talent,
  SetupTalentProfileRequest,
  PreSaveMarketingPermission,
} from "redux/Talent/types";
import {
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
  User,
  CheckUserExistedRequest,
} from "redux/User/types";
import { ApiConfig, DEFAULT_API_CONFIG } from "./api.config";
import { getGeneralApiProblem } from "./apiProblem";
import {
  CheckoutRequest,
  GetBraintreeAccessTokenResult,
  TRANSACTION_TYPE,
  CheckoutBookingRequest,
  CheckoutExpertSupportRequest,
  StripePaymentMethod,
} from "redux/Payment/types";
import {
  EXPERIENCE_TYPE,
  Experience,
  GetExperienceByIdRequest,
  GetUserExperiencesRequest,
  GetCategoryExperiencesRequest,
  GetTimeSlotResponse,
  ProductType,
} from "redux/Experience/types";
import {
  Booking,
  GetBookingRequest,
  CreateBookingRequest,
  RateBookingRequest,
  RateBookingResponse,
  TipBookingRequest,
  TipBookingResponse,
  GetTransactionRequest,
  Transaction,
  CompleteBookingRequest,
  UpdateBookingAttachmentsRequest,
} from "redux/Booking/types";
import { RoomToken } from "redux/Room/types";
import { Wishlist } from "redux/Wishlist/types";
import Axios from "axios";
import {
  GetGiftCodeDetail,
  SendGiftCodeRequest,
  BuyGiftCodeRequest,
  GiftCode,
} from "redux/GiftsCode/types";
import Cookies from "js-cookie";
import { KOMI_CURRENCY } from "services/UserService";
import {
  SpecialRequest,
  GetSpecialRequests,
  CreateSpecialRequestPayload,
} from "redux/SpecialRequest/types";
import { Bundle } from "redux/Bundle/types";
import { spotifyService } from "services";
import { komiConsumerUrl } from "services/DomainService";
import { getCorrelationId, getSessionId } from "@komi-app/correlation";
import { ProductKey, ProductSuccess } from "redux/User/sales";

const BANDSINTOWN_APP_ID_BASE = process.env.NEXT_PUBLIC_BANDSINTOWN_APP_ID_BASE || "";

export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauceSecure: ApisauceInstance;
  apisaucePublic: ApisauceInstance;
  apiSpotify: ApisauceInstance;

  /**
   * Configurable options.
   */
  _config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this._config = config;

    this.apisaucePublic = create({
      baseURL: this._config.url,
      timeout: this._config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
    this.apisauceSecure = create({
      baseURL: this._config.url,
      timeout: this._config.timeout,
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });
    this.apiSpotify = create({
      baseURL: this._config.spotifyUrl,
      timeout: this._config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
  }

  /**
   * Get instance of apisauceSecure.
   */
  get instanceSecure(): ApisauceInstance {
    return this.apisauceSecure;
  }

  config(apiConfig: ApiConfig): void {
    if (apiConfig.url !== undefined) {
      this._config.url = apiConfig.url;
    }
    if (apiConfig.spotifyUrl !== undefined) {
      this._config.spotifyUrl = apiConfig.spotifyUrl;
    }
    if (apiConfig.timeout !== undefined) {
      this._config.timeout = apiConfig.timeout;
    }
    if (apiConfig.jwt !== undefined) {
      this._config.jwt = apiConfig.jwt;
    }
    if (apiConfig.talentJwt !== undefined) {
      this._config.talentJwt = apiConfig.talentJwt;
    }
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    this.apisauceSecure.axiosInstance.interceptors.request.use(
      async (config) => {
        const headers: Record<string, string> = {};

        // TODO: Implement auto refresh token here
        const currency = Cookies.get(KOMI_CURRENCY);
        const correlationId = getCorrelationId();
        const sessionId = getSessionId();
        const serviceName = process.env.NEXT_PUBLIC_SERVICE_NAME || "";
        const serviceVersion = process.env.NEXT_PUBLIC_SERVICE_VERSION || "";

        if (currency) {
          headers["local-currency-code"] = currency;
        }
        if (correlationId) {
          headers["x-correlation-id"] = correlationId;
        }
        if (sessionId) {
          headers["x-session-id"] = sessionId;
        }
        if (serviceName) {
          headers["x-service-version"] = serviceVersion;
        }
        if (serviceVersion) {
          headers["x-service-name"] = serviceName;
        }

        config.headers = { ...config.headers, ...headers };

        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    this.apiSpotify.axiosInstance.interceptors.request.use(
      async (config) => {
        const headers: Record<string, string> = {};

        // TODO: Implement auto refresh token here
        const userToken = spotifyService.jwt;
        if (userToken) {
          headers["Authorization"] = `Bearer ${userToken}`;
        }

        config.headers = { ...config.headers, ...headers };

        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );
  }

  setLocalCurrency(currency: string): void {
    this.apisauceSecure.setHeader("local-currency-code", currency);
  }

  removeAuthToken(): void {
    this.apisauceSecure.deleteHeader("Authorization");
  }

  private requestAPI<P, R>(url: string, method: "get" | "delete" | "put" | "post" | "patch") {
    return async (payload?: P): Promise<Response<R>> => {
      let response: ApiResponse<any>;
      switch (method) {
        case "get":
          response = await this.apisauceSecure.get(url, payload);
          break;
        case "post":
          response = await this.apisauceSecure.post(url, payload);
          break;
        case "put":
          response = await this.apisauceSecure.put(url, payload);
          break;
        case "delete":
          response = await this.apisauceSecure.delete(url, payload);
          break;
        case "patch":
          response = await this.apisauceSecure.patch(url, payload);
          break;
        default:
          response = await this.apisauceSecure.get(url, payload);
      }
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem && response?.data?.message && response?.data?.data)
          return {
            kind: "bad-data",
            message: response?.data,
          };
        else if (problem) return problem;
      }

      return { ok: response.ok, response: response.data };
    };
  }

  async postRequest<P = any | undefined, R = any>(url: string, payload?: P): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "post")(payload);
  }

  getRequest = async <P, R = any>(url: string, payload?: P): Promise<Response<R>> => {
    return this.requestAPI<P, R>(url, "get")(payload);
  };

  async putRequest<P, R = any>(url: string, payload?: P): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "put")(payload);
  }

  async patchRequest<P, R = any>(url: string, payload?: P): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "patch")(payload);
  }

  async deleteRequest<P, R = any>(url: string, payload?: P): Promise<Response<R>> {
    return this.requestAPI<P, R>(url, "delete")(payload);
  }

  async signin(payload: SignInRequest): Promise<Response<SignInResponse>> {
    return this.postRequest(`/auth/login`, payload);
  }

  async logout(): Promise<Response<boolean>> {
    return this.postRequest(`/auth/logout`);
  }

  async checkTokenExpiry(): Promise<Response<boolean>> {
    return this.getRequest(`/auth/token/check`);
  }

  async signUp(payload: SignUpRequest): Promise<Response<SignUpResponse>> {
    return this.postRequest(`/auth/register`, payload);
  }

  async forgotPassword(payload: ForgotPasswordRequest): Promise<Response<ForgotPasswordResponse>> {
    return this.postRequest(`/auth/forgot-password/send`, {
      ...payload,
      forgotPasswordUrl: `${komiConsumerUrl}/reset-password`,
    });
  }

  async resetPassword(payload: ResetPasswordRequest): Promise<Response<ResetPasswordResponse>> {
    return this.postRequest(`/auth/forgot-password`, payload);
  }

  async getUserById(): Promise<Response<User>> {
    return this.getRequest(`/users/me`);
  }

  async checkUserExisted(
    payload: CheckUserExistedRequest,
  ): Promise<Response<CheckUserExistedResult>> {
    return this.getRequest(`/users/check-is-existed`, payload);
  }

  async checkUsernameExisted(payload: {
    username: string;
    role: string;
  }): Promise<Response<CheckUserExistedResult>> {
    return this.getRequest(`/users/check-is-existed`, payload);
  }

  async updateUserProfile(payload: User): Promise<Response<User>> {
    return this.patchRequest(`/users/me/consumer-profile`, payload);
  }

  async acceptCollaboratorInvitation(
    payload: AcceptCollaboratorInvitationRequest,
  ): Promise<Response<any>> {
    return this.putRequest(
      `/users/me/talent-profiles/collaborator-invitations/accept`,
      payload.form,
    );
  }

  async getTalents(params: GetTalentsRequest): Promise<Response<Pagination<Talent[]>>> {
    return this.getRequest(`/discovery`, {
      ...params,
      categories: Array.isArray(params.categories) ? params.categories.join(",") : undefined,
    });
  }

  async getTalentsTrending(): Promise<Response<Pagination<Talent[]>>> {
    return this.getRequest(`/discovery`, {
      sort: "talentProfile.trendingOrder",
      trending: true,
    });
  }

  async getAllCategories(params: CategoriesRequest): Promise<Response<Category[]>> {
    return this.getRequest(`/categories/list`, params);
  }

  async getCategoryById(id: number): Promise<Response<Category>> {
    return this.getRequest(`/categories/${id}`);
  }

  async getTalentsByCategory(
    params: GetTalentsByCategoryRequest,
  ): Promise<Response<Pagination<Talent[]>>> {
    return this.getRequest(`/discovery`, {
      ...params,
      categories: params.categories?.length ? params.categories : undefined,
    });
  }

  async getTalentById(id: string): Promise<Response<Talent>> {
    return this.getRequest(`/users/${id}`);
  }

  async getTalentByUsername(username: string, countryCode?: string): Promise<Response<Talent>> {
    return this.getRequest(
      `/talent/usernames/${encodeURIComponent(username)}${
        countryCode ? `?countryCode=${countryCode}` : ""
      }`,
    );
  }

  async getTalentModules(
    talentProfileId: string,
    countryCode?: string,
  ): Promise<Response<TalentProfileModule[]>> {
    return this.getRequest(
      `/talent-profiles/${talentProfileId}/modules${
        countryCode ? `?countryCode=${countryCode}` : ""
      }`,
    );
  }

  async getPurchasingOptions(
    payload: GetPurchasingOptionsRequest,
  ): Promise<Response<Pagination<PurchasingOptions[]>>> {
    const { id, ...rest } = payload;
    return this.getRequest(`/talents/${payload.id}/purchase-options`, rest);
  }

  async getPurchasingOptionsById(
    params: GetPurchasingOptionsByIdRequest,
  ): Promise<Response<PurchasingOptions>> {
    const { talentId, purchaseOptionId } = params;
    return this.getRequest(`/talents/${talentId}/purchase-options/${purchaseOptionId}`);
  }

  async getRoomToken1To1(bookingId: number): Promise<Response<RoomToken>> {
    return this.postRequest<{ bookingId: number }>("/rooms/one-to-one-tutorial/access-token", {
      bookingId,
    });
  }

  async getRoomTokenLiveClass(bookingId: number): Promise<Response<RoomToken>> {
    return this.postRequest<{ bookingId: number }>("/rooms/live-class/access-token", {
      bookingId,
    });
  }

  async checkoutRequest(payload: CheckoutRequest): Promise<Response<Booking>> {
    return this.postRequest(`/payments/payment-intent/video-request`, payload);
  }

  async checkoutBooking(payload: CheckoutBookingRequest): Promise<Response<Booking>> {
    return this.postRequest(`/payments/payment-intent`, {
      ...payload,
      transactionType: TRANSACTION_TYPE.PAYMENT,
    });
  }

  async checkoutExpertSupport(payload: CheckoutExpertSupportRequest): Promise<Response<Booking>> {
    return this.postRequest(`/payments/payment-intent`, {
      ...payload,
      transactionType: TRANSACTION_TYPE.EXPERT_TIP,
    });
  }

  async checkoutSupport(payload: CheckoutExpertSupportRequest): Promise<Response<Booking>> {
    return this.postRequest(`/payments/payment-intent`, {
      ...payload,
      transactionType: TRANSACTION_TYPE.TIP,
    });
  }

  async tipBooking(payload: TipBookingRequest): Promise<Response<TipBookingResponse>> {
    return this.postRequest(`/payments/payment-intent`, {
      ...payload,
      transactionType: TRANSACTION_TYPE.TIP,
    });
  }

  async getBookings(payload: GetBookingRequest): Promise<Response<Pagination<Booking[]>>> {
    return this.getRequest<ListQueryParams>(`users/${payload.userId}/bookings`, payload.params);
  }

  async getBookingById(bookingId: number): Promise<Response<Booking>> {
    return this.getRequest(`/bookings/${bookingId}`);
  }

  async getExperiences(
    type: EXPERIENCE_TYPE | EXPERIENCE_TYPE[],
    params: ListQueryParams,
  ): Promise<Response<Experience[]>> {
    if (type === EXPERIENCE_TYPE.ALL) {
      return this.getRequest<ListQueryParams>(`experiences`, {
        ...params,
      });
    }
    return this.getRequest<ListQueryParams & { type: EXPERIENCE_TYPE | EXPERIENCE_TYPE[] }>(
      `experiences`,
      {
        ...params,
        type,
      },
    );
  }

  async getUserExperiences(
    type: EXPERIENCE_TYPE | EXPERIENCE_TYPE[],
    payload: GetUserExperiencesRequest,
  ): Promise<Response<Experience[]>> {
    const { id, params } = payload;
    if (type === EXPERIENCE_TYPE.ALL) {
      return this.getRequest<ListQueryParams>(`users/${id}/experiences`, {
        ...params,
      });
    }
    return this.getRequest<ListQueryParams & { type: any }>(`users/${id}/experiences`, {
      ...params,
      type: Array.isArray(type) ? type : [type],
    });
  }

  async getBundles(params: ListQueryParams): Promise<Response<Experience[]>> {
    return this.getRequest(`experiences`, {
      ...params,
      type: EXPERIENCE_TYPE.BUNDLE,
    });
  }

  async getUserBundles(
    type: EXPERIENCE_TYPE,
    payload: GetUserExperiencesRequest,
  ): Promise<Response<Bundle[]>> {
    const { id, params } = payload;
    return this.getRequest<ListQueryParams & { type: any }>(`users/${id}/experiences`, {
      ...params,
      type: type,
    });
  }

  async getCategoryExperiences(
    type: EXPERIENCE_TYPE,
    payload: GetCategoryExperiencesRequest,
  ): Promise<Response<Experience[]>> {
    return this.getRequest(`experiences`, {
      ...payload,
      type,
    });
  }

  async getExperienceById(payload: GetExperienceByIdRequest): Promise<Response<Experience>> {
    return this.getRequest<GetExperienceByIdRequest>(`/experiences/${payload.expId}`);
  }

  async getTags(payload: ListQueryParams & Required<{ search: string }>): Promise<Response<any>> {
    return this.getRequest("tags", payload);
  }

  async getWishlist(payload: ListQueryParams): Promise<Response<Pagination<Wishlist>>> {
    return this.getRequest("wishlist", payload);
  }

  async addToWishlist(experienceId: Experience["id"]): Promise<Response<Wishlist>> {
    return this.postRequest("wishlist", {
      experienceId,
    });
  }

  async removeFromWishlist(experienceId: Experience["id"]): Promise<Response<Wishlist>> {
    return this.deleteRequest(`/wishlist/${experienceId}`);
  }

  async createPresignedPhotoUrl(
    fileExtension: string,
    contentType: string,
  ): Promise<Response<any>> {
    return this.postRequest<{ fileExtension: string; contentType: string }>(
      `/files/photos/presigned-url`,
      {
        fileExtension,
        contentType,
      },
    );
  }

  async createPresignedVideoUrl(fileExtension: string): Promise<Response<any>> {
    return this.postRequest<{ fileExtension: string }>(`/files/videos/presigned-url`, {
      fileExtension,
    });
  }

  async updatePassword(payload: UpdatePasswordRequest): Promise<Response<any>> {
    return this.postRequest("/users/update-password", payload);
  }

  async createPassword(payload: CreatePasswordRequest): Promise<Response<any>> {
    return this.postRequest("/users/create-password", payload);
  }

  async followTalent(id: Talent["id"]): Promise<Response<any>> {
    return this.postRequest(`/users/${id}/follow`);
  }

  async unFollowTalent(id: Talent["id"]): Promise<Response<any>> {
    return this.postRequest(`/users/${id}/unfollow`);
  }

  async getTimeSlots(periodId: string): Promise<Response<GetTimeSlotResponse>> {
    return this.getRequest(`/experiences/one-to-one-tutorials/available-slots/${periodId}`);
  }

  async createShortLink(payload: CreateShortLinkRequest): Promise<ShortLink | null> {
    try {
      const response: ApiResponse<any> = await Axios.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.NEXT_PUBLIC_FIREBASE_API_KEY}`,
        payload,
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async getExchangeRates(localCurrency: string): Promise<any> {
    return this.getRequest(`/payments/gbp-exchange-rate`, { localCurrency: localCurrency });
  }

  async getLocation(): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.apisaucePublic.get(
        `https://ipinfo.io/json?token=${process.env.NEXT_PUBLIC_IP_GEO_LOCATION}`,
      );
      return {
        ok: true,
        response: response.data,
      };
    } catch (error) {
      return {
        message: error,
      };
    }
  }

  async handleSearch(keyword: string): Promise<Response<SearchResult>> {
    return this.getRequest(`/search?term=${keyword || ""}`);
  }

  async getBraintreeClientToken(): Promise<Response<GetBraintreeAccessTokenResult>> {
    return this.postRequest(`/transactions/braintree/token`);
  }

  async sendPhoneOTP(payload: SendPhoneOTPRequest): Promise<Response<PhoneOTP>> {
    return this.getRequest(`/users/send-phone-verification`, payload);
  }

  async sendEmailOTP(payload: SendEmailOTPRequest): Promise<Response<any>> {
    return this.postRequest(`/auth/verify-email/send`, payload);
  }

  async verifyEmailOTP(payload: VerifyEmailOTPRequest): Promise<Response<any>> {
    return this.postRequest(`/auth/verify-email/check-code`, payload);
  }

  async signInWithFacebook(accessToken: string): Promise<Response<User>> {
    return this.getRequest(`/auth/facebook-token/callback`, {
      access_token: accessToken,
    });
  }

  async signInWithGoogle(accessToken: string, operation?: string): Promise<Response<User>> {
    return this.getRequest(`/auth/google-token/redirect`, {
      access_token: accessToken,
      operation: operation,
    });
  }

  async talentSignInWithGoogle(accessToken: string, operation: string): Promise<Response<User>> {
    //TODO: Can remove operation(SSX/Talent) as it's not dependent on role
    return this.getRequest(`/auth/talent-google-token/redirect`, {
      access_token: accessToken,
      operation: operation,
    });
  }

  async redirectToSignInApple(): Promise<any> {
    return this.apisaucePublic.post("https://appleid.apple.com/auth/token", {
      client_id: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_APPLE_CLIENT_SECRET,
      redirect_uri: `${komiConsumerUrl}/api/auth/callback/apple`,
      grant_type: "authorization_code",
    });
  }

  async signInWithApple(identityToken: string): Promise<Response<User>> {
    return this.getRequest(`/auth/apple-token/callback`, {
      identityToken: identityToken,
    });
  }

  async createBooking(payload: CreateBookingRequest): Promise<Response<any>> {
    const { type, ...rest } = payload;
    return this.postRequest(`/bookings`, rest);
  }

  updateBookingAttachments = (
    payload: UpdateBookingAttachmentsRequest,
  ): Promise<Response<string[]>> => {
    return this.postRequest(`/bookings/${payload.id}/attachments`, {
      urls: payload.urls,
    });
  };

  async sendGiftCode(payload: SendGiftCodeRequest): Promise<Response<any>> {
    return this.postRequest(`/users/me/gift-codes`, payload);
  }

  async buyGiftCode(payload: BuyGiftCodeRequest): Promise<Response<any>> {
    return this.postRequest(`/users/me/gift-codes`, payload);
  }

  async getGiftDetail(payload: GetGiftCodeDetail): Promise<any> {
    return this.getRequest(
      `/coupon-codes/validate-coupon-code/${payload.giftCodeId}/${payload.experienceId}`,
    );
  }

  async getTalentCredentials(): Promise<Response<SignUpResponse>> {
    return this.getRequest(`/auth/talent-credentials`);
  }

  async getPurchaseGifts(payload: ListQueryParams): Promise<Response<Pagination<GiftCode[]>>> {
    return this.getRequest(`/users/me/purchase-gift-codes`, payload);
  }

  async getListGiftCode(payload: ListQueryParams): Promise<Response<Pagination<GiftCode[]>>> {
    return this.getRequest(`/users/me/gift-codes`, payload);
  }

  async getPurchaseGiftDetail(id: string): Promise<Response<GiftCode>> {
    return this.getRequest(`/users/me/gift-codes/${id}`);
  }

  async rateBooking(data: RateBookingRequest): Promise<Response<RateBookingResponse>> {
    return this.postRequest(`/ratings`, data);
  }

  async getTalentProducts(id: User["id"]): Promise<Response<Pagination<ProductType[]>>> {
    return this.getRequest(`/users/${id}/products`, {
      page: 1,
      limit: 50,
    });
  }

  async getListSpecialRequests(
    payload: GetSpecialRequests,
  ): Promise<Response<Pagination<SpecialRequest[]>>> {
    return this.getRequest(`/users/${payload?.userId}/bookings`, {
      ...payload.params,
      type: [EXPERIENCE_TYPE.SPECIAL_REQUEST],
    });
  }

  async updateBooking(payload: Booking): Promise<Response<Booking>> {
    const { id } = payload;
    return this.putRequest(`/bookings/${id}`, payload);
  }

  async getSpecialRequestById(id: string): Promise<Response<SpecialRequest>> {
    return this.getRequest(`/bookings/${id}`);
  }

  async createSpecialRequest(
    payload: CreateSpecialRequestPayload,
  ): Promise<Response<SpecialRequest>> {
    return this.postRequest(`/bookings/special-request`, payload);
  }

  async acceptSpecialRequest(id: string): Promise<Response<SpecialRequest>> {
    return this.putRequest(`/special-requests/${id}/accept`);
  }

  async getTransactions(
    payload: GetTransactionRequest,
  ): Promise<Response<Pagination<Transaction[]>>> {
    return this.getRequest(`transactions`, {
      createdBy: payload.userId,
      ...payload.params,
    });
  }

  async getTransactionById(transactionId: number): Promise<Response<Transaction>> {
    return this.getRequest(`/transactions/${transactionId}`);
  }

  async completeBooking(payload: CompleteBookingRequest): Promise<Response<Booking>> {
    const { bookingId } = payload;

    return this.postRequest(`bookings/${bookingId}/complete`);
  }

  async getBundleById(id: string): Promise<Response<Bundle>> {
    return this.getRequest(`experiences/${id}`);
  }

  async getPaymentMethods(): Promise<Response<StripePaymentMethod[]>> {
    return this.getRequest(`payments/me/payment-methods`);
  }

  signInSpotify = (params: any): Promise<any> => {
    return this.apisaucePublic.get("https://accounts.spotify.com/authorize", params);
  };

  async getSpotifyById(type: string, id: string): Promise<Response<any>> {
    const response = await this.apiSpotify.get(`/${type}/${id}`);
    if (!response.ok) {
      return getGeneralApiProblem(response);
    }
    return { ok: response.ok, response: response.data };
  }

  async spotifyConnect(params: SpotifyConnectRequest): Promise<Response<SpotifyConnectResponse>> {
    return this.getRequest(`/integrations/spotify-consumer/auth`, params);
  }

  async spotifyRefreshToken(): Promise<Response<SpotifyUser>> {
    return this.getRequest(`/integrations/spotify/token`);
  }

  async createPreSaveMusic(payload: CreatePreSaveMusicRequest): Promise<Response<any>> {
    return this.postRequest(`/users/music/pre-save`, payload);
  }

  async setPreSaveMarketingPermission(payload: PreSaveMarketingPermission): Promise<Response<any>> {
    return this.postRequest(`users/music/pre-save/marketing-permission`, payload);
  }

  async getListUserPreSave(payload: GetListUserPreSaveRequest): Promise<Response<GetListUserPreSaveResult[]>> {
    return this.postRequest(`/users/music/list-pre-save`, payload);
  }

  async joinFanClub(payload: JoinFanClubRequest): Promise<Response<any>> {
    return this.postRequest(`/users/fan-club/join`, payload);
  }

  async joinWaitlist(data: WaitlistData): Promise<Response<any>> {
    return this.postRequest(`/waitlist`, data);
  }

  async getBandsintownData(item: BandsintownItem, date: string): Promise<any> {
    let id = Number(item?.metadata?.id);
    let url = `/artists/id_${id}/events/`;

    if (!Number.isInteger(id)) {
      const name = item?.metadata?.name || "";
      url = `/artists/id_${encodeURIComponent(name.replace("'", ""))}/events/`;
      id = 0;
    }

    return this.apisaucePublic.get(`${process.env.NEXT_PUBLIC_BANDSINTOWN_API_URL}${url}`, {
      app_id: this.getAppId(id),
      date: date,
    });
  }

  async checkExistedTalent(params: CheckTalentExistedRequest): Promise<Response<any>> {
    return this.getRequest(`/talent-profiles/accept-waitlist-application`, params);
  }

  async requestOtp(payload: RequestOtpRequest): Promise<Response<any>> {
    return this.postRequest(`/auth/request-otp`, payload);
  }

  async setupTalentProfile(payload: SetupTalentProfileRequest): Promise<Response<User>> {
    return this.postRequest(`/talent-profile`, payload);
  }

  async submitDataCaptureForm(payload: SubmitDataCaptureFormRequest): Promise<Response<User>> {
    return this.postRequest(`/users/me/submit-form`, payload);
  }

  getUserCountryCode() {
    return this.getRequest("users/ip-country");
  }

  getAppId(artist_id: number): string {
    const str_artist_id = `${artist_id}`;
    const { length } = str_artist_id;
    const REGEXP_LAST_N_CHARS = new RegExp(`.{${length}}$`);

    return BANDSINTOWN_APP_ID_BASE.replace(REGEXP_LAST_N_CHARS, str_artist_id);
  }

  async selfSignUp(payload: SelfSignUpRequest): Promise<Response<User>> {
    return this.postRequest(`/auth/signup`, payload);
  }

  async resendEmailConfirmation(
    payload: ResendEmailConfirmationRequest,
  ): Promise<Response<ResendEmailConfirmationResponse>> {
    return this.postRequest(`/auth/activate/email`, payload);
  }

  async verifyEmail(verificationCode: string): Promise<Response<EmailVerificationResponse>> {
    return this.getRequest(`/auth/activate?code=${verificationCode}`);
  }

  async requestTalentProfile(payload: RequestTalentProfileRequest): Promise<Response<any>> {
    return this.postRequest(`/talent-profiles/top-talent-request`, payload);
  }

  static isOKResponse(response: unknown): response is { ok: any } {
    return !!response && typeof response === "object" && "ok" in response;
  }

  // Sales
  getSalesProducts(product_key: ProductKey): Promise<Response<ProductSuccess>> {
    return this.getRequest(`/sales/products/${product_key}/public`);
  }
}
