import { createGenericTypes, createGenericActions } from "utils/createAction";
import { createAction } from "redux-actions";

export const signInTypes = createGenericTypes("SIGN_IN");
export const signInActions = createGenericActions(signInTypes);

export const signInWithFacebookTypes = createGenericTypes("SIGN_IN_FACEBOOK");
export const signInWithFacebookActions = createGenericActions(signInWithFacebookTypes);

export const signInWithGoogleTypes = createGenericTypes("SIGN_IN_GOOGLE");
export const signInWithGoogleActions = createGenericActions(signInWithGoogleTypes);

export const signInWithAppleTypes = createGenericTypes("SIGN_IN_APPLE");
export const signInWithAppleActions = createGenericActions(signInWithAppleTypes);

export const signUpTypes = createGenericTypes("SIGN_UP");
export const signUpActions = createGenericActions(signUpTypes);

export const forgotPasswordTypes = createGenericTypes("FORGOT_PASSWORD");
export const forgotPasswordActions = createGenericActions(forgotPasswordTypes);

export const resetPasswordTypes = createGenericTypes("RESET_PASSWORD");
export const resetPasswordActions = createGenericActions(resetPasswordTypes);

export const logoutTypes = createGenericTypes("LOGOUT");
export const logoutActions = createGenericActions(logoutTypes);

export const getUserByIdTypes = createGenericTypes("GET_USER_BY_ID");
export const getUserByIdActions = createGenericActions(getUserByIdTypes);

export const createPasswordTypes = createGenericTypes("CREATE_PASSWORD");
export const createPasswordActions = createGenericActions(createPasswordTypes);

export const updatePasswordTypes = createGenericTypes("UPDATE_PASSWORD");
export const updatePasswordActions = createGenericActions(updatePasswordTypes);

export const updateUserProfileTypes = createGenericTypes("UPDATE_USER_PROFILE");
export const updateUserProfileActions = createGenericActions(updateUserProfileTypes);

export const resetUserActionType = "RESET_USER_ACTION_TYPE";
export const resetUserActionTypeAction = createAction(resetUserActionType);

export const setUserValueType = "RESET_USER_VALUE_TYPE";
export const setUserValueAction = createAction(setUserValueType);

export const sendOTPTypes = createGenericTypes("SEND_OTP");
export const sendOTPActions = createGenericActions(sendOTPTypes);

export const verifyOTPTypes = createGenericTypes("VERIFY_OTP");
export const verifyOTPActions = createGenericActions(verifyOTPTypes);

export const checkUserExistedTypes = createGenericTypes("CHECK_USER_EXISTED");
export const checkUserExistedActions = createGenericActions(checkUserExistedTypes);

export const requestOtpTypes = createGenericTypes("REQUEST_OTP");
export const requestOtpActions = createGenericActions(requestOtpTypes);

export const getTalentCredentialTypes = createGenericTypes("GET_TALENT_CREDENTIALS");
export const getTalentCredentialActions = createGenericActions(getTalentCredentialTypes);

export const updateUserTimezoneTypes = createGenericTypes("UPDATE_USER_TIMEZONE");
export const updateUserTimezoneActions = createGenericActions(updateUserTimezoneTypes);

export const addBookingSetupTypes = createGenericTypes("ADD_BOOKING_SETUP");
export const addBookingSetupActions = createGenericActions(addBookingSetupTypes);

export const acceptCollaboratorInviteTypes = createGenericTypes("ACCEPT_COLLABORATOR_INVITE");
export const acceptCollaboratorInviteActions = createGenericActions(acceptCollaboratorInviteTypes);

export const getListUserPreSaveSpotifyTypes = createGenericTypes("GET_LIST_USER_PRESAVE_SPOTIFY");
export const getListUserPreSaveSpotifyActions = createGenericActions(getListUserPreSaveSpotifyTypes);

export const getListUserPreSaveAppleTypes = createGenericTypes("GET_LIST_USER_PRESAVE_APPLE");
export const getListUserPreSaveAppleActions = createGenericActions(getListUserPreSaveAppleTypes);

export const joinFanClubTypes = createGenericTypes("JOIN_FAN_CLUB");
export const joinFanClubActions = createGenericActions(joinFanClubTypes);

export const talentLoginTypes = createGenericTypes("TALENT_LOGIN");
export const talentLoginActions = createGenericActions(talentLoginTypes);

export const talentLoginWithGoogleTypes = createGenericTypes("TALENT_LOGIN_WITH_GOOGLE");
export const talentLoginWithGoogleActions = createGenericActions(talentLoginWithGoogleTypes);
