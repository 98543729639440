import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { ListQueryParams } from "redux/Common/types";
import { call, put, takeLatest } from "typed-redux-saga";
import { tagService } from "./../../services/index";
import { getTagsActions } from "./actions";

function* getTags({ payload }: Action<ListQueryParams & Required<{ search: string }>>) {
  const result: any = yield* call(tagService.getTags, payload);
  if (result.ok) {
    yield* put(getTagsActions.SUCCESS(result.response));
  } else {
    yield* put(getTagsActions.FAILURE(result?.message || null));
  }
}

export default function* tagSagas(): SagaIterator {
  yield* takeLatest(getTagsActions.REQUEST, getTags);
}
