interface ServicePortMap extends Record<string, number> {
  development: number;
  production: number;
  staging: number;
}

export const environment = process.env.NEXT_PUBLIC_NODE_ENV || "development";
export const komiRootDomain = process.env.NEXT_PUBLIC_CI_BUILD
  ? process.env.NEXT_PUBLIC_CI_ROOT_DOMAIN
  : process.env.NEXT_PUBLIC_ROOT_DOMAIN;

export enum KomiService {
  admin = "admin",
  analytics = "analytics",
  api = "api",
  consumer = "consumer",
  shopifyStore = "shopify-store",
  talent = "talent",
}

// Port mapping
//   This is a holdover until we get the local proxy working
const getEnvPort = (portMap: ServicePortMap): number => portMap[environment];
const getApiPort = (): number =>
  getEnvPort({
    development: 3000,
    production: 3001,
    staging: 3000,
  });
const getConsumerPort = (): number =>
  getEnvPort({
    development: 8080,
    production: 8082,
    staging: 8081,
  });

const localPorts: Record<string, number> = {
  admin: 4000,
  api: getApiPort(),
  consumer: getConsumerPort(),
  talent: 2000,
};

// Environment mapping
const envSubdomainMap: Record<string, string> = {
  development: "develop",
  staging: "staging",
  production: "",
};

/**
 * Creates an environment-specific domain for a specified service
 *
 * @param {string} komiServiceName The service name for which the URL is being created for, e.g., api
 * @returns {string} An environment-specific domain
 */
export const createEnvDomain = (komiServiceName: KomiService): string => {
  if (process.env.NEXT_PUBLIC_CI_BUILD) {
    const komiEnvSubdomain = envSubdomainMap[environment];
    const komiEnvSubdomainPart = komiEnvSubdomain ? `${komiEnvSubdomain}.` : "";

    return komiServiceName === KomiService.consumer
      ? `${komiEnvSubdomainPart}${komiRootDomain}`
      : `${komiServiceName}.${komiEnvSubdomainPart}${komiRootDomain}`;
  } else {
    return komiServiceName === KomiService.consumer
      ? `${komiRootDomain}`
      : `${komiServiceName}.${komiRootDomain}`;
  }
};

/**
 * Creates a Komi service URL, including the environment
 *
 * @param komiServiceName The Komi service's subdomain
 * @param path The komi service's root path
 * @returns The built Komi service URL
 */
export const createKomiDomain = (komiServiceName: KomiService, path = ""): string => {
  const isLocalhost = Boolean(process.env.NEXT_PUBLIC_LOCAL);

  const komiProtocol = isLocalhost ? "http" : "https";
  const localCompliantRoot = isLocalhost
    ? `localhost:${localPorts[komiServiceName]}`
    : createEnvDomain(komiServiceName);

  return `${komiProtocol}://${localCompliantRoot}${path}`;
};

// These need to be functions so that the URLs can lazy load once window is defined

// NEXT_PUBLIC_URL
export const komiConsumerUrl = createKomiDomain(KomiService.consumer);

// NEXT_PUBLIC_API_URL
export const komiApiUrl = createKomiDomain(KomiService.api, "/api");

// NEXT_PUBLIC_TALENT_URL
export const komiTalentUrl = createKomiDomain(KomiService.talent);
