import { createGenericTypes, createGenericActions } from "utils/createAction";
import { createAction } from "redux-actions";

export const getBookingsTypes = createGenericTypes("GET_BOOKINGS");
export const getBookingsActions = createGenericActions(getBookingsTypes);

export const getUpcomingBookingsTypes = createGenericTypes("GET_UPCOMING_BOOKINGS");
export const getUpcomingBookingsActions = createGenericActions(getUpcomingBookingsTypes);

export const getMyVideosTypes = createGenericTypes("GET_MY_VIDEOS");
export const getMyVideosActions = createGenericActions(getMyVideosTypes);

export const getBookingByIdTypes = createGenericTypes("GET_BOOKING_BY_ID");
export const getBookingByIdActions = createGenericActions(getBookingByIdTypes);

export const createBookingTypes = createGenericTypes("CREATE_BOOKING");
export const createBookingActions = createGenericActions(createBookingTypes);

export const rateBookingTypes = createGenericTypes("RATE_BOOKING");
export const rateBookingActions = createGenericActions(rateBookingTypes);

export const tipBookingTypes = createGenericTypes("TIP_BOOKING");
export const tipBookingActions = createGenericActions(tipBookingTypes);

export const completeBookingTypes = createGenericTypes("COMPLETE_BOOKING");
export const completeBookingActions = createGenericActions(completeBookingTypes);

export const resetBookingActionType = "RESET_BOOKING_ACTION_TYPE";
export const resetBookingActionTypeAction = createAction(resetBookingActionType);

export const updateBookingAttachmentsTypes = createGenericTypes("CREATE_BOOKING_ATTACHMENTS");
export const updateBookingAttachmentsActions = createGenericActions(updateBookingAttachmentsTypes);
