import { all } from "typed-redux-saga";
import bookingSagas from "./Booking/sagas";
import categorySagas from "./Category/sagas";
import experienceSagas from "./Experience/sagas";
import paymentSagas from "./Payment/sagas";
import purchasingOptionsSagas from "./PurchasingOptions/sagas";
import roomSagas from "./Room/sagas";
import tagSagas from "./Tags/sagas";
import talentSagas from "./Talent/sagas";
import userSagas from "./User/sagas";
import wishlistSagas from "./Wishlist/sagas";
import commonSagas from "./Common/sagas";
import giftCodesSagas from "./GiftsCode/sagas";
import specialRequestSagas from "./SpecialRequest/sagas";
import bundleSagas from "./Bundle/sagas";

export default function* rootSaga(): IterableIterator<any> {
  yield* all([
    userSagas(),
    talentSagas(),
    categorySagas(),
    purchasingOptionsSagas(),
    roomSagas(),
    paymentSagas(),
    bookingSagas(),
    tagSagas(),
    experienceSagas(),
    wishlistSagas(),
    commonSagas(),
    giftCodesSagas(),
    specialRequestSagas(),
    bundleSagas(),
  ]);
}
