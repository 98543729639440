import { Api } from "./api";
import { Bundle } from "redux/Bundle/types";
import { Response, ListQueryParams } from "redux/Common/types";
import { GetUserExperiencesRequest, EXPERIENCE_TYPE } from "redux/Experience/types";

export default class BundleService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getById = async (id: string): Promise<Response<Bundle>> => {
    return this.api.getBundleById(id);
  };

  getAll = (params: ListQueryParams): Promise<Response<Bundle[]>> => {
    return this.api.getBundles(params);
  };

  getAllByUser = (params: GetUserExperiencesRequest): Promise<Response<Bundle[]>> => {
    return this.api.getUserBundles(EXPERIENCE_TYPE.BUNDLE, params);
  };
}
