import { useMediaQuery } from "react-responsive";
import { useState, useEffect, useCallback } from "react";
import isWebView from "is-ua-webview";
export const useDesktop = () => {
  return useMediaQuery({
    query: "(min-width: 900px)",
  });
};

export const useResize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, [setHeight, setWidth]);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return { width, height };
};

export const checkIsMobile = (req: any) => {
  const UA = req?.headers?.["user-agent"];
  const isMobile = Boolean(UA?.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i));
  return isMobile;
};
export const checkIsWebView = (req: any) => {
  const UA = req?.headers?.["user-agent"];
  if (UA) {
    return isWebView(UA);
  }
  return false;
};
