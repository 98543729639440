const API_SPOTIFY_URL = process.env.NEXT_PUBLIC_SPOTIFY_URL || "";
const API_URL = process.env.NEXT_PUBLIC_API_URL || "";

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url?: string;

  /**
   * The URL of the spotify api.
   */
  spotifyUrl?: string;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout?: number;

  jwt?: string;

  talentJwt?: string;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  spotifyUrl: API_SPOTIFY_URL,
  timeout: 20000,
};
