import { Action, handleActions } from "redux-actions";
import { getTagsActions } from "./actions";
import { TagState } from "./types";

const handlers = {
  // get room token
  [getTagsActions.REQUEST]: (state: TagState): TagState => {
    return {
      ...state,
      error: null,
      isLoading: true,
    };
  },
  [getTagsActions.SUCCESS]: (state: TagState, { payload }: Action<any>): TagState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      tags: payload,
    };
  },
  [getTagsActions.FAILURE]: (state: TagState, { payload }: Action<any>): TagState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
};

const defaultState: TagState = {
  tags: null,
  isLoading: false,
  error: null,
};

export const tagReducer = handleActions<TagState>(handlers, defaultState);
