import {
  getTalentsActions,
  getTalentByIdActions,
  getTalentsTrendingActions,
  getTalentsByCategoryActions,
  getTalentByUsernameActions,
  getTalentAllExperiencesActions,
  getTalentProductsActions,
  getTrendingTalentsByCategoryActions,
  getTalentExclusiveContentExperiencesActions,
  createPreSaveMusicActions,
  setPreSaveMarketingPermissionActions,
  getTalentModulesActions,
  getTalentBasicInfoActions,
  setActiveModuleType,
  setPlayerPlayingType,
} from "./actions";
import { TalentState } from "./types";
import { handleActions, Action } from "redux-actions";
import uniqBy from "lodash/unionBy";

const handlers = {
  // get list talents
  [getTalentsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loading: true,
  }),
  [getTalentsActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    talents: payload.meta.currentPage === 1 ? [...payload.items] : uniqBy([...state.talents, ...payload.items], "id"),
    talentsTotalPages: payload.items.length > 0 ? payload.meta.totalPages : state.talentsTotalPages,
    loading: false,
  }),
  [getTalentsActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get list talents trending
  [getTalentsTrendingActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loadingTrending: true,
  }),
  [getTalentsTrendingActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    talentsTrending: payload,
    loadingTrending: false,
  }),
  [getTalentsTrendingActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loadingTrending: false,
  }),
  // get talent by id
  [getTalentByIdActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loading: true,
  }),
  [getTalentByIdActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    talent: payload,
    loading: false,
  }),
  [getTalentByIdActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get talent by username
  [getTalentByUsernameActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loadingTalentProfile: true,
    preSaveData: null,
  }),
  [getTalentByUsernameActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    talent: payload,
    loadingTalentProfile: false,
  }),
  [getTalentByUsernameActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => {
    return {
      ...state,
      error: payload.message,
      errorCode: payload.kind,
      loadingTalentProfile: false,
    };
  },
  // get talent basic info for meta tag
  [getTalentBasicInfoActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    talentBasicInfo: null,
  }),
  [getTalentBasicInfoActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    talentBasicInfo: payload,
  }),
  [getTalentBasicInfoActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => {
    return {
      ...state,
      error: payload.message,
      errorCode: payload.kind,
    };
  },
  // get talent modules
  [getTalentModulesActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loadingModules: true,
    talentModules: [],
  }),
  [getTalentModulesActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    talentModules: payload,
    loadingModules: false,
  }),
  [getTalentModulesActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loadingModules: false,
  }),
  // get list talents by category
  [getTalentsByCategoryActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loading: true,
  }),
  [getTalentsByCategoryActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    talentsByCategory:
      payload.meta.currentPage === 1
        ? [...payload.items]
        : uniqBy([...state.talentsByCategory, ...payload.items], "id"),
    talentsByCategoryLatestPage:
      payload.items.length > 0 ? payload.meta.currentPage : state.talentsByCategoryLatestPage,
    talentsByCategoryTotalPages: payload.items.length > 0 ? payload.meta.totalPages : state.talentsByCategoryTotalPages,
    loading: false,
  }),
  [getTalentsByCategoryActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get list trending talents by category
  [getTrendingTalentsByCategoryActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loadingTrending: true,
  }),
  [getTrendingTalentsByCategoryActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    trendingTalentsByCategory:
      payload.meta.currentPage === 1
        ? [...payload.items]
        : uniqBy([...state.trendingTalentsByCategory, ...payload.items], "id"),
    trendingTalentsByCategoryLatestPage:
      payload.items.length > 0 ? payload.meta.currentPage : state.trendingTalentsByCategoryLatestPage,
    loadingTrending: false,
  }),
  [getTrendingTalentsByCategoryActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loadingTrending: false,
  }),
  // get all experiences of talent
  [getTalentAllExperiencesActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getTalentAllExperiencesActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [getTalentAllExperiencesActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    loading: false,
    allLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.allLatestPage,
    isAllLastPage: payload.meta.totalPages <= payload.meta.currentPage,
    allExperiences: uniqBy([...state.allExperiences, ...payload.items], "id"),
  }),
  // get experience exclusive content of talent
  [getTalentExclusiveContentExperiencesActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getTalentExclusiveContentExperiencesActions.FAILURE]: (
    state: TalentState,
    { payload }: Action<any>,
  ): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [getTalentExclusiveContentExperiencesActions.SUCCESS]: (
    state: TalentState,
    { payload }: Action<any>,
  ): TalentState => ({
    ...state,
    loading: false,
    exclusiveContentLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.exclusiveContentLatestPage,
    exclusiveContentExperiences: uniqBy([...state.exclusiveContentExperiences, ...payload.items], "id"),
    isExclusiveContentLastPage: payload.meta.totalPages <= payload.meta.currentPage,
  }),

  // get list products of talent
  [getTalentProductsActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loading: true,
  }),
  [getTalentProductsActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    products: payload,
    loading: false,
  }),
  [getTalentProductsActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create pre save music
  [createPreSaveMusicActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loading: true,
    preSaveData: null,
  }),
  [createPreSaveMusicActions.SUCCESS]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    preSaveData: payload,
    loading: false,
  }),
  [createPreSaveMusicActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // set presave music marketing permissions
  [setPreSaveMarketingPermissionActions.REQUEST]: (state: TalentState): TalentState => ({
    ...state,
    loading: true,
  }),
  [setPreSaveMarketingPermissionActions.SUCCESS]: (state: TalentState): TalentState => ({
    ...state,
    loading: false,
  }),
  [setPreSaveMarketingPermissionActions.FAILURE]: (state: TalentState, { payload }: Action<any>): TalentState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [setActiveModuleType]: (state: TalentState, { payload }: Action<any>): TalentState => {
    return {
      ...state,
      activeModule: payload,
    };
  },
  [setPlayerPlayingType]: (state: TalentState, { payload }: Action<any>): TalentState => {
    return {
      ...state,
      playerPlaying: payload,
    };
  },
};

const initialState: TalentState = {
  talents: [],
  talentModules: [],
  talentsTotalPages: 1,
  talentsTrending: null,
  talent: null,
  talentBasicInfo: null,
  error: null,
  errorCode: "",
  loading: false,
  loadingTrending: false,
  talentsByCategory: [],
  talentsByCategoryLatestPage: 1,
  talentsByCategoryTotalPages: 1,
  trendingTalentsByCategory: [],
  trendingTalentsByCategoryLatestPage: 1,
  allExperiences: [],
  allLatestPage: 1,
  isAllLastPage: false,
  exclusiveContentExperiences: [],
  exclusiveContentLatestPage: 1,
  isExclusiveContentLastPage: false,
  products: null,
  preSaveData: null,
  loadingTalentProfile: false,
  loadingModules: false,
  activeModule: null,
  playerPlaying: null,
};

export const talentReducer = handleActions<TalentState>(handlers, initialState);
