import {
  GetPurchasingOptionsRequest,
  PurchasingOptions,
  GetPurchasingOptionsByIdRequest,
} from "redux/PurchasingOptions/types";
import {
  CreatePreSaveMusicRequest,
  GetTalentsByCategoryRequest,
  GetTalentsRequest,
  Talent,
  SetupTalentProfileRequest,
  PreSaveMarketingPermission,
} from "redux/Talent/types";
import { Pagination, Response } from "redux/Common/types";
import { Api } from "./api";
import {
  ProductType,
  EXPERIENCE_TYPE,
  Experience,
  GetUserExperiencesRequest,
} from "redux/Experience/types";
import {
  BandsintownItem,
  RequestTalentProfileRequest,
  TalentProfileModule,
  User,
} from "redux/User/types";

export interface ITalentService {
  getTalentsTrending(): Promise<Response<Pagination<Talent[]>>>;
  getTalentById(id: string): Promise<Response<Talent>>;
  getTalentByUsername(username: string, countryCode?: string): Promise<Response<Talent>>;
  getTalentsByCategory(
    payload: GetTalentsByCategoryRequest,
  ): Promise<Response<Pagination<Talent[]>>>;
  getPurchasingOptions(
    payload: GetPurchasingOptionsRequest,
  ): Promise<Response<Pagination<PurchasingOptions[]>>>;
}
export default class TalentService implements ITalentService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getTalents = (params: GetTalentsRequest): Promise<Response<Pagination<Talent[]>>> => {
    return this.api.getTalents(params);
  };

  getTalentsTrending = (): Promise<Response<Pagination<Talent[]>>> => {
    return this.api.getTalentsTrending();
  };

  getTalentsByCategory = (
    payload: GetTalentsByCategoryRequest,
  ): Promise<Response<Pagination<Talent[]>>> => {
    return this.api.getTalentsByCategory(payload);
  };

  getTalentById = (id: string): Promise<Response<Talent>> => {
    return this.api.getTalentById(id);
  };

  getTalentByUsername = (username: string, countryCode?: string): Promise<Response<Talent>> => {
    return this.api.getTalentByUsername(username, countryCode);
  };

  getTalentModules = (
    talentProfileId: string,
    countryCode?: string,
  ): Promise<Response<TalentProfileModule[]>> => {
    return this.api.getTalentModules(talentProfileId, countryCode);
  };

  getPurchasingOptions = (
    payload: GetPurchasingOptionsRequest,
  ): Promise<Response<Pagination<PurchasingOptions[]>>> => {
    return this.api.getPurchasingOptions(payload);
  };

  getPurchasingOptionsById = (
    params: GetPurchasingOptionsByIdRequest,
  ): Promise<Response<PurchasingOptions>> => {
    return this.api.getPurchasingOptionsById(params);
  };

  followTalent = (id: Talent["id"]): Promise<Response<any>> => {
    return this.api.followTalent(id);
  };

  unFollowTalent = (id: Talent["id"]): Promise<Response<any>> => {
    return this.api.unFollowTalent(id);
  };

  getTalentProducts = (id: User["id"]): Promise<Response<Pagination<ProductType[]>>> => {
    return this.api.getTalentProducts(id);
  };

  getUserExperiences = (params: GetUserExperiencesRequest): Promise<Response<Experience[]>> => {
    return this.api.getUserExperiences([EXPERIENCE_TYPE.ON_DEMAND_CONTENT], params);
  };

  createPreSaveMusic = (payload: CreatePreSaveMusicRequest): Promise<Response<any>> => {
    return this.api.createPreSaveMusic(payload);
  };

  setPreSaveMarketingPermission = (payload: PreSaveMarketingPermission): Promise<Response<any>> => {
    return this.api.setPreSaveMarketingPermission(payload);
  };

  getAllUserExperiences = (params: GetUserExperiencesRequest): Promise<Response<Experience[]>> => {
    return this.api.getUserExperiences(
      [
        EXPERIENCE_TYPE.ONE_TO_ONE,
        EXPERIENCE_TYPE.LIVE_CLASS,
        EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS,
        EXPERIENCE_TYPE.COURSE,
        EXPERIENCE_TYPE.BUNDLE,
      ],
      params,
    );
  };

  getBandsintownData = (item: BandsintownItem, date: string): Promise<Response<[]>> => {
    return this.api.getBandsintownData(item, date);
  };

  setupTalentProfile = (payload: SetupTalentProfileRequest): Promise<Response<any>> => {
    return this.api.setupTalentProfile(payload);
  };

  requestTalentProfile = (payload: RequestTalentProfileRequest): Promise<Response<any>> => {
    return this.api.requestTalentProfile(payload);
  };
}
