const maxExpiryEntry = "Expires=Thu, 01 Jan 9999 00:00:01 GMT;";
const minExpiryEntry = "Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

/**
 * Creates the expiry entry of a cookie.
 *
 * @param expiryOffset {number} The number of seconds offset from now until the cookie's expiry
 * @returns {string} The date at which the cookie will expire, as a UTC string
 */
function createCookieExpiryEntry(expiryOffset: number): string {
  const expiryMs = expiryOffset * 1000;
  const expiry = Date.now() + expiryMs;
  const expiryDate = new Date(expiry);
  const expiryUTC = expiryDate.toUTCString();

  return `Expires=${expiryUTC};`;
}

/**
 * Set a cookie, which expires at 1st January 9999
 *
 * @param name {string} The cookie's name
 * @param value {string} The cookie's value
 */
export function setCookie(name: string, value: string): void {
  if (process.browser) {
    document.cookie = `${name}=${value}; Path=/; ${maxExpiryEntry}`;
  }
}

/**
 * Set a cookie with an optional expiry offset
 *
 * @param name {string} The cookie's name
 * @param value {string} The cookie's value
 * @param expiryOffset {number} The number of seconds offset from now until the cookie's expiry
 */
export function setExpirableCookie(name: string, value: string, expiryOffset = 0): void {
  if (process.browser) {
    const expiryEntry = expiryOffset > 0 ? createCookieExpiryEntry(expiryOffset) : maxExpiryEntry;
    const cookieValue = `${name}=${value}; Path=/; ${expiryEntry}`;

    const isLocalhost = window.location.host.includes("localhost");
    document.cookie = isLocalhost ? cookieValue : `${cookieValue} Domain=.komi.io`;
  }
}

// TODO: This can be replaced with `Cookies.get` from `js-cookie`
export function getCookie(name: string, cookieString = ""): string {
  if (!cookieString && process.browser) {
    cookieString = document.cookie;
  }

  const match = cookieString?.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : "";
}

// TODO: This can be replaced with `Cookies.remove` from `js-cookie`
export function deleteCookie(name: string): void {
  if (process.browser) {
    document.cookie = `${name}=; Path=/; ${minExpiryEntry}`;
  }
}
