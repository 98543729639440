import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { bundleService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import { getBundleByIdActions } from "./actions";

function* getBundleById({ payload }: Action<any>) {
  const result: any = yield* call(bundleService.getById, payload);
  if (result.ok) {
    yield* put(getBundleByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getBundleByIdActions.FAILURE(result.message));
  }
}

export default function* bundleSagas(): SagaIterator {
  yield* takeLatest(getBundleByIdActions.REQUEST, getBundleById);
}
