import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import { CreateShortLinkRequest, ShortLink } from "redux/Common/types";
import { Api } from "services/api";

interface IFirebaseService {
  write(key: string, value: any): any;
  getOnce(key: string): any;
  getDataRef(key: string): any;
  createShortLink(payload: CreateShortLinkRequest): Promise<ShortLink | null>;
}

export default class FirebaseService implements IFirebaseService {
  private database: firebase.database.Database;

  constructor(private readonly api: Api) {
    const config = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
      appId: process.env.NEXT_PUBLIC_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    this.api = api;
    // Get a reference to the database service
    this.database = firebase.database();
  }

  createShortLink = (payload: CreateShortLinkRequest): Promise<ShortLink | null> => {
    return this.api.createShortLink(payload);
  };

  write = (key: string, value: any) => {
    return this.database.ref(key).set(value);
  };

  getOnce = (key: string) => {
    return firebase.database().ref(key).once("value");
  };

  getDataRef = (key: string) => {
    return firebase.database().ref(key);
  };

  logCurrentPage = (url: string) => {
    firebase.analytics().logEvent("screen_view" as string);
  };
}
