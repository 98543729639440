import { createAction } from "redux-actions";

type RequestType = {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
};

type ActionType = {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
};

export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

export const createGenericTypes = (base: string) => {
  const TYPES = [REQUEST, SUCCESS, FAILURE].reduce((types, type) => {
    types[type as keyof RequestType] = `${base}_${type}`;
    return types;
  }, {} as Record<keyof RequestType, string>);
  return TYPES;
};

export const createGenericActions = (types: any) => {
  return [REQUEST, SUCCESS, FAILURE].reduce((actions, type) => {
    actions[type as keyof ActionType] = createAction(types[type]);
    return actions;
  }, {} as Record<keyof ActionType, any>);
};

export const getActionBaseName = (val: string): string => {
  const splitData = val.split("_");
  splitData.pop();
  return splitData.join("_");
};
