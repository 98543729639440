import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { IToken } from "redux/Common/types";
import { RequestOtpRequest, SignInRequest } from "redux/User/types";
import { setCookie, setExpirableCookie } from "utils/cookie";
import { Response } from "./../redux/Common/types";
import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
  User,
} from "./../redux/User/types";
import { Api } from "./api/api";

export const JWT_STORE_KEY = "KOMI_CLIENT_JWT";
export const TALENT_JWT_KEY = "KOMI_TALENT_AUTH_JWT";
export const DEVICE_ID = "KOMI_DEVICE_ID";

export interface IAuthService {
  signin(payload: SignInRequest): Promise<Response<SignInResponse>>;
  signUp(payload: SignUpRequest): Promise<Response<SignUpResponse>>;
}

export default class AuthService implements IAuthService {
  constructor(private readonly api: Api) {
    this.api = api;
  }
  private __jwt = "";
  private __talent_jwt = "";
  private __device_id = "";

  set jwt(value: string) {
    try {
      const parseToken: IToken = jwtDecode(value);
      if (parseToken?.roles?.includes("talent")) {
        setCookie(TALENT_JWT_KEY, value);
        this.__talent_jwt = value;
        this.api.config({ talentJwt: this.__talent_jwt });
      }
      if (parseToken?.roles?.includes("user")) {
        setCookie(JWT_STORE_KEY, value);
        this.__jwt = value;
        this.api.config({ jwt: this.__jwt });
      }
    } catch (error) {
      console.log(error);
    }
  }

  get jwt(): string {
    if (!this.__jwt && Cookies.get(JWT_STORE_KEY)) {
      this.__jwt = Cookies.get(JWT_STORE_KEY) as string;
    }
    return this.__jwt || "";
  }

  set deviceId(value: string) {
    try {
      if (value) {
        setExpirableCookie(DEVICE_ID, value, 30 * 24 * 60 * 60);
        this.__device_id = value;
      }
    } catch (error) {
      console.log(error);
    }
  }

  get deviceId(): string {
    if (!this.__device_id && Cookies.get(DEVICE_ID)) {
      this.__device_id = Cookies.get(DEVICE_ID) as string;
    }
    return this.__device_id || "";
  }

  get id(): string | null {
    try {
      const { jwt } = this;
      const parseToken: IToken = jwtDecode(jwt);
      return jwt ? parseToken.userId : null;
    } catch (error) {
      return null;
    }
  }

  logout = (): Promise<Response<boolean>> => {
    Cookies.set(JWT_STORE_KEY, "");
    Cookies.set(TALENT_JWT_KEY, "");
    this.__talent_jwt = "";
    this.__jwt = "";
    this.api.config({
      talentJwt: this.__talent_jwt,
      jwt: this.__jwt,
    });

    return this.api.logout();
  };

  signin = (payload: SignInRequest): Promise<Response<SignInResponse>> => {
    return this.api.signin(payload);
  };

  checkTokenExpiry = (): Promise<Response<boolean>> => {
    return this.api.checkTokenExpiry();
  };

  signUp = (payload: SignUpRequest): Promise<Response<SignUpResponse>> => {
    return this.api.signUp(payload);
  };

  forgotPassword = (payload: ForgotPasswordRequest): Promise<Response<ForgotPasswordResponse>> => {
    return this.api.forgotPassword(payload);
  };

  resetPassword = (payload: ResetPasswordRequest): Promise<Response<ResetPasswordResponse>> => {
    return this.api.resetPassword(payload);
  };

  signInWithFacebook = (accessToken: string): Promise<Response<User>> => {
    return this.api.signInWithFacebook(accessToken);
  };

  signInWithGoogle = (accessToken: string, operation?: string): Promise<Response<User>> => {
    return this.api.signInWithGoogle(accessToken, operation);
  };

  talentSignInWithGoogle = (accessToken: string, operation: string): Promise<Response<User>> => {
    return this.api.talentSignInWithGoogle(accessToken, operation);
  };

  signInWithApple = (identityToken: string): Promise<Response<User>> => {
    return this.api.signInWithApple(identityToken);
  };

  redirectToSignInApple = (): Promise<Response<User>> => {
    return this.api.redirectToSignInApple();
  };

  getTalentCredentials = (): Promise<Response<SignUpResponse>> => {
    return this.api.getTalentCredentials();
  };

  setCurrency = (currency: string) => {
    this.api.setLocalCurrency(currency);
  };

  signInSpotify = (params: any): Promise<any> => {
    return this.api.signInSpotify(params);
  };

  requestOtp = (payload: RequestOtpRequest): Promise<Response<any>> => {
    return this.api.requestOtp(payload);
  };
}
