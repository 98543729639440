import React, { ReactNode } from "react";
import AntdModal, { ModalProps } from "antd/lib/modal";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close.svg";

interface IProps extends ModalProps {
  children: ReactNode;
  backIcon?: any;
  closeIcon?: any;
}

const Modal: React.FC<IProps> = ({ children, backIcon, closeIcon = <CloseIcon />, ...props }) => {
  return (
    <AntdModal destroyOnClose={true} closeIcon={closeIcon} {...props}>
      {backIcon}
      {children}
    </AntdModal>
  );
};

export default Modal;
