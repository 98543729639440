// tslint:disable

import { CountriesCurrency } from "constants/countries-currency";
import CurrencyCodes from "currency-codes";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "redux/rootReducer";
import { setUserValueAction, updateUserTimezoneActions } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import { userService } from "services";
import { KOMI_CURRENCY, KOMI_USER_LOCATION } from "services/UserService";
import { defaultState, reducer } from "./agoraStore";

const StateContext: any = createContext({});
const MutationContext: any = createContext({});

export const ContainerProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const dispatchRedux = useDispatch();
  const router = useRouter();

  const user = useTypedSelector(selectUserData);

  const [toasts, updateToasts] = useState<any>([]);
  const [country, setCountry] = useState<string | null>(null);
  const [visibleCookies, setVisibleCookies] = useState<boolean>(true);
  const [visibleMobileSuggestion, setVisibleMobileSuggestion] = useState<boolean>(true);

  useEffect(() => {
    if (
      user &&
      router?.pathname !== "/logout" &&
      router?.pathname !== "/invitation" &&
      router?.pathname !== "/" &&
      router?.pathname !== "/talent-profile-preview" &&
      router?.pathname !== "/talent-register" &&
      router?.pathname !== "/finish-setup"
    ) {
      (window as any)?.analytics?.reset();
      (window as any)?.analytics?.identify(user.id, {
        Name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        Age: moment().diff(user.dateOfBirth, "years"),
        Gender: user?.gender?.toUpperCase(),
        Platform: isMobile ? "Responsive" : "Web",
      });

      dispatchRedux(
        updateUserTimezoneActions.REQUEST({
          timezone: moment.tz.guess(),
        }),
      );
    }
  }, [user, dispatchRedux, router?.pathname]);

  useEffect(() => {
    if (
      user &&
      country &&
      router?.pathname !== "/logout" &&
      router?.pathname !== "/invitation" &&
      router?.pathname !== "/" &&
      router?.pathname !== "/talent-profile-preview" &&
      router?.pathname !== "/talent-register" &&
      router?.pathname !== "/finish-setup"
    ) {
      dispatchRedux(
        updateUserTimezoneActions.REQUEST({
          country,
        }),
      );
    }
  }, [country, dispatchRedux, router?.pathname, user]);

  useEffect(() => {
    (async () => {
      if (user && !country) {
        const country = await userService.getCountryFromTimezone();
        //if (!result || !result.ok) return;

        setCountry(country);
        const countryName = CountriesCurrency[country];
        Cookies.set(KOMI_USER_LOCATION, String(countryName), { expires: 999999 });
        if (!countryName) return;

        const currencies = CurrencyCodes.country(countryName);
        if (currencies.length === 0) return;
        const currency = currencies[0]?.code || "GBP";
        Cookies.set(KOMI_CURRENCY, currency, { expires: 999999 });
        dispatchRedux(
          setUserValueAction({
            currency: currency,
          }),
        );
      }
    })();
  }, [country, dispatchRedux, user]);

  const methods = {
    startLoading() {
      dispatch({ type: "loading", payload: true });
    },
    stopLoading() {
      dispatch({ type: "loading", payload: false });
    },
    updateConfig(params: any) {
      dispatch({ type: "config", payload: { ...state.config, ...params } });
    },
    setClient(clientInstance: any) {
      dispatch({ type: "client", payload: clientInstance });
    },
    setCodec(param: any) {
      dispatch({ type: "codec", payload: param });
    },
    setVideo(param: any) {
      dispatch({ type: "video", payload: param });
    },
    setAudio(param: any) {
      dispatch({ type: "audio", payload: param });
    },
    setScreen(param: any) {
      dispatch({ type: "screen", payload: param });
    },
    setProfile(param: any) {
      dispatch({ type: "profile", payload: param });
    },
    toastSuccess(message: any) {
      updateToasts([
        ...toasts,
        {
          variant: "success",
          message,
        },
      ]);
    },
    toastInfo(message: any) {
      updateToasts([
        ...toasts,
        {
          variant: "info",
          message,
        },
      ]);
    },
    toastError(message: any) {
      updateToasts([
        ...toasts,
        {
          variant: "error",
          message,
        },
      ]);
    },
    removeTop() {
      const items = toasts.filter((e: any, idx: any) => idx > 0);
      updateToasts([...items]);
    },
    setLocalStream(param: any) {
      dispatch({ type: "localStream", payload: param });
    },
    setCurrentStream(param: any) {
      dispatch({ type: "currentStream", payload: param });
    },
    setDevicesList(param: any) {
      dispatch({ type: "devicesList", payload: param });
    },
    clearAllStream() {
      dispatch({ type: "clearAllStream" });
    },
    addLocal(evt: any) {
      const { stream } = evt;
      methods.setLocalStream(stream);
      methods.setCurrentStream(stream);
    },
    addStream(evt: any) {
      const { stream } = evt;
      dispatch({ type: "addStream", payload: stream });
    },
    removeStream(evt: any) {
      const { stream } = evt;
      dispatch({ type: "removeStream", stream: stream });
    },
    removeStreamById(evt: any) {
      const { uid } = evt;
      dispatch({ type: "removeStream", uid: uid });
    },
    connectionStateChanged(evt: any) {
      methods.toastInfo(`${evt.curState}`);
    },
    // enableBeauty(enable) {
    //   dispatch({type: 'enableBeauty', enable});
    // }
  };

  useEffect(() => {
    Cookies.set(
      "custom_storage",
      JSON.stringify({
        uid: state.config.uid,
        host: state.config.host,
        channelName: state.config.channelName,
        token: state.config.token,
        resolution: state.config.resolution,
      }),
    );
  }, [state]);

  return (
    <StateContext.Provider value={{ ...state, visibleCookies, visibleMobileSuggestion }}>
      <MutationContext.Provider
        value={{ ...methods, setVisibleCookies, setVisibleMobileSuggestion }}
      >
        {children}
      </MutationContext.Provider>
    </StateContext.Provider>
  );
};

export function useGlobalState() {
  return useContext(StateContext);
}

export function useGlobalMutation() {
  return useContext(MutationContext);
}
