import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getAllCategoriesTypes = createGenericTypes("GET_ALL_CATEGORIES");
export const getAllCategoriesActions = createGenericActions(getAllCategoriesTypes);

export const getTrendingCategoriesTypes = createGenericTypes("GET_TRENDING_CATEGORIES");
export const getTrendingCategoriesActions = createGenericActions(getTrendingCategoriesTypes);

export const getCategoryByIdTypes = createGenericTypes("GET_CATEGORY_BY_ID");
export const getCategoryByIdActions = createGenericActions(getCategoryByIdTypes);

export const getCategoryLiveClassExperiencesTypes = createGenericTypes("GET_CATEGORY_EXPERIENCES_LIVE_CLASS");
export const getCategoryLiveClassExperiencesActions = createGenericActions(getCategoryLiveClassExperiencesTypes);

export const getCategoryOneToOneExperiencesTypes = createGenericTypes("GET_CATEGORY_EXPERIENCES_ONE_TO_ONE");
export const getCategoryOneToOneExperiencesActions = createGenericActions(getCategoryOneToOneExperiencesTypes);

export const getCategoryTrendingExperiencesTypes = createGenericTypes("GET_CATEGORY_TRENDING_EXPERIENCES");
export const getCategoryTrendingExperiencesActions = createGenericActions(getCategoryTrendingExperiencesTypes);

export const getCategoryOnDemandExperiencesTypes = createGenericTypes("GET_CATEGORY_EXPERIENCES_ON_DEMAND");
export const getCategoryOnDemandExperiencesActions = createGenericActions(getCategoryOnDemandExperiencesTypes);

export const getCategoryCourseExperiencesTypes = createGenericTypes("GET_CATEGORY_EXPERIENCES_COURSE");
export const getCategoryCourseExperiencesActions = createGenericActions(getCategoryCourseExperiencesTypes);

export const getCategoryAllExperiencesTypes = createGenericTypes("GET_CATEGORY_EXPERIENCES_ALL");
export const getCategoryAllExperiencesActions = createGenericActions(getCategoryAllExperiencesTypes);
