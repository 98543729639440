import { komiConsumerUrl } from "services/DomainService";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  MODAL_CREATE_ACCOUNT,
  MODAL_SIGNIN,
  MODAL_SIGNUP_FORM,
  MODAL_WAITLIST,
  toggleModalActions,
} from "redux/Modal/actions";
import { selectModalStatus } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { getUserByIdActions, setUserValueAction } from "redux/User/actions";
import { selectUserSocial, selectOAuth } from "redux/User/selector";
import { isLoggedIn } from "utils/auth";
import { ROLES } from "constants/auth";

interface IProps {
  children: any;
}

const AuthProvider = ({ children }: IProps) => {
  const router = useRouter();

  const dispatch = useDispatch();
  const userSocial = useTypedSelector(selectUserSocial);
  const createAccountModalStatus = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_CREATE_ACCOUNT),
  );
  const waitlistModalStatus = useTypedSelector((state) => selectModalStatus(state, MODAL_WAITLIST));
  const oAuthStatus = useTypedSelector(selectOAuth);

  useEffect(() => {
    if (isLoggedIn() && router.pathname !== '/logout') {
      Cookies.remove("KOMI_SIGNUP");
      dispatch(getUserByIdActions.REQUEST());
    } else {
      dispatch(
        setUserValueAction({
          user: null,
        }),
      );
    }
  }, [dispatch, router?.pathname]);

  /* Use Effect to handle SSX */
  useEffect(() => {
    //if ssx google auth login was used, and the callback has ended
    if (userSocial && oAuthStatus === ROLES.SELFSIGN) {

      //if new user was returned, launch signup form
      if (userSocial?.authProviderId) {
        dispatch(
          toggleModalActions({
            modal: MODAL_SIGNUP_FORM,
            status: true,
          }),
        );

      } else { //else login directly to SSX account

        /* TODO: Placeholder to login directly with SSX once integrated, for now redirects to  home page */
        dispatch(
          setUserValueAction({
            userSocial: null,
            oAuth: null
          }),
        );
        router.push(`/`);
      }
    }
  }, [oAuthStatus]);


  useEffect(() => {
    //check if google login was used and create account modal is closed, SSX signup does not use this flow
    if (userSocial && !createAccountModalStatus && !waitlistModalStatus && oAuthStatus === ROLES.TALENT) {
      dispatch(
        toggleModalActions({
          modal: MODAL_SIGNIN,
          status: true,
        }),
      );
    }

    if (waitlistModalStatus) {
      dispatch(
        setUserValueAction({
          userSocial: null,
        }),
      );
    }
  }, [createAccountModalStatus, dispatch, userSocial, waitlistModalStatus, oAuthStatus]);

  return <>{children}</>;
};

export default AuthProvider;
