import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getWishlistTypes = createGenericTypes("GET_WISHLIST");
export const getWishlistActions = createGenericActions(getWishlistTypes);

export const addToWishlistTypes = createGenericTypes("ADD_TO_WISHLIST");
export const addToWishlistActions = createGenericActions(addToWishlistTypes);

export const removeFromWishlistTypes = createGenericTypes("REMOVE_FROM_WISHLIST");
export const removeFromWishlistActions = createGenericActions(removeFromWishlistTypes);
