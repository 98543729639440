/* eslint-disable react-hooks/exhaustive-deps */
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import { Button, Checkbox, Col, Divider, Form, Input, Row, Spin } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import notification from "antd/lib/notification";
import classNames from "classnames";
import Modal from "components/Modal";
import { Paragraph, Text } from "components/Typography";
import { repeat } from "lodash";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { MODAL_TWO_FACTOR, SELECT_METHOD, toggleModalActions } from "redux/Modal/actions";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectEmailOtpLoading,
  selectOtpId,
  selectPhoneOTP,
  selectPhoneOtpLoading,
  selectTwoFactorInfo,
  selectUserData,
  selectUserLoading,
} from "redux/User/selector";
import {
  acceptCollaboratorInviteActions,
  requestOtpActions,
  talentLoginActions,
} from "redux/User/actions";
import { RequestOtpRequest } from "redux/User/types";
import { AnalyticServices } from "utils/analytics";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { KOMI_USER_LOCATION } from "services/UserService";
import { useRouter } from "next/router";
import { useForm } from "antd/lib/form/Form";
import { KomiLogo } from "@komi-app/components";

// TODO: deprecate this in favour of `OtpType`
export const EMAIL = "email";
export const PHONE = "phone";

export function otpRequest(
  method: string,
  twoFactorInfo: Pick<any, "userId" | "email" | "phoneOtp">,
  callback?: any,
): RequestOtpRequest {
  let payload: RequestOtpRequest = {
    method,
    userId: twoFactorInfo.userId,
    onError: (error: string) => {
      notification.error({
        message: error,
      });
    },
    onSuccess: () => {
      if (callback && callback instanceof Function) {
        callback();
      }
    },
  };
  if (method === "email") {
    payload = {
      ...payload,
      email: twoFactorInfo.email,
    };
  } else if (method === "phone") {
    payload = {
      ...payload,
      phone: twoFactorInfo.phoneOtp,
    };
  }
  return payload;
}
interface IProps {
  closable?: boolean;
  resetPassword?: boolean;
  show: boolean;
  data: any;
  onOk?(): void;
  onCancel(): void;
}
const TwoFactorModal: React.FC<IProps> = ({ show, data, resetPassword, onCancel }) => {
  const { method, email, password } = data;
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const router = useRouter();
  const twoFactorInfo = useTypedSelector(selectTwoFactorInfo);
  const otpId = useTypedSelector(selectOtpId);
  const user = useTypedSelector(selectUserData);
  const loading = useTypedSelector(selectUserLoading);
  const phoneOtpLoading = useTypedSelector(selectPhoneOtpLoading);
  const emailOtpLoading = useTypedSelector(selectEmailOtpLoading);
  const [resendIn, setResendIn] = useState<number | null>(null);
  const isMobile = screens["xs"];
  const DEFAULT_RESEND_IN = 60;
  const emailEnabled = twoFactorInfo?.email2faEnabled;
  const phoneEnabled = twoFactorInfo?.phone2faEnabled;
  const phoneOnly = phoneEnabled && !emailEnabled;
  const [form] = useForm();

  useEffect(() => {
    if (resendIn === 0) {
      setResendIn(null);
    }
    if (!resendIn) {
      return;
    }
    const interval = setInterval(() => {
      setResendIn(resendIn - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [resendIn]);

  useEffect(() => {
    setResendIn(DEFAULT_RESEND_IN);
    form.resetFields();
  }, [method]);

  const errorMessage = useCallback(
    (message: string) => {
      return (
        <Row align="top">
          <Text
            preset={"regular12"}
            className={classNames("d--block text--red flex--1 leading-16", isMobile && "pt-3")}
          >
            {message}
          </Text>
        </Row>
      );
    },
    [isMobile],
  );

  const emailOtp = useMemo(() => {
    return twoFactorInfo?.email.replace(
      /(.)(.+)(.)@(.+)/,
      (_: any, p1: string, p2: string, p3: string, p4: string) => {
        return `${p1}${repeat("•", p2.length)}${p3}@${p4}`;
      },
    );
  }, [twoFactorInfo]);

  const phoneOtp = useMemo(() => {
    return twoFactorInfo?.phoneOtp?.replace(/(.+)(\d\d)/, (_: any, p1: string, p2: string) => {
      return `${repeat("•", p1.length - 4)} ${repeat("•", 4)}${p2}`;
    });
  }, [twoFactorInfo]);

  function handleClickMethod(method: string): void {
    if (resendIn !== null) {
      setResendIn(null);
    }
    const payload: RequestOtpRequest = otpRequest(method, twoFactorInfo, () => {
      dispatch(
        toggleModalActions({
          modal: MODAL_TWO_FACTOR,
          status: true,
          data: {
            method,
            email,
            password,
          },
        }),
      );
    });
    dispatch(requestOtpActions.REQUEST(payload));
  }

  function handleClickResend(method: string): void {
    if (resendIn === null) {
      setResendIn(DEFAULT_RESEND_IN);
    }
    const payload: RequestOtpRequest = otpRequest(method, twoFactorInfo);
    dispatch(requestOtpActions.REQUEST(payload));
  }

  function handleClickBack(): void {
    dispatch(
      toggleModalActions({
        modal: MODAL_TWO_FACTOR,
        status: true,
        data: {
          method: SELECT_METHOD,
          email,
          password,
        },
      }),
    );
  }

  const sendSegmentEvent = useCallback(
    (data: any) => {
      AnalyticServices.track(SEGMENT_EVENT.LOGIN, {
        ...data,
        "Login method": "E-mail",
        Location: Cookies.get(KOMI_USER_LOCATION),
        Platform: isMobile ? "Responsive" : "Web",
        "Talent User ID": user?.id,
      });
    },
    [isMobile, user],
  );

  const isCollaboratorInvitation = useMemo(
    () => router?.asPath?.startsWith("/invitation"),
    [router],
  );

  const acceptInvitation = useCallback(() => {
    if (isCollaboratorInvitation) {
      dispatch(
        acceptCollaboratorInviteActions.REQUEST({
          form: {
            email: router?.query?.email,
            token: router?.query?.token,
          },
          refreshUser: true,
          onSuccess: () => {
            form.resetFields();
            router.push("/");
          },
          onAlreadyJoined: () => router.push("/"),
        }),
      );
    } else {
      form.resetFields();
      router.push("/");
    }
  }, [dispatch, isCollaboratorInvitation, router]);

  function handleSubmit(values: any): void {
    const { otp, rememberDevice } = values;
    dispatch(
      talentLoginActions.REQUEST({
        form: { email, password, rememberDevice, id: otpId, otp },
        sendSegmentEvent: sendSegmentEvent,
        onSuccess: () => acceptInvitation(),
        onError: (message: string) => {
          form.setFields([
            {
              name: "otp",
              value: "",
              errors: [message || "Wrong code. Please try again"],
            },
          ]);
        },
      }),
    );
  }

  const title = useCallback(() => {
    let description;
    switch (method) {
      case SELECT_METHOD:
        description =
          "To keep your Komi account safe, confirm that it’s really you trying to log in. We will send a code to your phone or email address.";
        break;
      case PHONE:
        if (phoneOnly) {
          description = (
            <>
              To keep your Komi account safe, confirm that it’s really you trying to log in.
              <br />
              <br />
              We’ve just sent a text message with a verification code to <br />
              <span className="font__weight--semi-bold">{phoneOtp}</span>. Once you receive the
              code, enter it below.
            </>
          );
        } else {
          description = (
            <>
              We’ve just sent a text message with a verification code to <br />
              <span className="font__weight--semi-bold">{phoneOtp}</span>. Once you receive the
              code, enter it below.
            </>
          );
        }
        break;
      case EMAIL:
        description = `We’ve just sent an email with a verification code to ${emailOtp}. Once you receive the code, enter it below.`;
        break;
      default:
        break;
    }
    return (
      <div style={{ maxWidth: "432px" }}>
        <Paragraph preset="semibold20">Two-Factor Authentication</Paragraph>
        <Paragraph className="opacity--08 m__t--4" preset="regular16">
          {description}
        </Paragraph>
      </div>
    );
  }, [method]);

  function footer() {
    return (
      <Row align="middle" justify="center">
        <KomiLogo height={isMobile ? 20 : 24} />
      </Row>
    );
  }

  function closeIcon() {
    return <CloseIcon width={isMobile ? 24 : 28} height={isMobile ? 24 : 28} />;
  }

  return (
    <Modal
      maskStyle={{ background: `rgb(18, 18, 18)` }}
      maskClosable={false}
      closable={!resetPassword}
      className={classNames("two-factor-modal", { "img-top": resetPassword })}
      visible={show}
      onCancel={onCancel}
      title={title()}
      footer={resetPassword ? null : footer()}
      closeIcon={closeIcon()}
      centered
      width={540}
    >
      <>
        {method === SELECT_METHOD && (
          <>
            <Paragraph preset="semibold18">Select Which Method You Want to Use</Paragraph>
            <Button
              block
              className="m__t--16"
              onClick={() => handleClickMethod(PHONE)}
              loading={loading}
            >
              <Row justify="center" align="middle" gutter={12}>
                <Col>
                  <img alt="phone" src="/static/assets/icons/phone.svg" />
                </Col>
                <Col>
                  <Paragraph preset="semibold16">
                    {!isMobile ? `Send a Text to ${phoneOtp}` : "Send a Text"}
                  </Paragraph>
                </Col>
                <Col>
                  <Spin
                    spinning={phoneOtpLoading}
                    indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                  />
                </Col>
              </Row>
            </Button>
            {emailEnabled && (
              <Button
                block
                className="m__t--16"
                onClick={() => handleClickMethod(EMAIL)}
                loading={loading}
              >
                <Row justify="center" align="middle" gutter={12}>
                  <Col>
                    <img alt="email" src="/static/assets/icons/email-green.svg" />
                  </Col>
                  <Col>
                    <Paragraph preset="semibold16">
                      {!isMobile ? `Send an Email to ${emailOtp}` : "Send a Email"}
                    </Paragraph>
                  </Col>
                  <Col>
                    <Spin
                      spinning={emailOtpLoading}
                      indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                    />
                  </Col>
                </Row>
              </Button>
            )}
            <Divider />
          </>
        )}
        {(method === EMAIL || method === PHONE) && (
          <Form layout="vertical" requiredMark={false} onFinish={handleSubmit} form={form}>
            <Form.Item
              className="m__b--12"
              name="otp"
              label="Enter confirmation code"
              rules={[
                {
                  required: true,
                  message: errorMessage("Please enter your code"),
                },
              ]}
            >
              <Input maxLength={6} placeholder="Enter the code" />
            </Form.Item>
            <Form.Item name="rememberDevice" valuePropName="checked">
              <Checkbox className="text align__items--center" value={true}>
                Remember this device
              </Checkbox>
            </Form.Item>
            <Paragraph preset={emailEnabled ? "regular14" : "regular16"}>
              Didn’t get the code?{" "}
              <Button
                className="text--blue p--0"
                disabled={resendIn !== null}
                type="text"
                onClick={() => handleClickResend(method)}
              >
                <span
                  className={classNames({
                    "font__size--medium1": phoneOnly,
                    "line-height--22": phoneOnly,
                    "line-height--20": emailEnabled,
                  })}
                >
                  {resendIn ? `Resend in ${resendIn}s` : "Resend"}
                </span>
              </Button>{" "}
              {emailEnabled && (
                <>
                  or{" "}
                  <Button className="text--blue p--0" type="text" onClick={handleClickBack}>
                    <span className="line-height--20">Try Another Way</span>
                  </Button>
                </>
              )}
            </Paragraph>
            <Button
              className="m__y--24"
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              block
            >
              Done
            </Button>
          </Form>
        )}
        <Paragraph className="p__x--16 p__y--8 bg--gray7 border-radius-8">
          If you no longer has access to your phone and email,{" "}
          <a target="_blank" href="mailto:support@komi.io" rel="noopener noreferrer">
            <Text className="text--blue">contact support</Text>
          </a>{" "}
          to recover your account.
        </Paragraph>
      </>
    </Modal>
  );
};

export default TwoFactorModal;
