import { Response } from "../redux/Common/types";
import { Api } from "./api";

interface IPhotoService {
  getPresignedPhotoUrl(fileExtension: string, contentType: string): Promise<Response<any>>;
  getPresignedVideoUrl(fileExtension: string): Promise<Response<any>>;
}

export default class PhotoService implements IPhotoService {
  constructor(private readonly api: Api) {
    this.api = api;
  }
  getPresignedPhotoUrl = (fileExtension: string, contentType: string): Promise<Response<any>> => {
    return this.api.createPresignedPhotoUrl(fileExtension, contentType);
  };

  getPresignedVideoUrl = (fileExtension: string): Promise<Response<any>> => {
    return this.api.createPresignedVideoUrl(fileExtension);
  };
}
