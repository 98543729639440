import { CategoryState } from "./types";
import { Action, handleActions } from "redux-actions";
import {
  getAllCategoriesActions,
  getCategoryByIdActions,
  getCategoryLiveClassExperiencesActions,
  getCategoryOneToOneExperiencesActions,
  getCategoryOnDemandExperiencesActions,
  getCategoryCourseExperiencesActions,
  getCategoryAllExperiencesActions,
  getTrendingCategoriesActions,
  getCategoryTrendingExperiencesActions,
} from "./actions";
import uniqBy from "lodash/unionBy";

const handlers = {
  // get all category
  [getAllCategoriesActions.REQUEST]: (state: CategoryState): CategoryState => {
    return {
      ...state,
      error: null,
      isLoading: true,
    };
  },
  [getAllCategoriesActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      categories: payload,
    };
  },
  [getAllCategoriesActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get trending categories
  [getTrendingCategoriesActions.REQUEST]: (state: CategoryState): CategoryState => {
    return {
      ...state,
      error: null,
      isLoading: true,
    };
  },
  [getTrendingCategoriesActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      trendingCategories: payload,
    };
  },
  [getTrendingCategoriesActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },

  // get category by id
  [getCategoryByIdActions.REQUEST]: (state: CategoryState): CategoryState => {
    return {
      ...state,
      error: null,
      isLoading: true,
    };
  },
  [getCategoryByIdActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => {
    return {
      ...state,
      category: payload,
      isLoading: false,
    };
  },
  [getCategoryByIdActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get experience Broadcast of category
  [getCategoryLiveClassExperiencesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [getCategoryLiveClassExperiencesActions.FAILURE]: (
    state: CategoryState,
    { payload }: Action<any>,
  ): CategoryState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [getCategoryLiveClassExperiencesActions.SUCCESS]: (
    state: CategoryState,
    { payload }: Action<any>,
  ): CategoryState => ({
    ...state,
    isLoading: false,
    liveClassLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.liveClassLatestPage,
    liveClassExperiences:
      payload.meta.currentPage === 1
        ? [...payload.items]
        : uniqBy([...state.liveClassExperiences, ...payload.items], "id"),
  }),
  // get experience one to one of category
  [getCategoryOneToOneExperiencesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [getCategoryOneToOneExperiencesActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [getCategoryOneToOneExperiencesActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    isLoading: false,
    oneToOneLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.oneToOneLatestPage,
    oneToOneExperiences:
      payload.meta.currentPage === 1
        ? [...payload.items]
        : uniqBy([...state.oneToOneExperiences, ...payload.items], "id"),
  }),

  // get trending experiences  of category
  [getCategoryTrendingExperiencesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: null,
    isLoadingTrending: true,
  }),
  [getCategoryTrendingExperiencesActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    error: payload,
    isLoadingTrending: false,
  }),
  [getCategoryTrendingExperiencesActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    isLoadingTrending: false,
    trendingLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.trendingLatestPage,
    trendingExperiences:
      payload.meta.currentPage === 1
        ? [...payload.items]
        : uniqBy([...state.trendingExperiences, ...payload.items], "id"),
  }),
  // get experience on demand of category
  [getCategoryOnDemandExperiencesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [getCategoryOnDemandExperiencesActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    isLoading: false,
    onDemandLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.onDemandLatestPage,
    onDemandExperiences:
      payload.meta.currentPage === 1
        ? [...payload.items]
        : uniqBy([...state.onDemandExperiences, ...payload.items], "id"),
  }),
  [getCategoryOnDemandExperiencesActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  // get experience course of category
  [getCategoryCourseExperiencesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [getCategoryCourseExperiencesActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    isLoading: false,
    courseLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.courseLatestPage,
    courseExperiences:
      payload.meta.currentPage === 1
        ? [...payload.items]
        : uniqBy([...state.courseExperiences, ...payload.items], "id"),
  }),
  [getCategoryCourseExperiencesActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  // get all experiences of category
  [getCategoryAllExperiencesActions.REQUEST]: (state: CategoryState): CategoryState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [getCategoryAllExperiencesActions.SUCCESS]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    isLoading: false,
    allLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.allLatestPage,
    allExperiences:
      payload.meta.currentPage === 1 ? [...payload.items] : uniqBy([...state.allExperiences, ...payload.items], "id"),
  }),
  [getCategoryAllExperiencesActions.FAILURE]: (state: CategoryState, { payload }: Action<any>): CategoryState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
};

const defaultState: CategoryState = {
  isLoading: false,
  isLoadingTrending: false,
  categories: null,
  trendingCategories: null,
  category: null,
  liveClassExperiences: [],
  liveClassLatestPage: 1,
  oneToOneExperiences: [],
  trendingExperiences: [],
  oneToOneLatestPage: 1,
  trendingLatestPage: 1,
  onDemandExperiences: [],
  onDemandLatestPage: 1,
  courseExperiences: [],
  courseLatestPage: 1,
  allExperiences: [],
  allLatestPage: 1,
};

export const categoryReducer = handleActions<CategoryState>(handlers, defaultState);
