import { applyMiddleware, createStore, StoreEnhancer, Middleware, compose } from "redux";

import createSagaMiddleware from "redux-saga";
import { WithSagaTaskStore } from "./Common/types";
import rootReducer from "./rootReducer";
import rootSaga from "./sagas";
import { createWrapper } from "next-redux-wrapper";
import CustomMiddleware from "./customMiddlewares";

// custom middlewares
const middlewareDev = [CustomMiddleware.SuccessRequestHandling, CustomMiddleware.ErrorRequestHandling];
const isWebDev = process.env.NODE_ENV !== "production" && process.browser;

const composeEnhancers = (isWebDev && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => applyMiddleware(...middleware, ...middlewareDev);

const makeConfiguredStore = (reducer: any) => {
  const sagaMiddleware = createSagaMiddleware();
  const composed = composeEnhancers(bindMiddleware([sagaMiddleware]));

  const store: WithSagaTaskStore = createStore(reducer, composed);

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export let store: WithSagaTaskStore;
export const makeStore = () => {
  const isServer = typeof window === "undefined";

  if (isServer) {
    store = makeConfiguredStore(rootReducer);
    return store;
  }

  // we need it only on client side
  const { persistStore } = require("redux-persist");

  store = makeConfiguredStore(rootReducer);

  (store as any).__persistor = persistStore(store);

  return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
