import { NextPageContext } from "next";
import { Store } from "redux";
import { Task } from "redux-saga";
import { GeneralApiProblem } from "./../../services/api/apiProblem";
import { User } from "redux/User/types";
import { Experience } from "redux/Experience/types";

export const PERSIST_KEY = "nextjs";

export interface IToken {
  exp: number;
  iat: number;
  userId: string;
  roles: string[];
}

export type CreateShortLinkRequest = {
  dynamicLinkInfo: {
    domainUriPrefix: string;
    link: string;
    androidInfo: {
      androidPackageName: string;
    };
    iosInfo: {
      iosBundleId: string;
      iosAppStoreId: string;
    };
    navigationInfo: {
      enableForcedRedirect: boolean;
    };
    socialMetaTagInfo: {
      socialTitle: string;
      socialDescription: string;
      socialImageLink: string;
    };
  };
};

export type ShortLink = {
  previewLink: string;
  shortLink: string;
};

export type AnchorItem = {
  name: string;
  url: string;
  key: string;
  disabled?: boolean;
};

export type ListQueryParams = {
  page: number;
  limit: number;
  sort?: string;
  filter?: string;
  trending?: boolean;
};

export type Pagination<T> = {
  items: T;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
};

export type Media = {
  src: string;
  type: string;
};

export type APIResponse<T> = {
  ok: boolean;
  response: T;
};

export type Response<T> = GeneralApiProblem | APIResponse<T> | null;

export enum StatusType {
  ALL = "all",
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  UNCOMPLETED_PROFILE = "uncompleted_profile",
  ACTIVATE = "activate",
  DEACTIVATE = "deactivate",
}

export interface WithSagaTaskStore extends Store {
  sagaTask?: Task;
}

export interface WithReduxNextPageContext extends NextPageContext {
  store: WithSagaTaskStore; // Added with react-redux Provider in _app.tsx
}

export type Callback = (...args: any[]) => void;

export type ErrorCallback = (error: any) => void;

export type MediaUpload = {
  id?: number;
  fileName?: string;
  loading?: boolean;
  url?: string | ArrayBuffer;
  poster?: string;
};

export type TIME_ZONE = {
  tz: string;
  tx: string;
  sectionId?: number;
};

export type SearchResult = {
  experts: Pagination<User[]>;
  experiences: Pagination<Experience[]>;
};

export enum MessageType {
  Text = "Text",
  Tip = "Tip",
}

export type CommonState = {
  isLoading: boolean;
  error?: string | null;
  result?: SearchResult | null;
};
