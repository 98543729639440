import { Api } from "../api";
import { EXPERIENCE_TYPE } from "redux/Experience/types";
import Experience1To1Service from "./Experience1To1Service";
import ExperienceLiveClassService from "./ExperienceLiveClassService";
import ExperienceService from "./ExperienceService";
import ExclusiveContentService from "./ExclusiveContentService";
import CourseService from "./CourseService";
import ExperienceInteractiveLiveClassService from "./ExperienceInteractiveLiveClassService";

export default class ExperienceFactoryService {
  static createService(api: Api, type: EXPERIENCE_TYPE): ExperienceService {
    switch (type) {
      case EXPERIENCE_TYPE.ONE_TO_ONE:
        return new Experience1To1Service(api);
      case EXPERIENCE_TYPE.LIVE_CLASS:
        return new ExperienceLiveClassService(api);
      case EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS:
        return new ExperienceInteractiveLiveClassService(api);
      case EXPERIENCE_TYPE.ON_DEMAND_CONTENT:
        return new ExclusiveContentService(api);
      case EXPERIENCE_TYPE.COURSE:
        return new CourseService(api);
      default:
        return new Experience1To1Service(api);
    }
  }
}
