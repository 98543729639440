import Cookies from "js-cookie";
import router from "next/router";
import { Response } from "redux/Common/types";
import { SpotifyTrack, SpotifyUser } from "redux/User/types";
import { userService } from "services";
import { setExpirableCookie } from "utils/cookie";
import { toQuery } from "utils/url";
import { Api } from "./api/api";

export const KOMI_SPOTIFY_ACCESS_TOKEN = "KOMI_SPOTIFY_ACCESS_TOKEN";
export const KOMI_SPOTIFY_REFRESH_TOKEN = "KOMI_SPOTIFY_REFRESH_TOKEN";

export default class SpotifyService {
  private __jwt = "";

  constructor(private readonly api: Api) {
    this.api = api;
  }

  set jwt(value: string) {
    this.__jwt = value;
    try {
      Cookies.set(KOMI_SPOTIFY_ACCESS_TOKEN, value);
    } catch (error) {
      console.log(error);
    }
  }

  get jwt(): string {
    return this.__jwt?.length ? this.__jwt : Cookies.get(KOMI_SPOTIFY_ACCESS_TOKEN) || "";
  }

  logout = (): void => {
    this.__jwt = "";
  };

  signin = (payload: SpotifyUser): void => {
    const { expires_in, access_token, refresh_token = "" } = payload;
    const expirySeconds = Number(expires_in);

    setExpirableCookie(KOMI_SPOTIFY_ACCESS_TOKEN, access_token, expirySeconds);
    setExpirableCookie(KOMI_SPOTIFY_REFRESH_TOKEN, refresh_token, 180 * 24 * 60 * 60); // 6 months
  };

  getSpotifyById = (type: string, id: string): Promise<Response<SpotifyTrack>> => {
    return this.api.getSpotifyById(type, id);
  };
}

/**
 * Authorize the Spotify using the OAuth flow
 */
export const handleSpotifySignIn = async (): Promise<void> => {
  // Authorize Spotify user
  const authResponse = await userService.spotifyConnect({
    callbackUrl: window.location.href,
  });

  const isValidResponse = authResponse && "ok" in authResponse;
  if (!isValidResponse) {
    // TODO: Probably want to throw or at least handle cases where this isn't true
    return;
  }

  /* As the popup only runs in one instance being a minimized window,
   and instagram/mobile/fullscreen browsers don't support popups and are instead copying a redirect flow with a new tab, 
   use a redirect for the autflow instead */
  router.push(authResponse.response.url);
  //const popup = window.open(authResponse.response.url, "spotify-authorization", toQuery(options, ","));
};
