import { CountriesCurrency } from "constants/countries-currency";
import Cookies from "js-cookie";
import moment from "moment";
import ct from 'countries-and-timezones';
import { Response, SearchResult } from "./../redux/Common/types";
import {
  User,
  UpdatePasswordRequest,
  PhoneOTP,
  SendPhoneOTPRequest,
  CheckUserExistedResult,
  CreatePasswordRequest,
  AcceptCollaboratorInvitationRequest,
  SendEmailOTPRequest,
  VerifyEmailOTPRequest,
  SpotifyConnectRequest,
  SpotifyConnectResponse,
  SpotifyUser,
  GetListUserPreSaveRequest,
  GetListUserPreSaveResult,
  JoinFanClubRequest,
  WaitlistData,
  CheckTalentExistedRequest,
  CheckUserExistedRequest,
  SubmitDataCaptureFormRequest,
  SelfSignUpRequest,
  ResendEmailConfirmationRequest,
  ResendEmailConfirmationResponse,
  EmailVerificationResponse,
} from "./../redux/User/types";
import { Api } from "./api";

export const KOMI_CURRENCY = "KOMI_CURRENCY";
export const KOMI_USER_LOCATION = "KOMI_USER_LOCATION";

interface IUserService {
  getUserById(userId: string): Promise<Response<User>>;
  updateUserProfile(payload: User): Promise<Response<User>>;
  createPassword(payload: CreatePasswordRequest): Promise<Response<User>>;
  updatePassword(payload: UpdatePasswordRequest): Promise<Response<User>>;
  sendPhoneOTP(payload: SendPhoneOTPRequest): Promise<Response<PhoneOTP>>;
  sendEmailOTP(payload: SendEmailOTPRequest): Promise<Response<any>>;
  verifyEmailOTP(payload: VerifyEmailOTPRequest): Promise<Response<any>>;
  checkUserExisted(payload: CheckUserExistedRequest): Promise<Response<CheckUserExistedResult>>;
  getLocation(): Promise<Response<any>>;
  handleSearch(payload: string): Promise<Response<SearchResult>>;
  acceptCollaboratorInvitation(
    payload: AcceptCollaboratorInvitationRequest,
  ): Promise<Response<any>>;
  joinWaitlist(payload: WaitlistData): Promise<Response<any>>;
}

export default class UserService implements IUserService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getUserById = (): Promise<Response<User>> => {
    return this.api.getUserById();
  };

  updateUserProfile = (payload: User): Promise<Response<User>> => {
    return this.api.updateUserProfile(payload);
  };

  updatePassword = (payload: UpdatePasswordRequest): Promise<Response<User>> => {
    return this.api.updatePassword(payload);
  };

  createPassword = (payload: CreatePasswordRequest): Promise<Response<User>> => {
    return this.api.createPassword(payload);
  };

  sendEmailOTP = (payload: SendEmailOTPRequest): Promise<Response<PhoneOTP>> => {
    return this.api.sendEmailOTP(payload);
  };

  verifyEmailOTP = (payload: VerifyEmailOTPRequest): Promise<Response<PhoneOTP>> => {
    return this.api.verifyEmailOTP(payload);
  };

  sendPhoneOTP = (payload: SendPhoneOTPRequest): Promise<Response<PhoneOTP>> => {
    return this.api.sendPhoneOTP(payload);
  };

  checkUserExisted = (
    payload: CheckUserExistedRequest,
  ): Promise<Response<CheckUserExistedResult>> => {
    return this.api.checkUserExisted(payload);
  };

  checkUsernameExisted = (payload: {
    username: string;
    role: string;
  }): Promise<Response<CheckUserExistedResult>> => {
    return this.api.checkUsernameExisted(payload);
  };

  getLocation = (): Promise<any> => {
    return this.api.getLocation();
  };

  handleSearch = (payload: string): Promise<Response<SearchResult>> => {
    return this.api.handleSearch(payload);
  };

  acceptCollaboratorInvitation = (
    payload: AcceptCollaboratorInvitationRequest,
  ): Promise<Response<any>> => {
    return this.api.acceptCollaboratorInvitation(payload);
  };

  spotifyConnect = (params: SpotifyConnectRequest): Promise<Response<SpotifyConnectResponse>> => {
    return this.api.spotifyConnect(params);
  };

  spotifyRefreshToken = (): Promise<Response<SpotifyUser>> => {
    return this.api.spotifyRefreshToken();
  };

  getListUserPreSave = (
    payload: GetListUserPreSaveRequest,
  ): Promise<Response<GetListUserPreSaveResult[]>> => {
    return this.api.getListUserPreSave(payload);
  };

  joinFanClub = (payload: JoinFanClubRequest): Promise<Response<any>> => {
    return this.api.joinFanClub(payload);
  };

  joinWaitlist = (payload: WaitlistData): Promise<Response<any>> => {
    return this.api.joinWaitlist(payload);
  };

  checkExistedTalent = (payload: CheckTalentExistedRequest): Promise<Response<any>> => {
    return this.api.checkExistedTalent(payload);
  };

  submitDataCaptureForm = (payload: SubmitDataCaptureFormRequest): Promise<Response<any>> => {
    return this.api.submitDataCaptureForm(payload);
  };

  async getCountry(): Promise<string> {
    const value = Cookies.get(KOMI_USER_LOCATION);

    if (value && value !== "undefined") return value;

    const countryCode = await this.getCountryFromTimezone();

    const country = CountriesCurrency[countryCode] as string;
    Cookies.set(KOMI_USER_LOCATION, country, { expires: 999999 });

    return country;
  }

  async getCountryCode(): Promise<string> {
    const userCountryCode: any = await this.api.getUserCountryCode();

    const countryCode = userCountryCode?.response?.data?.ipcountry || "";

    if (countryCode) return countryCode;

    const location = await this.getLocation();

    return location?.response?.country;
  }

  /**
   * getCountryFromTimezone
   * Function that does not rely on third party api to get country and involves a little bit of guessing
   * used for approximations such as ssx, where the country code would then be updated by the card subscription
   * @returns 
   */
  async getCountryFromTimezone(): Promise<string> {

    var countries: ct.Country[];

    //get users timezone
    try {
      const userTimezone = moment.tz.guess();
      countries = ct.getCountriesForTimezone(userTimezone);

    } catch (e) {
      countries = ct.getCountriesForTimezone('America/Los_Angeles');
    }

    return countries[0].id;
  }

  selfSignUp = (payload: SelfSignUpRequest): Promise<Response<User>> => {
    return this.api.selfSignUp(payload);
  };

  resendEmailConfirmation = (
    payload: ResendEmailConfirmationRequest,
  ): Promise<Response<ResendEmailConfirmationResponse>> => {
    return this.api.resendEmailConfirmation(payload);
  };

  verifyEmail = async (verificationCode: string): Promise<Response<EmailVerificationResponse>> => {
    return await this.api.verifyEmail(verificationCode);
  };
}
