import { SUCCESS } from "utils/createAction";
import { Action, handleActions } from "redux-actions";
import {
  checkoutBookingActions,
  checkoutExpertSupportActions,
  checkoutSupportActions,
  getBraintreeClientTokenActions,
  getExchangeRateActions,
  getTransactionActions,
  getTransactionByIdActions,
  resetCheckoutResultActions,
  getPaymentMethodsActions,
  resetSendTipResultActions,
  getExchangeRateUSDActions,
  getExchangeRateTipActions,
} from "./actions";
import { PaymentState } from "./types";

const handlers = {
  // checkout booking
  [checkoutBookingActions.REQUEST]: (state: PaymentState): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      clientSecret: undefined,
    };
  },
  [checkoutBookingActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      clientSecret: payload,
    };
  },
  [checkoutBookingActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  [checkoutExpertSupportActions.REQUEST]: (state: PaymentState): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      clientSecret: undefined,
      sendTipResult: null,
    };
  },
  [checkoutExpertSupportActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      clientSecret: payload.clientSecret,
      sendTipResult: payload,
    };
  },
  [checkoutExpertSupportActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  [checkoutSupportActions.REQUEST]: (state: PaymentState): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      clientSecret: undefined,
      sendTipResult: null,
    };
  },
  [checkoutSupportActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      clientSecret: payload.clientSecret,
      sendTipResult: payload,
    };
  },
  [checkoutSupportActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get braintree client token
  [getBraintreeClientTokenActions.REQUEST]: (state: PaymentState): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      token: null,
    };
  },
  [getBraintreeClientTokenActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      token: payload,
    };
  },
  [getBraintreeClientTokenActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get exchange rates
  [getExchangeRateActions.REQUEST]: (state: PaymentState): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      exchangeRates: null,
    };
  },
  [getExchangeRateActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      exchangeRates: payload,
    };
  },
  [getExchangeRateActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get exchange rates usd
  [getExchangeRateUSDActions.REQUEST]: (state: PaymentState): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      exchangeRatesUSD: null,
    };
  },
  [getExchangeRateUSDActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      exchangeRatesUSD: payload,
    };
  },
  [getExchangeRateUSDActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get exchange rates for tip
  [getExchangeRateTipActions.REQUEST]: (state: PaymentState): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      exchangeRatesTip: null,
    };
  },
  [getExchangeRateTipActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      exchangeRatesTip: payload,
    };
  },
  [getExchangeRateTipActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },

  [getTransactionActions.REQUEST]: (state: PaymentState): PaymentState => ({
    ...state,
    transactions: null,
    isLoading: true,
  }),
  [getTransactionActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    transactions: payload,
    isLoading: false,
  }),
  [getTransactionActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  // get transaction by id
  [getTransactionByIdActions.REQUEST]: (state: PaymentState): PaymentState => ({
    ...state,
    transactionData: null,
    isLoading: true,
  }),
  [getTransactionByIdActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    transactionData: payload,
    isLoading: false,
  }),
  [getTransactionByIdActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [resetCheckoutResultActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    error: null,
    clientSecret: "",
    checkoutResult: null,
    isLoading: false,
  }),
  [resetSendTipResultActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    error: null,
    sendTipResult: null,
    isLoading: false,
  }),
  // get payment methods
  [getPaymentMethodsActions.REQUEST]: (state: PaymentState): PaymentState => ({
    ...state,
    paymentMethods: [],
    isLoading: true,
  }),
  [getPaymentMethodsActions.SUCCESS]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    paymentMethods: payload,
    isLoading: false,
  }),
  [getPaymentMethodsActions.FAILURE]: (
    state: PaymentState,
    { payload }: Action<any>,
  ): PaymentState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
};

const defaultState: PaymentState = {
  isLoading: false,
  error: null,
  checkoutResult: null,
  token: null,
  exchangeRates: null,
  sendTipResult: null,
  transactions: null,
  transactionData: null,
  paymentMethods: [],
};

export const paymentReducer = handleActions<PaymentState>(handlers, defaultState);
