import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { ListQueryParams } from "redux/Common/types";
import { getWishlistActions, addToWishlistActions, removeFromWishlistActions } from "./actions";
import { wishlistService } from "services";
import { Experience } from "redux/Experience/types";

function* getWishlist({ payload }: Action<ListQueryParams>) {
  const result: any = yield* call(wishlistService.getWishlist, payload);
  if (result.ok) {
    yield* put(getWishlistActions.SUCCESS(result.response));
  } else {
    yield* put(getWishlistActions.FAILURE(result?.message || null));
  }
}

function* addToWishlist({ payload }: Action<Experience["id"]>) {
  const result: any = yield* call(wishlistService.addToWishlist, payload);
  if (result.ok) {
    yield* put(addToWishlistActions.SUCCESS(result.response));
  } else {
    yield* put(addToWishlistActions.FAILURE(result?.message || null));
  }
}

function* removeFromWishlist({ payload }: Action<Experience["id"]>) {
  const result: any = yield* call(wishlistService.removeFromWishlist, payload);
  if (result.ok) {
    yield* put(removeFromWishlistActions.SUCCESS(result.response));
  } else {
    yield* put(removeFromWishlistActions.FAILURE(result?.message || null));
  }
}

export default function* wishlistSagas(): SagaIterator {
  yield* takeLatest(getWishlistActions.REQUEST, getWishlist);
  yield* takeLatest(addToWishlistActions.REQUEST, addToWishlist);
  yield* takeLatest(removeFromWishlistActions.REQUEST, removeFromWishlist);
}
