import AuthProvider from "components/AuthProvider";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import "nprogress/nprogress.css";
import React, { useEffect, useRef } from "react";
import "react-phone-input-2/lib/style.css";
import "react-app-protect/dist/index.css";
import { useStore } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { wrapper } from "redux/store";
import "styles/abstracts/antd.css";
import "styles/app.scss";
import "styles/fonts/SFProDisplay/stylesheet.css";
import "styles/fonts/ABCReproVariable/stylesheet.css";
import "components/TalentDetailProductShopify/ShopifyLoadingCard.scss";
import { ContainerProvider } from "utils/container";
import { getProfileFromCookie } from "utils/getProfileFromCookie";
import { checkIsWebView } from "../utils/responsive";
import "../components/ConnectShopifyStoreModal/profile-selection.scss";
import "../components/SignUpModals/SignUpTrialModal/index.scss";
import "../components/AuthModals/AuthTrialModal/index.scss";
import "@komi-app/components/dist/index.css";

// import { useNotificationRequest } from "hooks/useNotification";

const AcceptCookies = dynamic(() => import("components/AcceptCookies"));
const MobileSuggestion = dynamic(() => import("components/MobileSuggestion"));

const TopProgressBar = dynamic(
  () => {
    return import("components/TopProgressBar");
  },
  { ssr: false },
);

function KomiApp({ Component, pageProps }: AppProps) {
  // useNotificationRequest();
  const store: any = useStore();

  const workerRef = useRef<any>();
  useEffect(() => {
    // if (process.browser) {
    //   workerRef.current = new Worker(new URL("../../worker.js", import.meta.url));

    //   workerRef.current.onmessage = (evt: any) => alert(`WebWorker Response => ${evt.data}`);
    // }
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker
          .register(new URL("../worker.js", import.meta.url))
          .then(function (registration) {
            console.log("SW registered: ", registration);
          })
          .catch(function (registrationError) {
            console.log("SW registration failed: ", registrationError);
          });
      });
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="height=device-height,
                      width=device-width, initial-scale=1.0,
                      minimum-scale=1.0, maximum-scale=1.0,
                      user-scalable=no, target-densitydpi=device-dpi"
        />
      </Head>
      <TopProgressBar />

      {process.browser ? (
        <PersistGate persistor={store.__persistor} loading={<></>}>
          <AuthProvider>
            <ContainerProvider>
              <Component {...pageProps} />
              <MobileSuggestion />
              <AcceptCookies isWebView={pageProps.isWebView} />
            </ContainerProvider>
          </AuthProvider>
        </PersistGate>
      ) : (
        <AuthProvider>
          <ContainerProvider>
            <Component {...pageProps} />
            <MobileSuggestion />
            <AcceptCookies isWebView={pageProps.isWebView} />
          </ContainerProvider>
        </AuthProvider>
      )}
    </>
  );
}

KomiApp.getInitialProps = async ({ Component, ctx }: any) => {
  const { req, store } = ctx;

  getProfileFromCookie({ req, store });
  const isWebView = checkIsWebView(req);
  return {
    pageProps: {
      ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
      isWebView,
    },
  };
};

export default wrapper.withRedux(KomiApp);
// export default wrapper.withRedux(appWithTranslation(KomiApp));
