import { EMAIL } from "./../../components/TwoFactorModal/TwoFactorModal";
import { PHONE } from "components/TwoFactorModal";
import { Action, handleActions } from "redux-actions";
import {
  checkUserExistedActions,
  checkUserExistedTypes,
  forgotPasswordActions,
  forgotPasswordTypes,
  getTalentCredentialActions,
  getTalentCredentialTypes,
  resetPasswordActions,
  resetPasswordTypes,
  resetUserActionType,
  sendOTPActions,
  sendOTPTypes,
  setUserValueType,
  signInWithAppleActions,
  signInWithAppleTypes,
  signInWithFacebookActions,
  signInWithFacebookTypes,
  signInWithGoogleActions,
  signInWithGoogleTypes,
  updatePasswordActions,
  createPasswordActions,
  createPasswordTypes,
  addBookingSetupActions,
  verifyOTPActions,
  verifyOTPTypes,
  acceptCollaboratorInviteActions,
  acceptCollaboratorInviteTypes,
  getListUserPreSaveAppleActions,
  getListUserPreSaveAppleTypes,
  getListUserPreSaveSpotifyActions,
  getListUserPreSaveSpotifyTypes,
  joinFanClubActions,
  joinFanClubTypes,
  talentLoginActions,
  talentLoginTypes,
  talentLoginWithGoogleActions,
  talentLoginWithGoogleTypes,
  requestOtpActions,
} from "redux/User/actions";
import {
  getUserByIdActions,
  getUserByIdTypes,
  logoutActions,
  logoutTypes,
  signInActions,
  signInTypes,
  signUpActions,
  signUpTypes,
  updatePasswordTypes,
  updateUserProfileActions,
  updateUserProfileTypes,
} from "./actions";
import { UserState } from "./types";

const handlers = {
  // sign in
  [signInActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [signInActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    user: payload,
    loading: false,
    type: signInTypes.SUCCESS,
  }),
  [signInActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: signInTypes.FAILURE,
  }),
  //talent login
  [talentLoginActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [talentLoginActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    user: payload,
    loading: false,
    type: talentLoginTypes.SUCCESS,
  }),
  [talentLoginActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: talentLoginTypes.FAILURE,
  }),
  // sign up
  [signUpActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
    signUpResult: null,
  }),
  [signUpActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    signUpResult: payload?.id ? null : payload,
    user: payload?.id ? payload : null,
    userSocial: null,
    loading: false,
    type: signUpTypes.SUCCESS,
  }),
  [signUpActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: signUpTypes.FAILURE,
  }),
  // forgot password
  [forgotPasswordActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [forgotPasswordActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    type: forgotPasswordTypes.SUCCESS,
  }),
  [forgotPasswordActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: forgotPasswordTypes.FAILURE,
  }),
  // reset password
  [resetPasswordActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [resetPasswordActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    type: resetPasswordTypes.SUCCESS,
  }),
  [resetPasswordActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: resetPasswordTypes.FAILURE,
  }),
  // logout
  [logoutActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [logoutActions.SUCCESS]: (state: UserState): UserState => ({
    ...state,
    user: undefined,
    joinFanClub: null,
    talentCredential: null,
    phoneOTP: null,
    loading: false,
    type: logoutTypes.SUCCESS,
  }),
  [logoutActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: logoutTypes.FAILURE,
  }),
  // get user by id
  [getUserByIdActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [getUserByIdActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    user: payload,
    loading: false,
    type: getUserByIdTypes.SUCCESS,
  }),
  [getUserByIdActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: getUserByIdTypes.FAILURE,
  }),
  // create password
  [createPasswordActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    password: null,
    loading: true,
  }),
  [createPasswordActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    password: payload,
    loading: false,
    type: createPasswordTypes.SUCCESS,
  }),
  [createPasswordActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: createPasswordTypes.FAILURE,
  }),
  // update password
  [updatePasswordActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    password: null,
    loading: true,
  }),
  [updatePasswordActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    password: payload,
    loading: false,
    type: updatePasswordTypes.SUCCESS,
  }),
  [updatePasswordActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: updatePasswordTypes.FAILURE,
  }),
  // update user profile
  [updateUserProfileActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: null,
  }),
  [updateUserProfileActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    user: {
      ...state.user,
      ...payload,
    },
    loading: false,
    type: updateUserProfileTypes.SUCCESS,
  }),
  [updateUserProfileActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: updateUserProfileTypes.FAILURE,
  }),
  [resetUserActionType]: (state: UserState): UserState => ({
    ...state,
    type: null,
  }),
  [setUserValueType]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    ...payload,
  }),
  // send phone number otp
  [sendOTPActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: sendOTPTypes.REQUEST,
  }),
  [sendOTPActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    phoneOTP: payload,
    loading: false,
    type: sendOTPTypes.SUCCESS,
  }),
  [sendOTPActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: sendOTPTypes.FAILURE,
  }),
  // verify otp
  [verifyOTPActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: verifyOTPTypes.REQUEST,
  }),
  [verifyOTPActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    verifyOtpResult: payload,
    signUpResult: null,
    user: payload,
    loading: false,
    type: verifyOTPTypes.SUCCESS,
  }),
  [verifyOTPActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: verifyOTPTypes.FAILURE,
  }),
  [resetUserActionType]: (state: UserState): UserState => ({
    ...state,
    type: null,
  }),
  // signIn with Facebook
  [signInWithFacebookActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: null,
  }),
  [signInWithFacebookActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    userSocial: payload,
    type: signInWithFacebookTypes.SUCCESS,
  }),
  [signInWithFacebookActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: signInWithFacebookTypes.FAILURE,
  }),
  // signIn with Apple
  [signInWithAppleActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: null,
  }),
  [signInWithAppleActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    userSocial: payload,
    type: signInWithAppleTypes.SUCCESS,
  }),
  [signInWithAppleActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: signInWithAppleTypes.FAILURE,
  }),
  // signIn with Google
  [signInWithGoogleActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: null,
  }),
  [signInWithGoogleActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    userSocial: payload,
    type: signInWithGoogleTypes.SUCCESS,
  }),
  [signInWithGoogleActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: signInWithGoogleTypes.FAILURE,
  }),

  // talent sign in with Google
  [talentLoginWithGoogleActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: null,
  }),
  [talentLoginWithGoogleActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    userSocial: payload,
    type: talentLoginWithGoogleTypes.SUCCESS,
  }),
  [talentLoginWithGoogleActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: talentLoginWithGoogleTypes.FAILURE,
  }),

  // check user existed
  [checkUserExistedActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    loading: true,
    type: null,
  }),
  [checkUserExistedActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    isUserExisted: payload,
    type: checkUserExistedTypes.SUCCESS,
  }),
  [checkUserExistedActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: checkUserExistedTypes.FAILURE,
  }),
  [requestOtpActions.REQUEST]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: null,
    phoneOtpLoading: payload.method === PHONE,
    emailOtpLoading: payload.method === EMAIL,
    type: null,
  }),
  [requestOtpActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    phoneOtpLoading: false,
    emailOtpLoading: false,
    otpId: payload,
    type: requestOtpActions.SUCCESS,
  }),
  [requestOtpActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    phoneOtpLoading: false,
    emailOtpLoading: false,
    error: payload,
    type: requestOtpActions.FAILURE,
  }),
  // get talent credentials
  [getTalentCredentialActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [getTalentCredentialActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    talentCredential: payload,
    loading: false,
    type: getTalentCredentialTypes.SUCCESS,
  }),
  [getTalentCredentialActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: getTalentCredentialTypes.FAILURE,
  }),
  [addBookingSetupActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => {
    return {
      ...state,
      bookingSetup: payload,
    };
  },

  // accept collaborator invite
  [acceptCollaboratorInviteActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
  }),
  [acceptCollaboratorInviteActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    type: acceptCollaboratorInviteTypes.SUCCESS,
  }),
  [acceptCollaboratorInviteActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: acceptCollaboratorInviteTypes.FAILURE,
  }),
  // list pre-save spotify
  [getListUserPreSaveSpotifyActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
    spotifyPreSaveList: [],
  }),
  [getListUserPreSaveSpotifyActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    spotifyPreSaveList: payload,
    type: getListUserPreSaveSpotifyTypes.SUCCESS,
  }),
  [getListUserPreSaveSpotifyActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListUserPreSaveSpotifyTypes.FAILURE,
  }),
  // list pre-save apple
  [getListUserPreSaveAppleActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
    applePreSaveList: [],
  }),
  [getListUserPreSaveAppleActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    applePreSaveList: payload,
    type: getListUserPreSaveAppleTypes.SUCCESS,
  }),
  [getListUserPreSaveAppleActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: getListUserPreSaveAppleTypes.FAILURE,
  }),
  // join fan club
  [joinFanClubActions.REQUEST]: (state: UserState): UserState => ({
    ...state,
    error: null,
    type: null,
    loading: true,
    joinFanClub: null,
  }),
  [joinFanClubActions.SUCCESS]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    loading: false,
    joinFanClub: payload,
    type: joinFanClubTypes.SUCCESS,
  }),
  [joinFanClubActions.FAILURE]: (state: UserState, { payload }: Action<any>): UserState => ({
    ...state,
    error: payload,
    loading: false,
    type: joinFanClubTypes.FAILURE,
  }),
};

const initialState: UserState = {
  error: null,
  type: null,
  loading: false,
  phoneOtpLoading: false,
  emailOtpLoading: false,
  talentCredential: null,
  user: null,
  password: null,
  phoneOTP: null,
  userSocial: null,
  isUserExisted: null,
  otpId: null,
  currency: "GBP",
  temporaryAccessToken: null,
  isRequestJoinTalent: false,
  bookingSetup: [],
  signUpResult: null,
  verifyOtpResult: null,
  spotifyPreSaveList: [],
  applePreSaveList: [],
  joinFanClub: null,
};

export const userReducer = handleActions<UserState>(handlers, initialState);
