import { createAction } from "redux-actions";
import { createGenericTypes, createGenericActions } from "utils/createAction";

export const getTalentsTypes = createGenericTypes("GET_TALENTS");
export const getTalentsActions = createGenericActions(getTalentsTypes);

export const getTalentsTrendingTypes = createGenericTypes("GET_TALENTS_TRENDING");
export const getTalentsTrendingActions = createGenericActions(getTalentsTrendingTypes);

export const getTalentByIdTypes = createGenericTypes("GET_TALENT_BY_ID");
export const getTalentByIdActions = createGenericActions(getTalentByIdTypes);

export const getTalentByUsernameTypes = createGenericTypes("GET_TALENT_BY_USERNAME");
export const getTalentByUsernameActions = createGenericActions(getTalentByUsernameTypes);

export const getTalentBasicInfoTypes = createGenericTypes("GET_TALENT_BASIC_INFO");
export const getTalentBasicInfoActions = createGenericActions(getTalentBasicInfoTypes);

export const getTalentsByCategoryTypes = createGenericTypes("GET_TALENTS_BY_CATEGORY");
export const getTalentsByCategoryActions = createGenericActions(getTalentsByCategoryTypes);

export const getTrendingTalentsByCategoryTypes = createGenericTypes("GET_TRENDING_TALENTS_BY_CATEGORY");
export const getTrendingTalentsByCategoryActions = createGenericActions(getTrendingTalentsByCategoryTypes);

export const getTalentAllExperiencesTypes = createGenericTypes("GET_TALENT_EXPERIENCES_ALL");
export const getTalentAllExperiencesActions = createGenericActions(getTalentAllExperiencesTypes);

export const getTalentExclusiveContentExperiencesTypes = createGenericTypes("GET_TALENT_EXPERIENCES_EXCLUSIVE_CONTENT");
export const getTalentExclusiveContentExperiencesActions = createGenericActions(
  getTalentExclusiveContentExperiencesTypes,
);

export const followTalentTypes = createGenericTypes("FOLLOW_TALENT");
export const followTalentActions = createGenericActions(followTalentTypes);

export const unFollowTalentTypes = createGenericTypes("UN_FOLLOW_TALENT");
export const unFollowTalentActions = createGenericActions(unFollowTalentTypes);

export const getTalentProductsTypes = createGenericTypes("GET_TALENT_PRODUCTS");
export const getTalentProductsActions = createGenericActions(getTalentProductsTypes);

export const getTalentModulesTypes = createGenericTypes("GET_TALENT_MODULES");
export const getTalentModulesActions = createGenericActions(getTalentModulesTypes);

export const createPreSaveMusicTypes = createGenericTypes("CREATE_PRE_SAVE_MUSIC");
export const createPreSaveMusicActions = createGenericActions(createPreSaveMusicTypes);

export const setPreSaveMarketingPermissionTypes = createGenericTypes("SET_PRE_SAVE_MARKETING_PREMISSION");
export const setPreSaveMarketingPermissionActions = createGenericActions(setPreSaveMarketingPermissionTypes);

export const updateSpotifyUserTypes = createGenericTypes("UPDATE_SPOTIFY_USER");
export const updateSpotifyUserActions = createGenericActions(updateSpotifyUserTypes);

export const getSpotifyUserTokenTypes = createGenericTypes("GET_SPOTIFY_USER_TOKEN");
export const getSpotifyUserTokenActions = createGenericActions(getSpotifyUserTokenTypes);

export const setActiveModuleType = "SET_ACTIVE_MODULE";
export const setActiveModuleAction = createAction(setActiveModuleType);

export const setupTalentProfileTypes = createGenericTypes("SETUP_TALENT_PROFILES");
export const setupTalentProfileActions = createGenericActions(setupTalentProfileTypes);

export const setPlayerPlayingType = "SET_PLAYER_PLAYING";
export const setPlayerPlayingAction = createAction(setPlayerPlayingType);
