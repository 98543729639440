import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import { getRoomTokenActions, getRoomLiveClassTokenActions } from "./actions";
import { bookingService } from "services";

function* getRoomToken({ payload }: Action<number>) {
  const result: any = yield* call(bookingService.getRoomToken1To1, payload);
  if (result.ok) {
    yield* put(getRoomTokenActions.SUCCESS(result.response));
  } else {
    yield* put(getRoomTokenActions.FAILURE(result?.message || null));
  }
}

function* getRoomLiveClassToken({ payload }: Action<number>) {
  const result: any = yield* call(bookingService.getRoomTokenLiveClass, payload);
  if (result.ok) {
    yield* put(getRoomLiveClassTokenActions.SUCCESS(result.response));
  } else {
    yield* put(getRoomLiveClassTokenActions.FAILURE(result?.message || null));
  }
}

export default function* roomSagas(): SagaIterator {
  yield* takeLatest(getRoomTokenActions.REQUEST, getRoomToken);
  yield* takeLatest(getRoomLiveClassTokenActions.REQUEST, getRoomLiveClassToken);
}
