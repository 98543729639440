import { notification } from "antd";
import { Maybe } from "graphql/jsutils/Maybe";
import { ProductKey, ProductSuccess, Product } from "redux/User/sales";
import { Api } from "./api";

export default class SalesService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  async getProduct(product_key: ProductKey): Promise<Maybe<Product>> {
    const res: any = await this.api.getSalesProducts(product_key);

    if (!res.ok) return void notification.error(res.message);

    return (res.response as ProductSuccess).product;
  }
}
