import * as React from "react";
import Typography from "antd/lib/typography";
import LinkNext from "next/link";
import { LinkProps as LinkAntdProps } from "antd/lib/typography/Link";
import { TypographyPresets, presets } from "./Preset";
import { Url } from "url";
const LinkAntd = Typography.Link;

type LinkProps = LinkAntdProps & {
  preset?: TypographyPresets;
  className?: string;
  href: Url; // override type to make props compatible with LinkNext (no possible by extending interface)
};

export const Link = (props: LinkProps) => {
  const { preset, className, children, ...rest } = props;
  const namePreset = preset && presets[preset];
  const mainClass = `text text--primary ${namePreset ? namePreset : ""} ${className}`;

  return (
    <LinkNext {...rest}>
      <LinkAntd className={mainClass}>{children}</LinkAntd>
    </LinkNext>
  );
};
