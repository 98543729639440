import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  getAllCategoriesActions,
  getCategoryByIdActions,
  getCategoryLiveClassExperiencesActions,
  getCategoryOneToOneExperiencesActions,
  getCategoryOnDemandExperiencesActions,
  getCategoryCourseExperiencesActions,
  getCategoryAllExperiencesActions,
  getTrendingCategoriesActions,
  getCategoryTrendingExperiencesActions,
} from "./actions";
import {
  categoryService,
  experienceLiveClassService,
  experience1To1Service,
  exclusiveContentService,
  courseService,
} from "services";
import { GetCategoryExperiencesRequest, EXPERIENCE_TYPE } from "redux/Experience/types";

function* getAllCategories() {
  const result: any = yield* call(categoryService.getAllCategories);
  if (result.ok) {
    yield* put(getAllCategoriesActions.SUCCESS(result.response));
  } else {
    yield* put(getAllCategoriesActions.FAILURE(result?.message || null));
  }
}
function* getTrendingCategories() {
  const result: any = yield* call(categoryService.getTrendingCategories);
  if (result.ok) {
    yield* put(getTrendingCategoriesActions.SUCCESS(result.response));
  } else {
    yield* put(getTrendingCategoriesActions.FAILURE(result?.message || null));
  }
}

function* getCategoryById({ payload }: Action<any>) {
  const result: any = yield* call(categoryService.getCategoryById, payload);
  if (result.ok) {
    yield* put(getCategoryByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoryByIdActions.FAILURE(result.message));
  }
}

function* getLiveClassExperiences({ payload }: Action<GetCategoryExperiencesRequest>) {
  const result: any = yield* call(experienceLiveClassService.getCategoryExperiences, payload);
  if (result?.ok) {
    yield* put(getCategoryLiveClassExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoryLiveClassExperiencesActions.FAILURE(result));
  }
}

function* getOneToOneExperiences({ payload }: Action<GetCategoryExperiencesRequest>) {
  const result: any = yield* call(experience1To1Service.getCategoryExperiences, payload);
  if (result?.ok) {
    yield* put(getCategoryOneToOneExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoryOneToOneExperiencesActions.FAILURE(result));
  }
}
function* getTrendingExperiences({ payload }: Action<GetCategoryExperiencesRequest>) {
  const result: any = yield* call(courseService.getAllExperiences, payload.params);
  if (result?.ok) {
    yield* put(getCategoryTrendingExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoryTrendingExperiencesActions.FAILURE(result));
  }
}

function* getOnDemandExperiences({ payload }: Action<GetCategoryExperiencesRequest>) {
  const result: any = yield* call(exclusiveContentService.getCategoryExperiences, payload);
  if (result?.ok) {
    yield* put(getCategoryOnDemandExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoryOnDemandExperiencesActions.FAILURE(result));
  }
}

function* getCourseExperiences({ payload }: Action<GetCategoryExperiencesRequest>) {
  const result: any = yield* call(courseService.getCategoryExperiences, payload);
  if (result?.ok) {
    yield* put(getCategoryCourseExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoryCourseExperiencesActions.FAILURE(result));
  }
}

function* getAllExperiences({ payload }: Action<GetCategoryExperiencesRequest>) {
  const result: any = yield* call(experience1To1Service.getCategoryExperiences, payload, [
    EXPERIENCE_TYPE.LIVE_CLASS,
    EXPERIENCE_TYPE.COURSE,
    EXPERIENCE_TYPE.ON_DEMAND_CONTENT,
  ]);
  if (result?.ok) {
    yield* put(getCategoryAllExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getCategoryAllExperiencesActions.FAILURE(result));
  }
}

export default function* categorySagas(): SagaIterator {
  yield* takeLatest(getAllCategoriesActions.REQUEST, getAllCategories);
  yield* takeLatest(getTrendingCategoriesActions.REQUEST, getTrendingCategories);
  yield* takeLatest(getCategoryByIdActions.REQUEST, getCategoryById);
  yield* takeLatest(getCategoryLiveClassExperiencesActions.REQUEST, getLiveClassExperiences);
  yield* takeLatest(getCategoryOneToOneExperiencesActions.REQUEST, getOneToOneExperiences);
  yield* takeLatest(getCategoryTrendingExperiencesActions.REQUEST, getTrendingExperiences);
  yield* takeLatest(getCategoryOnDemandExperiencesActions.REQUEST, getOnDemandExperiences);
  yield* takeLatest(getCategoryCourseExperiencesActions.REQUEST, getCourseExperiences);
  yield* takeLatest(getCategoryAllExperiencesActions.REQUEST, getAllExperiences);
}
