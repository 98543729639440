import {
  GetUserExperiencesRequest,
  GetCategoryExperiencesRequest,
  GetTimeSlotResponse,
} from "./../../redux/Experience/types";
import { ListQueryParams, Response } from "../../redux/Common/types";
import { GetExperienceByIdRequest } from "../../redux/Experience/types";
import { Api } from "../api";

export default abstract class ExperienceService {
  constructor(protected api: Api) {
    this.api = api;
  }
  abstract getExperiences(params: ListQueryParams): Promise<Response<any>>;

  abstract getAllExperiences(params: ListQueryParams): Promise<Response<any>>;

  abstract getUserExperiences(params: GetUserExperiencesRequest): Promise<Response<any>>;

  abstract getAllUserExperiences(params: GetUserExperiencesRequest): Promise<Response<any>>;

  abstract getCategoryExperiences(
    params: GetCategoryExperiencesRequest,
    type?: string | string[],
  ): Promise<Response<any>>;

  abstract getExperienceById(payload: GetExperienceByIdRequest): Promise<Response<any>>;

  abstract getTimeSlots(payload: string): Promise<Response<GetTimeSlotResponse>>;
}
