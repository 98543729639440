import { commonReducer } from "./Common/reducer";
import { wishlistReducer } from "./Wishlist/reducer";
import { experienceReducer } from "./Experience/reducer";
import { RoomState } from "./Room/types";
import { purchasingOptionsReducer } from "./PurchasingOptions/reducer";
import { TalentState } from "./Talent/types";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers, AnyAction } from "redux";
import { UserState } from "./User/types";
import { userReducer } from "./User/reducer";
import { talentReducer } from "./Talent/reducer";
import { categoryReducer } from "./Category/reducer";
import { CategoryState } from "./Category/types";
import { HYDRATE } from "next-redux-wrapper";
import { PurchasingOptionsState } from "./PurchasingOptions/types";
import { roomReducer } from "./Room/reducer";
import { PaymentState } from "./Payment/types";
import { paymentReducer } from "./Payment/reducer";
import { ModalState } from "./Modal/types";
import { modalReducer } from "./Modal/reducer";
import { BookingState } from "./Booking/types";
import { bookingReducer } from "./Booking/reducer";
import { TagState } from "./Tags/types";
import { tagReducer } from "./Tags/reducer";
import { ExperienceState } from "./Experience/types";
import { WishlistState } from "./Wishlist/types";
import { LogRequestState } from "./LogRequest/types";
import { logRequestReducer } from "./LogRequest/reducer";
import { CommonState, PERSIST_KEY } from "./Common/types";
import { GiftCodesState } from "./GiftsCode/types";
import { giftCodesReducer } from "./GiftsCode/reducer";
import { SpecialRequestState } from "./SpecialRequest/types";
import { specialRequestReducer } from "./SpecialRequest/reducer";
import { BundleState } from "./Bundle/types";
import { bundleReducer } from "./Bundle/reducer";

export type IRootState = {
  userState: UserState;
  talentState: TalentState;
  categoryState: CategoryState;
  purchasingOptionsState: PurchasingOptionsState;
  roomState: RoomState;
  paymentState: PaymentState;
  modalState: ModalState;
  bookingState: BookingState;
  tagState: TagState;
  experienceState: ExperienceState;
  wishlistState: WishlistState;
  logRequestState: LogRequestState;
  commonState: CommonState;
  giftCodesState: GiftCodesState;
  specialRequestState: SpecialRequestState;
  bundleState: BundleState;
};

// we need it only on client side
const { persistReducer } = require("redux-persist");
const storage = require("redux-persist/lib/storage").default;

const userPersistConfig: any = {
  key: PERSIST_KEY,
  storage,
  whitelist: ["user", "currency", "bookingSetup"],
};

// Add all reducers here.
const reducer = combineReducers<IRootState>({
  userState: persistReducer(userPersistConfig, userReducer),
  talentState: talentReducer,
  categoryState: categoryReducer,
  purchasingOptionsState: purchasingOptionsReducer,
  roomState: roomReducer,
  paymentState: paymentReducer,
  modalState: modalReducer,
  bookingState: bookingReducer,
  tagState: tagReducer,
  experienceState: experienceReducer,
  wishlistState: wishlistReducer,
  logRequestState: logRequestReducer,
  commonState: commonReducer,
  giftCodesState: giftCodesReducer,
  specialRequestState: specialRequestReducer,
  bundleState: bundleReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const { userState, ...rest } = action.payload;
    const nextState = {
      ...state,
      ...rest,
    };
    return nextState;
  } else {
    return reducer(state, action);
  }
};

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
