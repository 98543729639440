import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { FAILURE, getActionBaseName, SUCCESS } from "utils/createAction";
import { REQUEST_API_ERROR, REQUEST_API_SUCCESS } from "./LogRequest/actions";
import { LogRequestState } from "./LogRequest/types";
import { MODAL_CREATE_ACCOUNT, toggleModalType } from "./Modal/actions";

export default class CustomMiddleware {
  public static ErrorRequestHandling: Middleware =
    (store: MiddlewareAPI<any>) =>
    (next: Dispatch) =>
    (action: AnyAction): any => {
      if (action?.type?.includes(FAILURE)) {
        if (action?.payload?.kind === "unauthorized" || action?.payload === "Unauthorized") {
          next({
            type: toggleModalType,
            payload: {
              modal: MODAL_CREATE_ACCOUNT,
              status: true,
              data: action?.type?.includes("BOOKING") ? { isBookingFlow: true } : {},
            },
          });
        }
        if (process.env.NODE_ENV !== "production") {
          next({
            type: REQUEST_API_ERROR,
            payload: {
              ...action.payload,
              type: `REQUEST API ERROR: ${getActionBaseName(action.type)}`,
            },
          } as { type: string; payload: LogRequestState });
        }
        next(action);
      } else next(action);
    };

  public static SuccessRequestHandling: Middleware =
    (store: MiddlewareAPI<any>) =>
    (next: Dispatch) =>
    (action: AnyAction): any => {
      if (action?.type?.includes(SUCCESS)) {
        if (process.env.NODE_ENV !== "production") {
          next({
            type: REQUEST_API_SUCCESS,
            payload: {
              ...action.payload,
              type: `REQUSET API SUCCESS: ${getActionBaseName(action.type)}`,
            },
          } as { type: string; payload: LogRequestState });
        }
        next(action);
      } else next(action);
    };
}
