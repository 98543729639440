import { Response, ListQueryParams, Pagination } from "../redux/Common/types";
import { Api } from "./api";
import { Wishlist } from "redux/Wishlist/types";
import { Experience } from "redux/Experience/types";

interface IWishlistService {
  getWishlist(payload: ListQueryParams): Promise<Response<Pagination<Wishlist>>>;
  addToWishlist(experienceId: Experience["id"]): Promise<Response<Wishlist>>;
  removeFromWishlist(experienceId: Experience["id"]): Promise<Response<Wishlist>>;
}

export default class WishlistService implements IWishlistService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getWishlist = (payload: ListQueryParams): Promise<Response<Pagination<Wishlist>>> => {
    return this.api.getWishlist(payload);
  };
  addToWishlist = (experienceId: Experience["id"]): Promise<Response<Wishlist>> => {
    return this.api.addToWishlist(experienceId);
  };

  removeFromWishlist = (experienceId: Experience["id"]): Promise<Response<Wishlist>> => {
    return this.api.removeFromWishlist(experienceId);
  };
}
