import uniqBy from "lodash/uniqBy";
import { Action, handleActions } from "redux-actions";
import {
  createBookingActions,
  getBookingByIdActions,
  getBookingsActions,
  getMyVideosActions,
  getUpcomingBookingsActions,
  rateBookingActions,
  resetBookingActionType,
  tipBookingActions,
  updateBookingAttachmentsActions,
} from "./actions";
import { BookingState } from "./types";

const handlers = {
  // get bookings
  [getBookingsActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    bookings: null,
    loading: true,
  }),
  [getBookingsActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    bookings: payload,
    loading: false,
  }),
  [getBookingsActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get my videos
  [getMyVideosActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getMyVideosActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    loading: false,
    myVideosHasMore: payload.meta.currentPage < payload.meta.totalPages,
    myVideosLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.myVideosLatestPage,
    myVideos: uniqBy([...state.myVideos, ...payload.items], "id"),
  }),
  [getMyVideosActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get upcoming bookings
  [getUpcomingBookingsActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getUpcomingBookingsActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    loading: false,
    upcomingBookingsHasMore: payload.meta.currentPage < payload.meta.totalPages,
    upcomingBookingsLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.upcomingBookingsLatestPage,
    upcomingBookings: uniqBy([...state.upcomingBookings, ...payload.items], "id"),
  }),
  [getUpcomingBookingsActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // get booking by id
  [getBookingByIdActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    bookingData: null,
    loading: true,
  }),
  [getBookingByIdActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    bookingData: payload,
    attachments: payload.attachments,
    loading: false,
  }),
  [getBookingByIdActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // create booking
  [createBookingActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    bookingCreated: null,
    loading: true,
    isCreatingBooking: true,
    error: undefined,
    errorData: undefined,
  }),
  [createBookingActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    bookingCreated: payload,
    loading: false,
    isCreatingBooking: false,
  }),
  [createBookingActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload?.message,
    errorData: payload?.data,
    bookingCreated: null,
    loading: false,
    isCreatingBooking: false,
  }),

  // rate booking
  [rateBookingActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    loading: true,
    error: undefined,
    ratingData: null,
  }),
  [rateBookingActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    loading: false,
    ratingData: payload,
  }),
  [rateBookingActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload?.message,
    loading: false,
  }),

  // tip booking
  [tipBookingActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    loading: true,
    error: undefined,
    tipData: null,
  }),
  [tipBookingActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    loading: false,
    tipData: payload,
  }),
  [tipBookingActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload?.message,
    loading: false,
  }),
  // update booking attachments
  [updateBookingAttachmentsActions.REQUEST]: (state: BookingState): BookingState => ({
    ...state,
    loading: true,
    error: undefined,
  }),
  [updateBookingAttachmentsActions.SUCCESS]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    loading: false,
    attachments: payload,
  }),
  [updateBookingAttachmentsActions.FAILURE]: (state: BookingState, { payload }: Action<any>): BookingState => ({
    ...state,
    error: payload?.message,
    loading: false,
  }),
  [resetBookingActionType]: (state: BookingState): BookingState => ({
    error: null,
    errorData: null,
    loading: false,
    isCreatingBooking: false,
    bookings: null,
    myExperiences: null,
    bookingData: null,
    bookingCreated: null,
    ratingData: null,
    tipData: null,
    attachments: null,
    myVideos: [],
    myVideosHasMore: true,
    myVideosLatestPage: 1,
    upcomingBookings: [],
    upcomingBookingsLatestPage: 1,
    upcomingBookingsHasMore: true,
  }),
};

const initialState: BookingState = {
  attachments: null,
  error: null,
  errorData: null,
  loading: false,
  isCreatingBooking: false,
  bookings: null,
  myExperiences: null,
  bookingData: null,
  bookingCreated: null,
  ratingData: null,
  tipData: null,
  myVideos: [],
  myVideosHasMore: false,
  myVideosLatestPage: 1,
  upcomingBookings: [],
  upcomingBookingsHasMore: false,
  upcomingBookingsLatestPage: 1,
};

export const bookingReducer = handleActions<BookingState>(handlers, initialState);
