import { createAction } from "redux-actions";

export const MODAL_LOGIN = "MODAL_LOGIN";
export const MODAL_SIGNIN = "MODAL_SIGNIN";
export const MODAL_TWO_FACTOR = "MODAL_TWO_FACTOR";
export const SELECT_METHOD = "SELECT_METHOD";
export const MODAL_CREATE_ACCOUNT = "MODAL_CREATE_ACCOUNT";
export const MODAL_CREATE_ACCOUNT_INVITATION = "MODAL_CREATE_ACCOUNT_INVITATION";
export const MODAL_SEND_TIP = "MODAL_SEND_TIP";
export const MODAL_WARNING = "MODAL_WARNING";
export const MODAL_WAITLIST = "MODAL_WAITLIST";
export const MODAL_WAITLIST_CONFIRM_MODAL = "MODAL_WAITLIST_CONFIRM_MODAL";
export const MODAL_SIGNUP_ENTRY = "MODAL_SIGNUP_ENTRY";
export const MODAL_SIGNUP_FORM = "MODAL_SIGNUP_FORM";
export const MODAL_SIGNUP_ACTIVATE = "MODAL_SIGNUP_ACTIVATE";

export const MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_SIGNUP = "MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_SIGNUP";
export const MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_LOGIN = "MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_LOGIN";

export const toggleModalType = "TOGGLE_MODAL";
export const toggleModalActions = createAction(toggleModalType);
