import { GetTransactionRequest, Transaction } from "redux/Booking/types";
import {
  CheckoutBookingRequest,
  CheckoutExpertSupportRequest,
  GetBraintreeAccessTokenResult,
  StripePaymentMethod,
} from "redux/Payment/types";
import { Response } from "../redux/Common/types";
import { Api } from "./api";

export interface IPaymentService {
  getBraintreeClientToken(): Promise<Response<GetBraintreeAccessTokenResult>>;
  checkoutBooking(payload: CheckoutBookingRequest): Promise<Response<any>>;
  getExchangeRates(localCurrency: string): Promise<any>;
  getTransactionById(id: number): Promise<Response<Transaction>>;
  checkoutSupport(payload: CheckoutExpertSupportRequest): Promise<Response<any>>;
  checkoutExpertSupport(payload: CheckoutExpertSupportRequest): Promise<Response<any>>;
  getTransactions(payload: GetTransactionRequest): Promise<any>;
}
export default class PaymentService implements IPaymentService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getBraintreeClientToken = (): Promise<Response<GetBraintreeAccessTokenResult>> => {
    return this.api.getBraintreeClientToken();
  };

  checkoutBooking = (payload: CheckoutBookingRequest): Promise<Response<any>> => {
    return this.api.checkoutBooking(payload);
  };

  checkoutExpertSupport = (payload: CheckoutExpertSupportRequest): Promise<Response<any>> => {
    return this.api.checkoutExpertSupport(payload);
  };

  checkoutSupport = (payload: CheckoutExpertSupportRequest): Promise<Response<any>> => {
    return this.api.checkoutSupport(payload);
  };

  getExchangeRates = (localCurrency: string): Promise<any> => {
    return this.api.getExchangeRates(localCurrency);
  };

  getTransactions = (payload: GetTransactionRequest): Promise<Response<any>> => {
    return this.api.getTransactions(payload);
  };

  getPaymentMethods = (): Promise<Response<StripePaymentMethod[]>> => {
    return this.api.getPaymentMethods();
  };

  getTransactionById = (id: number): Promise<Response<Transaction>> => {
    return this.api.getTransactionById(id);
  };
}
