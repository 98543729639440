import * as React from "react";
import Typography from "antd/lib/typography";
import { TitleProps as TitleAntdProps } from "antd/lib/typography/Title";
import { TypographyPresets, presets } from "./Preset";

const TitleAntd = Typography.Title;
interface TitleProps extends TitleAntdProps {
  preset?: TypographyPresets;
  className?: string;
}

export const Title = (props: TitleProps) => {
  const { preset, className, ...rest } = props;
  const namePreset = preset && presets[preset];
  const mainClass = `text title ${namePreset ? namePreset : ""} ${className}`;
  return <TitleAntd className={mainClass} {...rest} />;
};
