import React from "react";
import message, { ConfigOptions } from "antd/lib/message";
import { NotificationMessage, NotificationIcon } from "components/NotificationMessage";

export type NoticeType = "info" | "success" | "error" | "warning" | "loading";

const defaultConfig: ConfigOptions = {
  top: 5,
  duration: 2,
  maxCount: 5,
};

type MessageParams = {
  message: string;
  description?: string;
  duration?: number;
  isOffDuration?: boolean;
};

class Notifiaction {
  constructor(config: ConfigOptions) {
    message.config(config);
  }

  private notify(type: NoticeType, params: MessageParams) {
    message.open({
      key: params.message,
      type: type,
      className: "noti-message",
      icon: <NotificationIcon type={type} />,
      duration: params.duration || defaultConfig.duration || 2,
      content: <NotificationMessage message={params.message} onClose={() => message.destroy(params.message)} />,
    });
  }

  error(params: MessageParams) {
    this.notify("error", params);
  }

  warn(params: MessageParams) {
    this.notify("warning", params);
  }

  success(params: MessageParams) {
    this.notify("success", params);
  }

  info(params: MessageParams) {
    this.notify("info", params);
  }
}

export default new Notifiaction(defaultConfig);
