import axios from "axios";
import { komiApiUrl } from "services/DomainService";

export default class ShopifyService {
  getProductList(storeFrontToken: string, shop: string, countryCode: string, gid: string) {
    return this.query(
      storeFrontToken,
      shop,
      `query productBydID @inContext(country: ${countryCode}){
      product(id: "${gid}"){
        id
          handle
          description
          onlineStoreUrl
          variants(first: 1) {
            nodes {
              priceV2 {
                amount
                currencyCode
              }
            }
          }
        }
      }`,
    );
  }

  getProfiles(urlParams: URLSearchParams) {
    return axios.get(`${komiApiUrl}/shopify/storefront?${urlParams.toString()}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  private query(storefrontToken: string, shop: string, graphqlQuery: string) {
    const headers = {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": storefrontToken,
    };

    return axios.post(`https://${shop}/api/2022-04/graphql.json`, { query: graphqlQuery }, { headers });
  }
}
