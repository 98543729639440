import { Act } from "@testing-library/react-hooks";
import { Action } from "redux-actions";
import { SagaIterator } from "redux-saga";
import { GetUserExperiencesRequest } from "redux/Experience/types";
import { CreatePreSaveMusicRequest, GetTalentsRequest, PreSaveMarketingPermission, Talent } from "redux/Talent/types";
import { getListUserPreSaveAppleActions, getListUserPreSaveSpotifyActions } from "redux/User/actions";
import { MusicItemType, SpotifyUser, User } from "redux/User/types";
import { spotifyService, talentService, userService } from "services";
import { call, put, takeLatest } from "typed-redux-saga";
import {
  createPreSaveMusicActions,
  followTalentActions,
  getSpotifyUserTokenActions,
  getTalentAllExperiencesActions,
  getTalentBasicInfoActions,
  getTalentByIdActions,
  getTalentByUsernameActions,
  getTalentExclusiveContentExperiencesActions,
  getTalentModulesActions,
  getTalentProductsActions,
  getTalentsActions,
  getTalentsByCategoryActions,
  getTalentsTrendingActions,
  getTrendingTalentsByCategoryActions,
  unFollowTalentActions,
  updateSpotifyUserActions,
  setupTalentProfileActions,
  setPreSaveMarketingPermissionActions,
} from "./actions";
import { GetTalentsByCategoryRequest, SetupTalentProfileRequest } from "./types";

function* getTalents({ payload }: Action<GetTalentsRequest>) {
  const result: any = yield* call(talentService.getTalents, payload);
  if (result.ok) {
    yield* put(getTalentsActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentsActions.FAILURE(result?.message || null));
  }
}

function* getTalentsTrending() {
  const result: any = yield* call(talentService.getTalentsTrending);
  if (result.ok) {
    yield* put(getTalentsTrendingActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentsTrendingActions.FAILURE(result?.message || null));
  }
}

function* getTalentById({ payload }: Action<string>) {
  const result: any = yield* call(talentService.getTalentById, payload);
  if (result.ok) {
    yield* put(getTalentByIdActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentByIdActions.FAILURE(result.message));
  }
}

function* getTalentByUsername({ payload }: Action<any>) {
  const result: any = yield* call(talentService.getTalentByUsername, payload?.username, payload?.countryCode);
  if (result.ok) {
    yield* put(getTalentByUsernameActions.SUCCESS(result.response));
    yield* put(
      getTalentModulesActions.REQUEST({
        talentProfileId: result.response.talentProfile.id,
        countryCode: payload?.countryCode,
      }),
    );
  } else {
    yield* put(getTalentByUsernameActions.FAILURE(result));
  }
}

function* getTalentBasicInfo({ payload }: Action<any>) {
  const result: any = yield* call(talentService.getTalentByUsername, payload, "");
  if (result.ok) {
    yield* put(getTalentBasicInfoActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentBasicInfoActions.FAILURE(result));
  }
}

function* getTalentModules({ payload }: Action<any>) {
  const result: any = yield* call(talentService.getTalentModules, payload?.talentProfileId, payload?.countryCode);
  if (result.ok) {
    yield* put(getTalentModulesActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentModulesActions.FAILURE(result.message));
  }
}

function* getTalentsByCategory({ payload }: Action<GetTalentsByCategoryRequest>) {
  const result: any = yield* call(talentService.getTalentsByCategory, payload);
  if (result.ok) {
    yield* put(getTalentsByCategoryActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentsByCategoryActions.FAILURE(result.message));
  }
}

function* getTrendingTalentsByCategory({ payload }: Action<GetTalentsByCategoryRequest>) {
  const result: any = yield* call(talentService.getTalentsByCategory, payload);
  if (result.ok) {
    yield* put(getTrendingTalentsByCategoryActions.SUCCESS(result.response));
  } else {
    yield* put(getTrendingTalentsByCategoryActions.FAILURE(result.message));
  }
}

function* getAllExperiences({ payload }: Action<GetUserExperiencesRequest>) {
  const result: any = yield* call(talentService.getAllUserExperiences, payload);
  if (result?.ok) {
    yield* put(getTalentAllExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentAllExperiencesActions.FAILURE(result));
  }
}

function* getExclusiveContentExperiences({ payload }: Action<GetUserExperiencesRequest>) {
  const result: any = yield* call(talentService.getUserExperiences, payload);
  if (result?.ok) {
    yield* put(getTalentExclusiveContentExperiencesActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentExclusiveContentExperiencesActions.FAILURE(result));
  }
}

function* followTalent({ payload }: Action<Talent["id"]>) {
  const result: any = yield* call(talentService.followTalent, payload);
  if (result?.ok) {
    yield* put(followTalentActions.SUCCESS(result.response));
  } else {
    yield* put(followTalentActions.FAILURE(result));
  }
}

function* unFollowTalent({ payload }: Action<Talent["id"]>) {
  const result: any = yield* call(talentService.unFollowTalent, payload);
  if (result?.ok) {
    yield* put(unFollowTalentActions.SUCCESS(result.response));
  } else {
    yield* put(unFollowTalentActions.FAILURE(result));
  }
}

function* getTalentProducts({ payload }: Action<User["id"]>) {
  const result: any = yield* call(talentService.getTalentProducts, payload);
  if (result?.ok) {
    yield* put(getTalentProductsActions.SUCCESS(result.response));
  } else {
    yield* put(getTalentProductsActions.FAILURE(result));
  }
}

function* createPreSaveMusic({
  payload: { payload, onSuccess, onFailure },
}: Action<{
  payload: CreatePreSaveMusicRequest;
  onSuccess: (result: any, payload: CreatePreSaveMusicRequest) => void;
  onFailure: (result: any, payload: CreatePreSaveMusicRequest) => void;
}>) {
  const result: any = yield* call(talentService.createPreSaveMusic, payload);
  if (result?.ok) {
    if (payload.linkType === MusicItemType.SPOTIFY) {
      yield* put(getListUserPreSaveSpotifyActions.REQUEST({ token: payload.token }));
    } else {
      yield* put(getListUserPreSaveAppleActions.REQUEST({ token: payload.token }));
    }

    yield* put(createPreSaveMusicActions.SUCCESS(result.response));
    if (onSuccess) {
      yield* call(onSuccess, result, payload);
    }
  } else {
    yield* put(createPreSaveMusicActions.FAILURE(result));
    if (onFailure) {
      yield* call(onFailure, result, payload);
    }
  }
}

function* setPreSaveMarketingPermission({
  payload: { payload },
}: Action<{
  payload: PreSaveMarketingPermission;
}>) {
  const result: any = yield* call(talentService.setPreSaveMarketingPermission, payload);

  if (result?.ok) {
    yield* put(setPreSaveMarketingPermissionActions.SUCCESS(result.response));
  } else {
    yield* put(setPreSaveMarketingPermissionActions.FAILURE(result));
  }
}

function* getSpotifyUserToken({ payload }: Action<any>) {
  const result: any = yield* call(userService.spotifyRefreshToken);
  if (result.ok) {
    yield* put(updateSpotifyUserActions.REQUEST(result.response)); // go to update
  }
}

function* updateSpotifyUser({ payload }: Action<SpotifyUser>) {
  yield* call(spotifyService.signin, payload);

  // run callback update token
  if (payload.callback && typeof payload.callback === "function") {
    payload.callback();
  }
}

function* setupTalentProfile({ payload }: Action<SetupTalentProfileRequest>) {
  const result: any = yield* call(talentService.setupTalentProfile, payload);
  if (result.ok) {
    payload?.onSuccess?.(result.response);
    yield* put(setupTalentProfileActions.SUCCESS(result.response));
  } else {
    yield* put(setupTalentProfileActions.FAILURE(result));
    payload?.onError?.();
  }
}

export default function* talentSagas(): SagaIterator {
  yield* takeLatest(getTalentsActions.REQUEST, getTalents);
  yield* takeLatest(getTalentsTrendingActions.REQUEST, getTalentsTrending);
  yield* takeLatest(getTalentByIdActions.REQUEST, getTalentById);
  yield* takeLatest(getTalentByUsernameActions.REQUEST, getTalentByUsername);
  yield* takeLatest(getTalentBasicInfoActions.REQUEST, getTalentBasicInfo);
  yield* takeLatest(getTalentModulesActions.REQUEST, getTalentModules);
  yield* takeLatest(getTalentsByCategoryActions.REQUEST, getTalentsByCategory);
  yield* takeLatest(getTrendingTalentsByCategoryActions.REQUEST, getTrendingTalentsByCategory);
  yield* takeLatest(getTalentExclusiveContentExperiencesActions.REQUEST, getExclusiveContentExperiences);
  yield* takeLatest(getTalentAllExperiencesActions.REQUEST, getAllExperiences);
  yield* takeLatest(followTalentActions.REQUEST, followTalent);
  yield* takeLatest(unFollowTalentActions.REQUEST, unFollowTalent);
  yield* takeLatest(getTalentProductsActions.REQUEST, getTalentProducts);
  yield* takeLatest(createPreSaveMusicActions.REQUEST, createPreSaveMusic);
  yield* takeLatest(updateSpotifyUserActions.REQUEST, updateSpotifyUser);
  yield* takeLatest(getSpotifyUserTokenActions.REQUEST, getSpotifyUserToken);
  yield* takeLatest(setupTalentProfileActions.REQUEST, setupTalentProfile);
  yield* takeLatest(setPreSaveMarketingPermissionActions.REQUEST, setPreSaveMarketingPermission);
}
