import { Booking, Transaction } from "redux/Booking/types";
import { Pagination } from "redux/Common/types";
export type CheckoutBooking1To1Request = {
  bookingId: number;
};

export type CheckoutRequest = {
  requestId: number;
};

export enum TRANSACTION_TYPE {
  PAYMENT = "PAYMENT",
  EXPERT_TIP = "EXPERT_TIP",
  TIP = "TIP",
}

export type PaymentMethod = {
  globalId: string;
  imageUrl: string;
  last4: string;
  cardType: string;
  description: string;
  default: boolean;
  nonce: string;
  token: string;
  email: string;
  expirationMonth: string;
  expirationYear: string;
  expirationDate: string;
};

export type CheckoutBookingRequest = {
  bookingId: Booking["id"];
  couponCode?: string;
  paymentMethodToken?: string;
  paymentMethodNonce?: string;
};

export type CheckoutExpertSupportRequest = {
  talentId: number | undefined;
  bookingId?: number;
  amountLocal: number;
  notes?: string;
  localCurrency?: string;
};

export type GetBraintreeAccessTokenResult = {
  clientToken: string;
  usedPaymentMethods: PaymentMethod[];
};

export enum PAYMENT_TYPE {
  CREDIT_CARD = 1,
  PAYPAL = 2,
  APPLE_PAY = 3,
}

export type StripePaymentMethod = {
  billing_details: {
    email: string | null;
    name: string;
    phone: string | null;
    address: {
      city: string | null;
      country: string | null;
      line1: string | null;
      line2: string | null;
      postal_code: number | null;
      state: string | null;
    };
  };
  card: {
    brand: string;
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from?: any;
    last4: string;
    checks: {
      address_line1_check?: any;
      address_postal_code_check?: any;
      cvc_check: string;
    };
  };
  created: number;
  customer: string;
  id: string;
  livemode: false;
  metadata: any;
  object: string;
  type: string;
};

export type PaymentState = {
  isLoading: boolean;
  error?: string | null;
  checkoutResult?: any;
  clientSecret?: string;
  token?: GetBraintreeAccessTokenResult | null;
  exchangeRates?: any;
  exchangeRatesUSD?: any;
  exchangeRatesTip?: any;
  sendTipResult?: any;
  transactions?: Pagination<Transaction[]> | null;
  transactionData?: Transaction | null;
  paymentMethods?: StripePaymentMethod[];
};
