import uniqBy from "lodash/unionBy";
import { Action, handleActions } from "redux-actions";
import {
  createExclusiveContentActions,
  createExperience1To1Actions,
  createExperienceActions,
  createExperienceLiveClassActions,
  getExclusiveContentExperiencesActions,
  getCourseExperiencesActions,
  getExperienceByIdActions,
  getExperiencesActions,
  getLiveClassExperiencesActions,
  getOneToOneExperiencesActions,
  publishExperienceActions,
  updateExclusiveContentActions,
  updateExperience1To1Actions,
  updateExperienceLiveClassActions,
  updateExperienceProcessStatusActions,
  updateProductImagesActions,
  updateStagingCoversActions,
  getExperienceTimeSlotsActions,
  getAllExperiencesActions,
  getListExperiencesActions,
  checkExperienceIsBookedActions,
} from "./actions";
import { ExperienceState, EXPERIENCE_PROCESS_STATUS } from "./types";

const handlers = {
  // get experiences
  [getExperiencesActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    experience: null,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.EMPTY,
  }),
  [getExperiencesActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    totalPagesExperiences: payload.items.length > 0 ? payload.meta.totalPages : state.totalPagesExperiences,
    experience: {
      ...payload,
    },
    error: null,
    loading: false,
  }),
  [getExperiencesActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),

  // get list experiences
  [getListExperiencesActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.EMPTY,
  }),
  [getListExperiencesActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    totalPagesExperiences: payload.items.length > 0 ? payload.meta.totalPages : state.totalPagesExperiences,
    experiences:
      payload.meta.currentPage === 1 ? [...payload.items] : uniqBy([...state.experiences, ...payload.items], "id"),
    error: null,
    loading: false,
  }),
  [getListExperiencesActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get experience by id
  [getExperienceByIdActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    experienceDetail: null,
    error: null,
    loading: true,
    hasBooking: false,
  }),
  [getExperienceByIdActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    fetchExperienceDetail: payload,
    loading: false,
  }),
  [getExperienceByIdActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create experience
  [createExperienceActions.REQUEST]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    ...payload,
  }),
  // create experience 1 to 1
  [createExperience1To1Actions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExperience1To1Actions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExperience1To1Actions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience 1 to 1
  [updateExperience1To1Actions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExperience1To1Actions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExperience1To1Actions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create experience Broadcast
  [createExperienceLiveClassActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExperienceLiveClassActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExperienceLiveClassActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience Live Broadcast
  [updateExperienceLiveClassActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExperienceLiveClassActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExperienceLiveClassActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // create experience exclusive content
  [createExclusiveContentActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.DRAFTED,
  }),
  [createExclusiveContentActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    loading: false,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [createExclusiveContentActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience exclusive content
  [updateExclusiveContentActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.CREATED,
  }),
  [updateExclusiveContentActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    experienceDetail: payload,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.UPDATED,
    loading: false,
  }),
  [updateExclusiveContentActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // update experience process status
  [updateExperienceProcessStatusActions.REQUEST]: (
    state: ExperienceState,
    { payload }: Action<any>,
  ): ExperienceState => ({
    ...state,
    contentProcessStatus: payload,
  }),
  // update photos and videos of experience
  [updateStagingCoversActions.REQUEST]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => {
    let stagingCovers = state.stagingCovers;

    if (payload?.uploadItemIndex) {
      stagingCovers = {
        ...stagingCovers,
        [payload.uploadItemIndex]:
          stagingCovers && stagingCovers[payload.uploadItemIndex] && payload.photo.percent
            ? { ...stagingCovers[payload.uploadItemIndex], ...payload.photo }
            : payload.photo,
      };
    } else {
      Object.keys(stagingCovers).map((key) => {
        stagingCovers[key] = { id: stagingCovers[key].id };
        return null;
      });
    }
    return {
      ...state,
      stagingCovers,
    };
  },
  // update photos product images
  [updateProductImagesActions.REQUEST]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => {
    let productImages = state.productImages;

    if (payload?.uploadItemIndex) {
      productImages = {
        ...productImages,
        [payload.uploadItemIndex]: payload.photo,
      };
    } else {
      Object.keys(productImages).map((key) => {
        productImages[key] = { id: productImages[key].id };
        return null;
      });
    }
    return {
      ...state,
      productImages,
    };
  },
  // publish experience
  [publishExperienceActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [publishExperienceActions.SUCCESS]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    contentProcessStatus: EXPERIENCE_PROCESS_STATUS.PUBLISHED,
    loading: false,
  }),
  [publishExperienceActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  // get Live Broadcast experiences
  [getLiveClassExperiencesActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getLiveClassExperiencesActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [getLiveClassExperiencesActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    loading: false,
    liveClassLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.liveClassLatestPage,
    liveClassExperiences: uniqBy([...state.liveClassExperiences, ...payload.items], "id"),
  }),
  // get one to one experiences
  [getOneToOneExperiencesActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getOneToOneExperiencesActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [getOneToOneExperiencesActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    loading: false,
    oneToOneLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.oneToOneLatestPage,
    oneToOneExperiences: uniqBy([...state.oneToOneExperiences, ...payload.items], "id"),
  }),
  // get exclusive content experiences
  [getExclusiveContentExperiencesActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getExclusiveContentExperiencesActions.FAILURE]: (
    state: ExperienceState,
    { payload }: Action<any>,
  ): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [getExclusiveContentExperiencesActions.SUCCESS]: (
    state: ExperienceState,
    { payload }: Action<any>,
  ): ExperienceState => ({
    ...state,
    loading: false,
    exclusiveContentLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.exclusiveContentLatestPage,
    exclusiveContentExperiences: uniqBy([...state.exclusiveContentExperiences, ...payload.items], "id"),
  }),
  // get course experiences
  [getCourseExperiencesActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getCourseExperiencesActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [getCourseExperiencesActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    loading: false,
    courseLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.courseLatestPage,
    courseExperiences: uniqBy([...state.courseExperiences, ...payload.items], "id"),
  }),
  // get all experiences
  [getAllExperiencesActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loading: true,
  }),
  [getAllExperiencesActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    loading: false,
  }),
  [getAllExperiencesActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    loading: false,
    allExperienceLatestPage: payload.items.length > 0 ? payload.meta.currentPage : state.allExperienceLatestPage,
    allExperiences: uniqBy([...state.allExperiences, ...payload.items], "id"),
  }),
  // get experience time slots
  [getExperienceTimeSlotsActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
    loadingSlots: true,
  }),
  [getExperienceTimeSlotsActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => {
    return {
      ...state,
      error: null,
      timeSlots: payload,
      loadingSlots: false,
    };
  },
  [getExperienceTimeSlotsActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
    // timeSlots: [],
    loadingSlots: false,
  }),
  // check experience is booked
  [checkExperienceIsBookedActions.REQUEST]: (state: ExperienceState): ExperienceState => ({
    ...state,
    error: null,
  }),
  [checkExperienceIsBookedActions.SUCCESS]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: null,
    hasBooking: payload,
  }),
  [checkExperienceIsBookedActions.FAILURE]: (state: ExperienceState, { payload }: Action<any>): ExperienceState => ({
    ...state,
    error: payload,
  }),
};

const initialState: ExperienceState = {
  error: null,
  loading: false,
  loadingSlots: false,
  timeSlots: null,
  experience: null,
  experiences: [],
  totalPagesExperiences: 1,
  experienceDetail: null,
  fetchExperienceDetail: null,
  stagingCovers: {},
  productImages: {},
  contentProcessStatus: EXPERIENCE_PROCESS_STATUS.EMPTY,
  liveClassExperiences: [],
  liveClassLatestPage: 1,
  oneToOneExperiences: [],
  oneToOneLatestPage: 1,
  exclusiveContentExperiences: [],
  exclusiveContentLatestPage: 1,
  courseExperiences: [],
  courseLatestPage: 1,
  allExperiences: [],
  allExperienceLatestPage: 1,
  hasBooking: false,
};

export const experienceReducer = handleActions<ExperienceState>(handlers, initialState);
