import { BookingStatusType, Booking } from "redux/Booking/types";
import { GetSpecialRequests, SpecialRequest, CreateSpecialRequestPayload } from "redux/SpecialRequest/types";
import { Pagination, Response } from "../redux/Common/types";
import { Api } from "./api";

export interface ISpecialRequestService {
  getList(params: GetSpecialRequests): Promise<Response<Pagination<SpecialRequest[]>>>;
  getById(id: string): Promise<Response<SpecialRequest>>;
  accept(id: Booking["id"]): Promise<Response<SpecialRequest>>;
  decline(payload: Booking): Promise<Response<SpecialRequest>>;
  create(payload: CreateSpecialRequestPayload): Promise<Response<SpecialRequest>>;
}
export default class SpecialRequestService implements ISpecialRequestService {
  constructor(private readonly api: Api) {
    this.api = api;
  }

  getList = (params: GetSpecialRequests): Promise<Response<Pagination<SpecialRequest[]>>> => {
    return this.api.getListSpecialRequests(params);
  };

  getById = (id: string): Promise<Response<SpecialRequest>> => {
    return this.api.getSpecialRequestById(id);
  };

  create = (payload: CreateSpecialRequestPayload): Promise<Response<SpecialRequest>> => {
    return this.api.createSpecialRequest(payload);
  };

  accept = (id: Booking["id"]): Promise<Response<SpecialRequest>> => {
    return this.api.updateBooking({
      id,
      status: BookingStatusType.TALENT_APPROVAL_PENDING,
    });
  };

  decline = (payload: Booking): Promise<Response<SpecialRequest>> => {
    return this.api.updateBooking({
      status: BookingStatusType.ADMIN_DECLINED,
      id: payload.id,
      rejectNotes: payload.rejectNotes,
    });
  };
}
