import { Pagination, ListQueryParams } from "redux/Common/types";
import { User } from "redux/User/types";
import { Talent } from "redux/Talent/types";
import { Experience, EXPERIENCE_TYPE, ProductType } from "redux/Experience/types";
import { GiftCode } from "../GiftsCode/types";

export const DEFAULT_BOOKING_PAGE_SIZE = 10;

type Video = {
  createdAt: string;
  id: string;
  status: string;
  updatedAt: string;
  videoOriginalSource: string;
  zencoderJobId: string;
  metadata: {
    duration_in_ms: number;
    file_size_in_bytes: number;
    format: string;
    height: number;
    id: number;
    state: string;
    url: string;
    width: number;
  }[];
};

export type Transaction = {
  id: string;
  currency: string;
  amount: number;
  transactionStatus?: string;
  transactionType?: string;
  chargeGbpAmount?: number;
  chargeAmount?: number;
  chargeCurrency?: string;
  paymentMethodNonce?: string;
  pricePercent?: number;
  createdAt?: string;
  updatedAt?: string;
  iapCurrencyCode?: string;
  iapLocalPrice?: number;
  paymentGatewayProvider?: string;
  braintreeTransactions?: any;
  createdBy: Talent;
  receiverId: User;
  customFields?: {
    notes?: string;
  };
};

export type Booking = {
  createdAt?: string;
  updatedAt?: string;
  isExpired?: boolean;
  endedAt?: string | null;
  isAutomaticallyEnded?: boolean | null;
  id?: number;
  forMe?: boolean;
  from?: string | null;
  to?: string | null;
  message?: string;
  isPublic?: boolean;
  status?: string;
  videos?: Video[];
  requestor?: User;
  talent?: Talent;
  experiencePriceGBP?: number;
  experiencePrice?: number;
  occasion?: {
    id: number;
    name: string;
  };
  experience?: Experience;
  paymentStatus?: string;
  isFreeExperience?: boolean;
  experienceAvailablePeriod?: {
    end?: string;
    id?: string;
    start?: string;
  };
  payment?: {
    id?: string;
    purchaseOn?: string;
    paymentMethodDetails?: {
      card?: {
        brand: string;
        country: string;
        last4: string;
      };
      type?: string;
    };
  };
  date?: string;
  duration?: number;
  room?: {
    id: number;
    roomId: string | null;
    name: string;
    url: string;
  };
  notes?: string;
  rejected?: {
    reason?: string;
  };
  completed?: {
    rating?: number;
    comment?: string;
    tips: number;
  };
  rejectNotes?: string | null;
  transactions?: {
    localCurrency: string;
    netAmountGBP: number;
    netAmountLocal: number;
    createdAt: string;
    currency: string;
    id: string;
    paymentMethodNonce: string | null;
    pricePercent: string | null;
    transactionStatus: TransactionStatus;
    transactionType: string;
    updatedAt: string;
    iapLocalPrice: number;
    iapCurrencyCode: string;
    couponCodeId: string;
    couponCode?: GiftCode;
    paymentGatewayProvider: string;
    receivedCodes?: {
      name: string;
      link: string;
      image: string;
      receivedCode: {
        id: string;
        code: string;
      };
    }[];
  }[];
  rating?: number | null;
  shortenId?: string;
  currencyCode?: string;
  chargeCurrency?: string;
  chargeAmount?: number;
  chargeAmountLocal?: number;
  chargeAmountGBP?: number;
  paymentType?: any;
  description?: string;
  productCodes?: {
    code: string;
    createdAt: string;
    id: string;
    isSharedCode: boolean;
    product: ProductType;
    updatedAt: string;
  }[];
  attachments?: {
    id: string;
    url: string;
    userId: number;
  }[];
  requestorTimezone?: string;
};

export enum PAYMENT_GATEWAY_PROVIDER {
  BRAINTREE = "BRAINTREE",
  APPLE_IAP = "APPLE_IAP",
  GOOGLE_IAP = "GOOGLE_IAP",
  STRIPE = "STRIPE",
}

export enum TransactionStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REFUNDED = "REFUNDED",
  REFUND_PENDING = "REFUND_PENDING",
  REFUND_FAILED = "REFUND_FAILED",
}

export enum PaymentMethodType {
  CREDIT_CARD = "credit_card",
  APPLE_PAY = "apple_pay",
  BRAINTREE = "BRAINTREE",
  PAYPAL = "paypal_account",
  APPLE_IAP = "APPLE_IAP",
  GOOGLE_IAP = "GOOGLE_IAP",
}

export type GetTransactionRequest = {
  userId: User["id"];
  params: ListQueryParams & {
    transactionType?: string[];
  };
};

export type GetBookingRequest = {
  userId: User["id"];
  params: ListQueryParams & {
    type?: string[];
  };
};

export type GetBookingDetailRequest = {
  userId: User["id"];
  bookingId: Booking["id"];
};

export type RejectBookingRequest = {
  bookingId: Booking["id"];
  notes: string;
};

export type CreateBookingRequest = {
  experienceId: Experience["id"];
  notes?: string;
  date?: string;
  type: EXPERIENCE_TYPE;
  requestorTimezone: string;
};

export type CompleteBookingRequest = {
  bookingId: Booking["id"];
  notes?: string;
};

export type RateBookingRequest = {
  bookingId: Booking["id"];
  value: number;
  comment: string;
};

export type UpdateBookingAttachmentsRequest = {
  id: string;
  urls: string[];
};

export type RateBookingResponse = any;

export type TipBookingRequest = {
  bookingId: Booking["id"];
  amountLocal: number;
};

export type TipBookingResponse = any;

export enum BookingStatusType {
  ALL = "all",
  ADMIN_APPROVAL_PENDING = "admin_approval_pending", //waiting for admin approval
  TALENT_APPROVAL_PENDING = "talent_approval_pending", //waiting for talent approval
  PENDING = "pending", //waiting for payment
  ACCEPTED = "accepted", //Payment success and talent accepted
  ADMIN_DECLINED = "admin_declined", //Admin rejected
  DECLINED = "declined", //Talent rejected
  CANCELED = "canceled", //Canceled
  COMPLETED = "completed",
  EXPIRED = "expired",
  UNCOMPLETED = "uncompleted",
  REFUNDED = "REFUNDED",
  REFUND_PENDING = "REFUND_PENDING",
  REFUND_FAILED = "REFUND_FAILED",
  PAYMENT_FAILED = "payment_failed", //No success payment until required completion date
  REJECTED = "rejected",
}

export type BookingState = {
  bookings?: Pagination<Booking[]> | null;
  myExperiences?: Pagination<Booking[]> | null;
  bookingData?: Booking | null;
  bookingCreated?: Booking | null;
  error?: string | null;
  errorData?: string | null;
  loading: boolean;
  isCreatingBooking: boolean;
  ratingData?: any | null;
  tipData?: any | null;
  attachments: Booking["attachments"] | null;
  myVideosLatestPage: number;
  myVideos: Booking[];
  myVideosHasMore: boolean;
  upcomingBookingsLatestPage: number;
  upcomingBookings: Booking[];
  upcomingBookingsHasMore: boolean;
};
