import { MediaUpload, ListQueryParams, Pagination } from "redux/Common/types";
import { Talent } from "redux/Talent/types";
import { Category } from "redux/Category/types";
import { BaseItem, TalentProfileModule } from "redux/User/types";

export type ProductImage = {
  name: string;
  link: string;
  image: string;
  price: string;
};

export type Contents1To1Request = {
  covers?: Array<string>;
  name?: "string";
  description?: "string";
  duration?: "string";
  price?: number;
  tags: Array<string>;
};

export type PublishExperienceRequest = {
  id: string;
  type: EXPERIENCE_TYPE;
};

export type GetTimeSlotResponse = {
  availableSlots: number;
  totalSlots: number;
};

export type UpdateExperience1To1Request = {
  requestBody: Contents1To1Request;
  id: string;
};

export type GetExperienceByIdRequest = {
  expId: string;
};

export enum CREATE_EXPERIENCE_STEP {
  PHOTOS_VIDEOS = "1",
  EXPERIENCE_DETAILS = "2",
  AVAILABILITY_TIME = "3",
  E_COMMERCE = "4",
}

export enum EXPERIENCE_TYPE {
  ALL = "all",
  LIVE_CLASS = "live_class",
  INTERACTIVE_LIVE_CLASS = "interactive_live_class",
  ONE_TO_ONE = "one_to_one",
  ON_DEMAND_CONTENT = "exclusive_content",
  COURSE = "course",
  GIFT = "gift",
  SPECIAL_REQUEST = "special_request",
  SUPPORT = "support",
  BUNDLE = "bundle",
}

export type FilterExperienceParams = {
  categories: Category["id"][];
};

export const ExperienceTypeLabel = new Map<string, string>([
  [EXPERIENCE_TYPE.LIVE_CLASS, "Broadcast"],
  [EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS, "Live"],
  [EXPERIENCE_TYPE.ONE_TO_ONE, "1-to-1"],
  [EXPERIENCE_TYPE.ON_DEMAND_CONTENT, "On-Demand"],
  [EXPERIENCE_TYPE.COURSE, "Course"],
  [EXPERIENCE_TYPE.BUNDLE, "Bundle"],
]);
export const ExperienceTypeTitle = new Map<string, string>([
  [EXPERIENCE_TYPE.LIVE_CLASS, "Live Broadcasts"],
  [EXPERIENCE_TYPE.INTERACTIVE_LIVE_CLASS, "Live Classes"],
  [EXPERIENCE_TYPE.ONE_TO_ONE, "1-to-1 Video Sessions"],
  [EXPERIENCE_TYPE.ON_DEMAND_CONTENT, "On-demand Videos"],
  [EXPERIENCE_TYPE.COURSE, "Courses"],
  [EXPERIENCE_TYPE.BUNDLE, "Bundles"],
]);

export enum EXPERIENCE_STATUS {
  DRAFT = "draft",
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
}

export enum EXPERIENCE_PROCESS_STATUS {
  EMPTY = "EMPTY",
  DRAFTED = "DRAFTED",
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  PUBLISHED = "PUBLISHED",
}

export enum MY_EXPERIENCE_TAB {
  UPCOMING = "upcoming",
  COMPLETED = "completed",
  COURSES = "courses",
  VIDEOS = "videos",
  SAVED = "saved",
}

export type GetExperiencePayload = {
  type: EXPERIENCE_TYPE;
  params: ListQueryParams;
};

export const DEFAULT_EXPERIENCE_PAGE_SIZE = 20;
export const DEFAULT_TALENT_PAGE_SIZE = 20;

export type GetLiveClassExperiences = {
  params: ListQueryParams;
};

export type GetOneToOneExperiences = {
  params: ListQueryParams;
};

export type GetExclusiveContentExperiences = {
  params: ListQueryParams;
};

export type GetCourseExperiences = {
  params: ListQueryParams;
};

export type GetAllExperiences = {
  params: ListQueryParams;
};

export type UpdateStagingCoversPayload = {
  uploadItemIndex: string;
  photo: Experience["media"];
};

export type AvailablePeriod = {
  id: string;
  start: string;
  end: string;
};

export type TimeRange = {
  id?: string;
  startTime: string;
  endTime: string;
  occurrence: string[];
};

export type DateOff = {
  id?: string;
  date: string;
};

export type StateExperienceLocation = {
  data: Experience;
  contentType: EXPERIENCE_TYPE;
};

export enum ProductCodeType {
  DISCOUNT = "discount",
  EARLY_ACCESS = "early_access",
  EXCLUSIVE_ACCESS = "exclusive_access",
}

export enum ProductStatusType {
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
}

export type ProductType = {
  id: string;
  image?: string;
  name?: string;
  status?: ProductStatusType;
  link?: string;
  price?: number;
  codeType?: ProductCodeType;
  codes?: {
    code: string;
  }[];
};

export type Experience = {
  id?: string;
  media?: MediaUpload[];
  description?: string;
  status?: string;
  name?: string;
  duration?: number;
  rating?: number;
  totalRatings?: number;
  date?: string;
  covers?: Array<string>;
  tags?: {
    id: number;
    name: string;
  }[];
  type: EXPERIENCE_TYPE;
  createdAt?: string;
  updatedAt?: string;
  scheduleTime?: string | null;
  creator?: Talent;
  timeRanges?: TimeRange[];
  dateOffs?: DateOff[];
  video?: string | null;
  products?: ProductType[];
  totalRating?: number;
  wishlist?: string;
  attachments?: CourseAttachment[];
  lessons?: CourseLesson[];
  prerequisites?: string[];
  localPrice?: number;
  isPOA?: boolean;
  isFreeExperience?: boolean;
  isUnableToBook?: boolean;
  isFull?: boolean;
  endTime?: string;
  bundle?: Experience;
  experiences?: Experience[];
  priceLocal?: number;
  gbpExperiencePrice?: number;
  availablePeriodTimezone?: string;
  availablePeriods?: AvailablePeriod[];
  hasBooking?: boolean;
  productSectionTitle?: string;
  experienceCreationCurrency?: string;
  localizationCurrency?: string;
  localizationId?: string;
  localizationName?: string;
};

export type CourseAttachment = {
  url: string;
  metaData: {
    name: string;
    fileName: string;
    size: number;
    duration: number;
  };
  metadata: {
    name: string;
    fileName: string;
    size: number;
    duration: number;
  };
};

export type CourseLesson = {
  name: string;
  description: string;
  video: {
    url: string;
    metadata: {
      name: string;
      fileName: string;
      fileSize: number;
      duration: number;
    };
  };
  order: number;
};

export type GetUserExperiencesRequest = {
  id: string;
  params: ListQueryParams;
};

export type GetCategoryExperiencesRequest = {
  id: string;
  params: ListQueryParams;
};

export type GetExperiencesRequest = ListQueryParams & {
  categories: string;
  type: string;
};

export type ExperienceState = {
  loading: boolean;
  loadingSlots: boolean;
  error?: string | null;
  timeSlots: GetTimeSlotResponse | null;
  contentProcessStatus: EXPERIENCE_PROCESS_STATUS;
  stagingCovers: { [key in string]: MediaUpload };
  productImages: { [key in string]: MediaUpload };
  experience: Pagination<Experience[]> | null;
  experiences: Experience[];
  totalPagesExperiences: number;
  experienceDetail: Experience | null;
  fetchExperienceDetail: Experience | null;
  hasBooking: boolean;
  // for home page
  liveClassExperiences: Experience[];
  liveClassLatestPage: number;
  oneToOneExperiences: Experience[];
  oneToOneLatestPage: number;
  exclusiveContentExperiences: Experience[];
  exclusiveContentLatestPage: number;
  courseExperiences: Experience[];
  courseLatestPage: number;
  allExperiences: Experience[];
  allExperienceLatestPage: number;
};
