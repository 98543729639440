import { Experience } from "redux/Experience/types";
import { ROLES } from "constants/auth";

export type User = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  username?: string;
  acceptTermsAndConditions?: true;
  acceptMarketingOffers?: true;
  emailVerified?: false;
  accountStatus?: string;
  activationCode?: string;
  activationCodeVersion?: number | null;
  talentProfile?: UserProfile;
  avatar?: string;
  bio?: string;
  miniBio?: string;
  dateOfBirth?: string;
  city?: string;
  country?: string;
  gender?: string;
  phone?: string;
  roles?: [
    {
      id: number;
      name: string;
      createdAt: string;
      updatedAt: string;
    },
  ];
  isFollowing?: boolean;
  createdAt?: string;
  hasPassword?: string;
  collaborationTalentProfiles?: User[];
  allowedMarketingEmailTalents?: {
    id: number;
  }[];
  clubs?: {
    id: string;
    talentProfile: UserProfile;
  }[];
  hasBooking?: boolean;
};

export type PhoneOTP = {
  serviceSid: string;
  phone: string;
};

export type SubmitDataCaptureFormRequest = {
  moduleId: string;
  formId: string;
  answers: { [key: string]: any };
};

export type JoinFanClubRequest = {
  talentProfileId: string;
  onJoinFanSuccess?: () => void;
};

export type SendPhoneOTPRequest = {
  phone: string;
};

export type SendEmailOTPRequest = {
  email: string;
};

export type VerifyEmailOTPRequest = {
  email: string;
  code: string;
};

export enum TalentProfileStatusType {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

export type TalentCategory = {
  id: number;
  name: string;
  parentCategory: {
    id: number;
    name: string;
  };
};

export interface BaseItem {
  // Has to be nullable as there are items on TalentProfileModules
  // that don't inherit from this interface
  visible?: boolean;
}

export interface LinkItem extends BaseItem {
  id: string;
  url: string;
  title: string;
  thumbnail: string;
  specialOffer?: {
    storeUrl: string;
    title: string;
    thumbnail: string;
    couponCode: string;
  };
}
export interface BandsintownItem extends BaseItem {
  id: string;
  url: string;
  metadata: {
    id: string;
    name: string;
  };
  datetime?: string;
  lineup?: string[];
  venue?: {
    name?: string;
    location?: string;
  };
  offers: {
    status: string;
    type: string;
    url: string;
  }[];
}
export interface SeatedItem {
  attributes: {
    "additional-info"?: string;
    "ends-at-date-local"?: string;
    "is-sold-out": boolean;
    "primary-button-text": string;
    "primary-link-url": string;
    "promoted-button-text": string;
    "promoted-link-url": string;
    "starts-at-date-local": string;
    "venue-address-line1": string;
    "venue-address-line2?": string;
    "venue-city": string;
    "venue-country": string;
    "venue-country-code": string;
    "venue-display-name": string;
    "venue-formatted-address": string;
    "venue-latitude": number;
    "venue-longitude": number;
    "venue-name": string;
    "venue-postal-code": string;
    "venue-state-or-province": string;
    "venue-time-zone": string;
  };
  id: string;
  type: string;
}
export interface ShopListItem extends BaseItem {
  image: string;
  original_image: string;
  title: string;
  description: string;
  url: string;
  code: any;
}
export interface ProductItem extends BaseItem {
  id: string;
  url: string;
  title: string;
  thumbnail: string;
  price: number;
  currency?: string;
}
export interface ProductShopListItem {
  id: string;
  url: string;
  title: string;
  image: string;
  code?: any;
  visible: boolean;
}
export interface EventItem extends BaseItem {
  id: string;
  eventDate?: string;
  venueName?: string;
  location?: string;
  ticketLink?: string;
  soldOut?: boolean;
}

export enum MusicItemType {
  // streaming
  SPOTIFY = "SPOTIFY",
  APPLE_MUSIC = "APPLE_MUSIC",
  AMAZON_MUSIC = "AMAZON_MUSIC",
  DEEZER = "DEEZER",
  SOUNDCLOUD = "SOUNDCLOUD",
  YOUTUBE_MUSIC = "YOUTUBE_MUSIC",
  PANDORA = "PANDORA",
  TIDAL = "TIDAL",
  AUDIOMACK = "AUDIOMACK",
  NAPSTER = "NAPSTER",
  BANDCAMP = "BANDCAMP",
  QOBUZ = "QOBUZ",
  SIRIUSXM = "SIRIUSXM",
  GAANA = "GAANA",
  BEATPORT = "BEATPORT",
  TIKTOK = "TIKTOK",
  IHEARTRADIO = "IHEARTRADIO",
  ANGHAMMI = "ANGHAMMI",
  // digital download
  ITUNES_DIGITAL_DOWNLOAD = "ITUNES_DIGITAL_DOWNLOAD",
  AMAZONE_DIGITAL_DOWNLOAD = "AMAZONE_DIGITAL_DOWNLOAD",
  // physical purchase
  HMV = "HMV",
  TARGET = "TARGET",
  WALMART = "WALMART",
  URBAN_OUTFITTERS = "URBAN_OUTFITTERS",
  RECORD_STORE = "RECORD_STORE",
  AMAZONE_PHYSICAL_PURCHASE = "AMAZONE_PHYSICAL_PURCHASE",
  OFFICIAL_STORE = "OFFICIAL_STORE",
  CUSTOM_LINK = "customLink",
}

export enum ShopifyItemType {
  NO_PRODUCT = "NO_PRODUCT",
}

export enum MusicItemTypes {
  PRE_SAVE = "PRE_SAVE",
  CUSTOM_PRE_SAVE = "CUSTOM_PRE_SAVE",
  NORMAL = "NORMAL",
}

export enum ReleaseScope {
  LOCAL = "LOCAL",
  GLOBAL = "GLOBAL",
}

export interface MusicArtist {
  name: string;
}

export interface MusicArtistToFollow {
  url: string;
  name: string;
}
export interface MusicItemLink {
  type: MusicItemType;
  url: string;
  isVisible: boolean;
  isTemporary?: boolean;
  artistsToFollow?: MusicArtistToFollow[];
  previewUrl?: string;
}

export enum MusicItemResourceType {
  TRACK = "TRACK",
  ALBUM = "ALBUM",
  SHOW = "SHOW",
  USER = "USER",
  PLAYLIST = "PLAYLIST",
  ARTIST = "ARTIST",
  EPISODE = "EPISODE",
}

export enum ReleaseType {
  KOMI = "KOMI",
  CUSTOM = "CUSTOM",
}

export interface MusicItem extends BaseItem {
  id: string;
  metadata: Record<string, any>;
  links: MusicItemLink[];
  type: MusicItemTypes;
  releaseDate?: string;
  releaseScope?: ReleaseScope;
  releaseType?: ReleaseType;
  urlSlug?: string;
}

// podcast
export enum PodcastItemType {
  SPOTIFY = "SPOTIFY",
  STITCHER = "STITCHER",
  APPLE_PODCAST = "APPLE_PODCAST",
  OVERCAST = "OVERCAST",
  AUDIBLE = "AUDIBLE",
  AMAZON_MUSIC = "AMAZON_MUSIC",
}
export interface PodcastItemLink {
  url: string;
  type: PodcastItemType;
  externalUrl?: string;
  audioPreview?: string;
}
export interface PodcastItemAutomationLink {
  url: string;
  type: PodcastItemType;
}
export interface PodcastItem extends BaseItem {
  id: string;
  metadata: Record<string, any>;
  links: PodcastItemLink[];
  numberOfEpisodes?: number;
  activeLatestPodcast?: boolean;
  isDifferent?: boolean;
  customUrl?: string;
}
export interface PodcastAutomationItem extends BaseItem {
  id: string;
  metadata: Record<string, any>;
  links: PodcastItemLink[];
  images?: any;
  urls?: any;
  externalUrl?: any;
  trackName?: any;
  author?: any;
  collectionName?: string;
}
export interface FanClubItem extends BaseItem {
  id: number;
  order: number;
  thumbnail: string;
}

export type OnDemandVideoItem = Experience & BaseItem;

export interface YoutubeVideoItem extends BaseItem {
  id: string;
  metadata: any;
  url: string;
}
export type ExperienceItem = Experience & BaseItem;

export interface ShopifyProductItem extends BaseItem {
  id: string;
  shop: string;
  itemIds: string[];
  collectionId: string;
}

export interface FormDataItem extends BaseItem {
  id?: string;
  layout?: "left" | "center";
  image: string;
  enableImage: boolean;
  title: string;
  subTitle?: string;
  form: FormItem;
}

export interface FormItem {
  name: string;
  title?: string;
  subTitle?: string;
  fields: FieldItem[];
}

export enum LEGAL_TYPE {
  DEFAULT_CHECK = "DEFAULT_CHECK",
  DEFAULT_UNCHECK = "DEFAULT_UNCHECK",
  PLAIN_TEXT = "PLAIN_TEXT",
}

export interface FieldItem {
  name?: string;
  label?: string;
  text?: string;
  required?: boolean;
  type?: FieldType;
  options?: OptionItem[];
  legalType?: string;
}

export interface OptionItem {
  name?: string;
  value?: string;
  label: string;
  checked: boolean;
}

export enum FieldType {
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  SELECT_SINGLE = "SELECT_SINGLE",
  SELECT_MULTIPLE = "SELECT_MULTIPLE",
  LEGALS = "LEGALS",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  SELECT_DATE = "SELECT_DATE",
  SELECT_COUNTRY = "SELECT_COUNTRY",
}

export type ModuleItem =
  | LinkItem
  | ProductItem
  | MusicItem
  | OnDemandVideoItem
  | YoutubeVideoItem
  | ExperienceItem
  | PodcastItem
  | FanClubItem
  | BandsintownItem
  | ShopifyProductItem
  | EventItem
  | FormDataItem
  | ShopListItem
  | PodcastAutomationItem;

export type TalentProfileModule = {
  id: string;
  order: number;
  type: TalentProfileModuleType;
  name: string;
  localizationId: string;
  localizationName: string;
  showTitle?: boolean;
  displayLatest?: boolean;
  visible: boolean;
  items:
    | LinkItem[]
    | ProductItem[]
    | MusicItem[]
    | OnDemandVideoItem[]
    | YoutubeVideoItem[]
    | ExperienceItem[]
    | PodcastItem[]
    | FanClubItem[]
    | BandsintownItem[]
    | ShopifyProductItem[]
    | EventItem[]
    | FormDataItem[]
    | ShopListItem[]
    | PodcastAutomationItem[];
};

export enum TalentProfileModuleType {
  LINK = "LINK",
  EXPERIENCE = "EXPERIENCE",
  ON_DEMAND_VIDEO = "ON_DEMAND_VIDEO",
  PRODUCT = "PRODUCT",
  MUSIC = "MUSIC",
  YOUTUBE_VIDEO = "YOUTUBE_VIDEO",
  PODCAST = "PODCAST",
  FAN_CLUB = "FAN_CLUB",
  SHOPIFY_PRODUCT = "SHOPIFY_PRODUCT",
  SHOPIFY_COLLECTION = "SHOPIFY_COLLECTION",
  BANDSINTOWN = "BANDSINTOWN",
  EVENTS = "EVENTS",
  SPECIAL_OFFER_LINK = "SPECIAL_OFFER_LINK",
  FORM_DATA = "FORM_DATA",
  GROUP = "GROUP",
  SHOP_LIST = "SHOP_LIST",
  SHOP_MY_SHELF = "SHOP_MY_SHELF",
  YOUTUBE_COLLECTION = "YOUTUBE_COLLECTION",
  PODCAST_AUTOMATION = "PODCAST_AUTOMATION",
  SEATED = "SEATED",
}

export enum SocialProfileLinkTypes {
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  MEDIUM = "MEDIUM",
  PINTEREST = "PINTEREST",
  REDDIT = "REDDIT",
  TIKTOK = "TIKTOK",
  TWITCH = "TWITCH",
  SNAPCHAT = "SNAPCHAT",
  WEBSITE = "WEBSITE",
  YOUTUBE = "YOUTUBE",
  AMAZON_MUSIC = "AMAZON_MUSIC",
  APPLE_MUSIC = "APPLE_MUSIC",
  DEEZER = "DEEZER",
  SOUNDCLOUD = "SOUNDCLOUD",
  YOUTUBE_MUSIC = "YOUTUBE_MUSIC",
  SPOTIFY = "SPOTIFY",
  EMAIL = "EMAIL",
  BANDSINTOWN = "BANDSINTOWN",
  DISCORD = "DISCORD",
}
export interface SocialProfileLink {
  type: SocialProfileLinkTypes;
  link: string;
}

export type SpotifyConnectRequest = {
  callbackUrl: string;
};

export type CheckTalentExistedRequest = {
  email: string;
  token: string;
};

export type CheckUserExistedRequest = {
  onSuccess?(
    isExisted: boolean,
    userId: number,
    phone2faEnabled: boolean,
    email2faEnabled: boolean,
    phone: string,
    emailVerified: boolean,
  ): void;
  onError?(error?: any): void;
  email: string;
  role?: string;
};

export type RequestOtpRequest = {
  onSuccess?(id: string): void;
  onError?(error?: any): void;
  method: string;
  phone?: string;
  email?: string;
  userId?: number;
};

export type SpotifyConnectResponse = {
  url: string;
};

export type GetListUserPreSaveRequest = {
  token: string;
};

export type GetListUserPreSaveResult = {
  createdAt: string;
  updatedAt: string;
  id: string;
  link: string;
  linkType: string;
  musicItemId: string;
  releaseDate: string;
  token: string;
  status: string;
};

export type SpotifyUserTokenRequest = {
  grant_type: string;
  code: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
};

export type SpotifyUser = {
  access_token: string;
  refresh_token?: string;
  expires_in: string;
  token_type: string;
  scope: string;
  callback?: any;
};

export type ArtistsSpotify = {
  external_urls?: {
    spotify?: string;
  };
  href: string;
  id: string;
  name: string;
  type: string;
  uri: string;
};
export type SpotifyTrack = {
  album?: {
    album_type?: string;
    artists?: ArtistsSpotify[];
    available_markets?: string[];
    external_urls: {
      spotify: string;
    };
    href: string;
    id: string;
    images: {
      height: number;
      url: string;
      width: number;
    }[];
    name: string;
    release_date: string;
    release_date_precision: string;
    total_tracks: number;
    type: string;
    uri: string;
  };
  artists?: ArtistsSpotify[];
  available_markets?: string[];
  disc_number?: number;
  duration_ms?: number;
  explicit?: boolean;
  external_ids?: {
    isrc?: string;
  };
  external_urls?: {
    spotify?: string;
  };
  href?: string;
  id: string;
  is_local: boolean;
  name: string;
  popularity: number;
  preview_url: string;
  track_number: number;
  type: string;
  uri: string;
};

export enum TalentProfileDisplayNameTypes {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
}

export type UserProfile = {
  accountStatus?: string;
  avatar?: string;
  bio?: string;
  miniBio?: string;
  city?: string;
  country?: string;
  createdAt?: string;
  dateOfBirth?: string;
  gender?: string;
  id?: string;
  status?: TalentProfileStatusType;
  categories?: TalentCategory[];
  category?: {
    name: string;
    id?: number;
  };
  phone?: string;
  state?: string;
  updatedAt?: string;
  instagram?: string;
  youtube?: string;
  website?: string;
  accomplishments?: string[];
  covers?: string[];
  firstName?: string;
  lastName?: string;
  commissionRate?: number;
  modules: TalentProfileModule[];
  displayName: string;
  socialProfileLinks: SocialProfileLink[];
  privacyPolicy?: string;
  privacyPolicyName?: string;
  facebookPixels?: string[];
  fanClubBenefits?: string;
  fanClubName?: string;
  isHasFanClub?: boolean;
  themeColor: {
    backgroundColor: string;
    overlayColor: string;
    overlayOpacity: number;
    typographyColor: string;
  };
  themeType: string;
  displayNameImage?: string;
  displayNameImageScale?: string;
  showDisplayName?: boolean;
  displayNameType?: TalentProfileDisplayNameTypes;
  shopifyMerchants?:
    | {
        [string: string]: string;
      }
    | {
        [string: string]: {
          accessToken: string;
        };
      };
  hashedProtectionPassword: string;
  localizationId?: string;
  localizationName?: string;
};

export type SignInRequest = {
  email: string;
  password: string;
  rememberDevice: boolean;
  id?: string;
  otp?: string;
};

export type SignInRequestWithSegment = {
  form: SignInRequest;
  sendSegmentEvent: any;
  onSuccess?: any;
  onError?: any;
};

export type SignInResponse = {
  user: User;
};

export type SignUpRequest = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptMarketingOffers: boolean;
  acceptTermsAndConditions: boolean;
  inviteCode?: string;
  callback?: any;
  waitlistApplicationToken?: string;
};

export type SignUpResponse = {
  user: User;
};

export type ForgotPasswordRequest = {
  email: string;
};

export type ForgotPasswordResponse = any;

export type ResetPasswordRequest = {
  email: string;
  token: string;
  newPassword: string;
  onSuccess?: any;
};

export type CategoriesRequest = {
  offset?: number;
  limit?: number;
  page?: number;
  trending?: boolean;
};

export type ResetPasswordResponse = {
  accessToken: string;
};

export type UpdatePasswordRequest = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type CreatePasswordRequest = {
  password: string;
  confirmPassword: string;
};

export type CheckUserExistedResult = {
  isExisted: boolean;
  firstName: string;
  lastName: string;
  avatar: null;
};

export type SignInSocialRequest = {
  id?: string;
  token: string;
  sendSegmentEvent: any;
};

export type SignInGoogleRequest = {
  token: string;
  sendSegmentEventSignIn: any;
  sendSegmentEventSignUp: any;
  operation?: string;
  onSuccessLogin?: () => void;
};

export type SignInAppleRequest = {
  token: string;
  name: string;
  id: string;
  sendSegmentEventSignIn: any;
  sendSegmentEventSignUp: any;
};

export type AcceptCollaboratorInvitationRequest = {
  form: {
    email: string;
    token: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    phone?: string;
    dateOfBirth?: string;
  };
  refreshUser: boolean;
  openLogin: boolean;
  onSuccess?: () => void;
  onAlreadyJoined?: () => void;
};
export type WaitlistData = {
  publicName: string;
  socialProfileLink: string;
  contactEmail: string;
  contactPhone: string;
  referralCode?: string;
};

export type UserState = {
  loading: boolean;
  phoneOtpLoading: boolean;
  emailOtpLoading: boolean;
  user?: User | null;
  userSocial?: User | null;
  error?: string | null;
  type?: string | null;
  password?: boolean | null;
  phoneOTP?: PhoneOTP | null;
  isUserExisted?: CheckUserExistedResult | null;
  otpId?: string | null;
  currency?: string;
  temporaryAccessToken?: string | null;
  talentCredential?: string | null;
  isRequestJoinTalent: boolean;
  bookingSetup: number[];
  signUpResult?: {
    email: string;
  } | null;
  oAuth?: typeof ROLES | null;
  verifyOtpResult: string | null;
  spotifyPreSaveList: GetListUserPreSaveResult[];
  applePreSaveList: GetListUserPreSaveResult[];
  joinFanClub: any;
};

export type ShopifyInternationalPrice = {
  id: string;
  amount: string;
  currencyCode: string;
  gid: string;
};

export type PricingMap = {
  [id: string]: ShopifyInternationalPrice;
};

export interface SelfSignUpRequest {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  dateOfBirth: string;
  authProviderId?: string;
  googleRecaptchaToken?: string;
}

export type ResendEmailConfirmationRequest = { userId: number } | { activationCode: string };
export type ResendEmailConfirmationResponse = {
  success: true;
  newActivationCodeVersion: number;
};

export interface RequestTalentProfileRequest {
  talentName: string;
  talentSocialProfileUrl: string;
  userId: number;
}

export type EmailVerificationResponse = {
  code: number;
  status: string;
  data: User;
};
