export const KOMI_APPLE_MUSIC_TOKEN = "KOMI_APPLE_MUSIC_TOKEN";

export const ROLES = {
  USER: "user",
  TALENT: "talent",
  SELFSIGN: "selfsign",
};

export const MARKETING_PERMISSIONS = {
  SPOTIFY: "KOMI_SPOTIFY_MARKETING_PERMISSIONS",
  APPLE: "KOMI_APPLE_MARKETING_PERMISSIONS",
};

