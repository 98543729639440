import { Action, handleActions } from "redux-actions";
import { getBundleByIdActions } from "./actions";
import { BundleState } from "./types";

const handlers = {
  // get bundle by id
  [getBundleByIdActions.REQUEST]: (state: BundleState): BundleState => {
    return {
      ...state,
      error: null,
      isLoading: true,
    };
  },
  [getBundleByIdActions.SUCCESS]: (state: BundleState, { payload }: Action<any>): BundleState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      bundleDetail: payload,
    };
  },
  [getBundleByIdActions.FAILURE]: (state: BundleState, { payload }: Action<any>): BundleState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
};

const defaultState: BundleState = {
  isLoading: false,
  bundleDetail: null,
  error: null,
};

export const bundleReducer = handleActions<BundleState>(handlers, defaultState);
