import { createGenericTypes, createGenericActions } from "utils/createAction";

export const checkoutBookingTypes = createGenericTypes("CHECKOUT_BOOKING");
export const checkoutBookingActions = createGenericActions(checkoutBookingTypes);

export const getExchangeRateTypes = createGenericTypes("GET_EXCHANGE_RATES");
export const getExchangeRateActions = createGenericActions(getExchangeRateTypes);

export const getExchangeRateTipTypes = createGenericTypes("GET_EXCHANGE_RATES_TIP");
export const getExchangeRateTipActions = createGenericActions(getExchangeRateTipTypes);

export const getExchangeRateUSDTypes = createGenericTypes("GET_EXCHANGE_RATES_USD");
export const getExchangeRateUSDActions = createGenericActions(getExchangeRateUSDTypes);

export const checkoutRequestTypes = createGenericTypes("CHECKOUT_REQUEST");
export const checkoutRequestActions = createGenericActions(checkoutRequestTypes);

export const getBraintreeClientTokenTypes = createGenericTypes("GET_BRAINTREE_CLIENT_TOKEN");
export const getBraintreeClientTokenActions = createGenericActions(getBraintreeClientTokenTypes);

export const checkoutExpertSupportTypes = createGenericTypes("CHECKOUT_EXPERT_SUPPORT");
export const checkoutExpertSupportActions = createGenericActions(checkoutExpertSupportTypes);

export const getTransactionTypes = createGenericTypes("GET_TRANSACTIONS");
export const getTransactionActions = createGenericActions(getTransactionTypes);

export const getTransactionByIdTypes = createGenericTypes("GET_TRANSACTION_BY_ID");
export const getTransactionByIdActions = createGenericActions(getTransactionByIdTypes);

export const checkoutSupportTypes = createGenericTypes("CHECKOUT_SUPPORT");
export const checkoutSupportActions = createGenericActions(checkoutSupportTypes);

export const resetCheckoutResultTypes = createGenericTypes("RESET_CHECKOUT_RESULTS_VALUE");
export const resetCheckoutResultActions = createGenericActions(resetCheckoutResultTypes);

export const resetSendTipResultTypes = createGenericTypes("RESET_SEND_TIP_RESULTS_VALUE");
export const resetSendTipResultActions = createGenericActions(resetSendTipResultTypes);

export const getPaymentMethodsTypes = createGenericTypes("GET_PAYMENT_METHODS");
export const getPaymentMethodsActions = createGenericActions(getPaymentMethodsTypes);
