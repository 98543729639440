import { Action, handleActions } from "redux-actions";
import { getRoomTokenActions, getRoomLiveClassTokenActions } from "./actions";
import { RoomState } from "./types";

const handlers = {
  // get room token
  [getRoomTokenActions.REQUEST]: (state: RoomState): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      token: null,
    };
  },
  [getRoomTokenActions.SUCCESS]: (state: RoomState, { payload }: Action<any>): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: false,
      token: payload,
    };
  },
  [getRoomTokenActions.FAILURE]: (state: RoomState, { payload }: Action<any>): RoomState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
  // get room Broadcast token
  [getRoomLiveClassTokenActions.REQUEST]: (state: RoomState): RoomState => {
    return {
      ...state,
      error: null,
      isLoading: true,
      token: null,
    };
  },
  [getRoomLiveClassTokenActions.SUCCESS]: (state: RoomState, { payload }: Action<any>): RoomState => {
    const allRequestors = payload?.allRequestors?.reduce((requestors: any, requestor: any) => {
      requestors[requestor.id] = requestor;
      return requestors;
    }, {});

    payload = {
      ...payload,
      allRequestors,
    };

    return {
      ...state,
      error: null,
      isLoading: false,
      token: payload,
    };
  },
  [getRoomLiveClassTokenActions.FAILURE]: (state: RoomState, { payload }: Action<any>): RoomState => {
    return {
      ...state,
      error: payload,
      isLoading: false,
    };
  },
};

const defaultState: RoomState = {
  isLoading: false,
  token: null,
  error: null,
};

export const roomReducer = handleActions<RoomState>(handlers, defaultState);
